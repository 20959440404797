import {
    accountSelector,
    authTokenSelector, deepCloneObject, Form,
    FormControlChangeType,
    FormControlType,
    IFormConfig, isNotNullOrUndefined,
    isNullOrUndefined, Loader, Translation
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {BehaviorSubject, forkJoin, of, Subscription} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {createTutoringScopeAPI} from '../../../../api/createTutoringScopeType';
import {TutoringSubject} from '../../../../api/provider/educatTutoringScopeTypeAPI';
import {sendMentorRegistrationSurveyAPI} from '../../../../api/sendMentorRegistrationSurvey';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {
    IMentorRegistrationService,
    MentorAccount,
    MentorRegistrationSteps,
    MentorTutoringScope,
    MentorTutoringScopeIds
} from '../../../../service/mentorRegistrationService';
import {RootState} from '../../../../store/reducers';
import {classesFormGroup, mentorClassesFormConfig} from './mentorClassesFormConfig';
import {tutoringSubjectScopeGroup} from './subjectClassesFormConfig';

interface IConnectedFormStepClassesProps {
    readonly authToken: string;
    readonly account: any;
}

interface IExternalFormStepClassesProps {
    readonly submitStep: (
        stepName: MentorRegistrationSteps,
        stepValue: any,
        tutoringScopesIds: { maturityExamId: string | null; examId: string | null; otherId: string | null }
    ) => void;
    readonly prevStep: (step: MentorRegistrationSteps, stepValue?: any, prevStep?: MentorRegistrationSteps) => void;
    readonly tutoringScopesList: { multiselectOptions: { [key: string]: any }[]; subjectsArray: TutoringSubject[] };
    readonly rawFormData: any;
    readonly stepData?: any;
    readonly changeCurrentStep?: () => void;
}

interface IFormStepClassesProps extends IConnectedFormStepClassesProps, IExternalFormStepClassesProps {
}

interface IFormStepClassesState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorRegistrationSteps;
    formConfig: typeof IFormConfig;
    mentorTutoringScopesId: MentorTutoringScopeIds;
}

class FormStepClasses extends React.Component<IFormStepClassesProps, IFormStepClassesState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
    @lazyInject('MentorRegistrationService') private mentorRegistrationService: IMentorRegistrationService;

    constructor(props: IFormStepClassesProps) {
        super(props);

        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: mentorClassesFormConfig,
            stepValue: null,
            stepName: MentorRegistrationSteps.CLASSES,
            mentorTutoringScopesId: {maturityExamId: null, examId: null, otherId: null},
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );

        let updatedFormConfig = deepCloneObject(this.state.formConfig);
        updatedFormConfig.controls.push(classesFormGroup(this.props.tutoringScopesList.multiselectOptions, this.props.stepData));
        const update: any = {formConfig: updatedFormConfig};

        const value = this.props.stepData;
        if (value) {
            update.stepValue = value;
            if (value && value.classes && value.classes.subject) {
                const subjectId = value.classes.subject.value ?
                    value.classes.subject.value :
                    value.classes.subject;
                this.showSelectedSubjectFormConfig(subjectId, value, updatedFormConfig);
            }
        }

        this.setState(update);
    }


    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <Loader showLoader={this.state.isProcessing}/>
                <div className="onboarding-form-wrapper">
                    <Form
                        config={this.state.formConfig}
                        controlName={MentorRegistrationSteps.CLASSES}
                        onValueStateChange={this.onValueStateChange}
                        onValidationStateChange={this.formValidityChange}
                        value={this.state.stepValue}
                    />
                </div>
                <footer className="onboarding-navigation">
                    <button
                        type="button"
                        onClick={() => {
                            this.props.prevStep(
                                MentorRegistrationSteps.MENTOR_DESCRIPTION,
                                this.state.stepValue,
                                this.state.stepName
                            )
                        }}
                        className="btn btn-theme btn-back">
                        <Translation text="buttons.back"/>
                    </button>
                    <button
                        type="submit"
                        disabled={!this.state.isStepValid}
                        onClick={() => this.register(this.props.account.mentorId)}
                        className="btn btn-theme btn-rounded">
                        <Translation text="buttons.sendRegistration"/>
                    </button>
                </footer>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        const update: any = {formConfig: Object.assign({}, this.state.formConfig), stepValue: value};

        Object.keys(value).forEach((key: string) => {
            if (key !== 'classes' || isNullOrUndefined(value[key])) {
                return;
            }
            update.formConfig.controls.forEach((control: any) => {
                if (!control.hasOwnProperty('controls')) {
                    return;
                }

                Object.keys(control.controls).forEach((controlKey: string) => {
                    if (controlKey === 'subject') {
                        control.controls[controlKey].hostClass =
                            value[key].subjectClasses === 'yes' ? 'col-xl-12 form-row' : 'col-xl-12 form-row d-none';

                        if (isNotNullOrUndefined(value[key].subject)) {
                            const subjectValue = value[key].subject.value ? value[key].subject.value : value[key].subject;

                            const mentorTutoringScopesId = this.showSelectedSubjectFormConfig(subjectValue, update.stepValue, update.formConfig);

                            if (isNotNullOrUndefined(mentorTutoringScopesId)) {
                                update.mentorTutoringScopesId = mentorTutoringScopesId;
                            }
                        }
                    }

                    if (controlKey === 'subjectClasses' && value[key].subjectClasses === 'no') {
                        this.removeSelectedSubjectFormConfig(update.formConfig, update.stepValue);
                    }
                });
            });
        });

        this.setState(update);
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        this.setState({isStepValid: isValid});
    };

    private showSelectedSubjectFormConfig(selectedSubjectId: string | null, value: any, config: any) {
        if (!value.classes) {
            return;
        }
        const selected = this.props.tutoringScopesList.subjectsArray.find((subject: TutoringSubject) => subject.id === selectedSubjectId);

        if (config.controls.length > 1) {
            config.controls = config.controls.splice(0, 1);
            // updatedFormConfig.controls[0].controls.subject.hostClass =
            // 	this.state.stepValue.classes.subjectClasses === "yes" ? "col-xl-12 form-row" : "col-xl-12 form-row d-none";
        }

        if (!selected) {
            return {
                maturityExamId: null,
                examId: null,
                otherId: null,
            };
        }

        if (selected.maturityExamArray.length > 0) {
            const controls = this.createFormControlsFromScope(selected.maturityExamArray);
            config.controls.push(
                tutoringSubjectScopeGroup('maturityExams', 'mentorOnboarding.registration.classes.formControls.maturityExam.title', controls)
            );
        }
        if (selected.examArray.length > 0 && selected.examId) {
            const controls = this.createFormControlsFromScope(selected.examArray);
            config.controls.push(
                tutoringSubjectScopeGroup('exams', 'mentorOnboarding.registration.classes.formControls.exams.title', controls)
            );
        }
        if (selected.otherArray.length > 0 && selected.otherId) {
            const controls = this.createFormControlsFromScope(selected.otherArray);
            config.controls.push(
                tutoringSubjectScopeGroup('others', 'mentorOnboarding.registration.classes.formControls.additional.title', controls)
            );
        }

        return {
            maturityExamId: selected.maturityExamId ? selected.maturityExamId : null,
            examId: selected.examId ? selected.examId : null,
            otherId: selected.otherId ? selected.otherId : null,
        };
    }

    private removeSelectedSubjectFormConfig = (config: any, value: any) => {
        const updatedControls = config.controls.filter((item: any) => item.key === 'classes');
        config['controls'] = [...updatedControls];
        delete value?.classes?.subject;
    };

    private createFormControlsFromScope(scopeItemArray: { name: string; id: string }[]) {
        let stepData = null,
            stepDataValues: { [key: string]: any } | null = null;
        if (this.props.rawFormData && this.props.rawFormData.CLASSES) {
            stepData = this.props.rawFormData.CLASSES;
            stepDataValues = {
                ...stepData.exams,
                ...stepData.maturityExams,
                ...stepData.others,
            };
        }

        let controls = {};
        scopeItemArray.forEach((scopeItem: { name: string; id: string }) => {
            controls = Object.assign(controls, {
                [scopeItem.id]: {
                    controlType: 'control',
                    defaultValue: stepDataValues && stepDataValues[scopeItem.id],
                    value: stepDataValues && stepDataValues[scopeItem.id],
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [],
                    placeholder: '',
                    label: scopeItem.name,
                    isLabelHidden: false,
                    isCheckboxLabelRaw: false,
                    checkboxLabel: ``,
                    hostClass: 'checkbox-fieldset-container',
                },
            });
        });
        return controls;
    }

    private register = (mentorId: string | null): void => {
        if (!mentorId) {
            return;
        }

        const mentorScopes = this.mentorRegistrationService.getTutoringScopeFromStepValue(
            this.state.stepValue,
            this.state.mentorTutoringScopesId,
            this.props.account.mentorId
        );
        const mentorSurveyPayload = this.mentorRegistrationService.convertRawDataToMentorPayload(this.props.rawFormData, this.props.account);
        if (isNotNullOrUndefined(this.state.stepValue?.classes?.subject)) {
            mentorSurveyPayload.subjects?.push(this.state.stepValue.classes.subject);
        }


        const apiArray = (mentorScopes.length > 0) ?
            mentorScopes.map((scope: MentorTutoringScope) => createTutoringScopeAPI(this.props.authToken, scope)) :
            [of(null)];
        this.setState({isProcessing: true});
        this.subscriptions.push(
            forkJoin(apiArray)
                .pipe(
                    switchMap(() => sendMentorRegistrationSurveyAPI(this.props.authToken, mentorId, mentorSurveyPayload)),
                    tap((response: MentorAccount) => {
                        if (response) {
                            this.alertManager?.addAlert('mentorOnboarding.registration.alerts.registrationSendingSuccess');
                            if (this.props.changeCurrentStep) {
                                this.props.changeCurrentStep();
                            }
                            this.setState({
                                isProcessing: false,
                            });
                        }
                    }),
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.registration.alerts.registrationSendingFailure');
                        this.setState({isProcessing: false});
                        return of();
                    })
                ).subscribe()
        );
    };
}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        authToken: authTokenSelector(state),
    }),
    {}
)(FormStepClasses);
