import {Observable} from "rxjs";
import {educatAPI, IBlockerEvent, RestQueryParams} from "educat-common-web";

export function editSlotsAvailability(
    slotBlockerEvents: typeof IBlockerEvent[],
    calendarValidUntil: string,
    calendarId: string,
    authToken: string
): Observable<any> {
    return educatAPI.put(
        `calendar/${calendarId}/edit_slots_availability`,
        {
            slots: slotBlockerEvents,
            calendarValidUntil: calendarValidUntil,
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        },
    );
}
