import {
    consultationDataLoadingSelector,
    consultationSlotsToBlockSelector,
    Form,
    IBlockerEvent,
    IFormConfig,
    ICalendarFreeTerms,
    isNullOrUndefined,
    isSameCollection,
    isSameValue,
    Loader,
    setSlotsToBlock,
    Toast,
    Translation,
    authTokenSelector,
    setConsultationDataError,
    FormControlChangeType
} from "educat-common-web";
import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {RootState} from "../../../../store/reducers";
import {ICalendarDetails} from "../../../../store/reducers/calendarSlice";
import {calendarDetailsSelector, calendarIdSelector} from "../../../../store/selectors/calendarSelectors";
import CalendarCreationService from "../../../../service/calendarCreationService";
import {getTime, getTimezoneOffset, getUTCTime} from "../../../../utils/dateTransformUtils";
import {calendarFormConfig} from "./calendarFormConfig";
import styles from "./styles.module.scss";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, tap} from "rxjs/operators";
import {editSlotsAvailability} from "../../../../api/editSlotsAvailabilityAPI";
import {getFreeTermsAPI} from "../../../../api/getFreeTerms";
import {isSafari} from "react-device-detect";
const momentTz = require('moment-timezone');

interface IConnectedMonthCalendarProps {
    readonly calendarId: string,
    readonly authToken: string;
    readonly consultationIsLoading: boolean;
    readonly slotsToBlock: typeof IBlockerEvent[];
    readonly calendarDetails: ICalendarDetails | null;
    readonly setSlotsToBlock: typeof setSlotsToBlock;
    readonly setConsultationDataError: typeof setConsultationDataError;
}

interface IMonthCalendarProps extends IConnectedMonthCalendarProps {
    readonly availableConsultationSlots: { [key: string]: any } | null;
    readonly getAvailableConsultationSlots: (from?: string, until?: string, calendarId?: string, month?: number) => void;
}

interface IMonthCalendarState {
    formConfig: typeof IFormConfig | null;
    value: any;
    selectedDate: Date | null;
    availableDates: Date[] | null;
    currentMonth: number;
    timeSlots: { [key: string]: any }[] | null;
    isLoading: boolean;
    hasTimeSlotUpdated: boolean;
}

class MonthCalendar extends React.Component<IMonthCalendarProps, IMonthCalendarState> {
    @lazyInject("CalendarCreationService") private calendarCreationService: CalendarCreationService;
    @lazyInject("AlertManagerService") private alertManagerService: IAlertManagerService;
    private readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];

    constructor(props: IMonthCalendarProps) {
        super(props);

        this.state = {
            formConfig: null,
            value: null,
            selectedDate: null,
            availableDates: null,
            timeSlots: null,
            currentMonth: new Date().getMonth() + 1,
            isLoading: true,
            hasTimeSlotUpdated: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onValueUpdated(data.value))
            ).subscribe()
        );

        this.setAvailableDates();
        this.getCurrentMonthAvailableSlots();
        this.setFormConfig();
    }

    componentDidUpdate(prevProps: Readonly<IMonthCalendarProps>, prevState: Readonly<IMonthCalendarState>, snapshot?: any): void {
        if (!isSameValue(this.props.availableConsultationSlots, prevProps.availableConsultationSlots)) {
            this.setAvailableDates();
        }

        if (!isSameCollection(this.state.availableDates, prevState.availableDates) ||
            !isSameCollection(this.state.timeSlots, prevState.timeSlots)) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.calendarContainer}>
                <div className={styles.calendarWrapper}>
                    <h4 className="mb-5">
                        <Translation text={"calendar.calendarTimeSlots.tabs.calendar.editCalendar"}/>
                    </h4>
                    <div className={styles.formWrapper}>
                        <Form
                            config={this.state.formConfig}
                            controlName={"calendarForm"}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                        />
                    </div>
                </div>
                <Toast/>
                <Loader showLocalLoader={this.props.consultationIsLoading || this.state.isLoading}/>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValueUpdated = (value: any) => {
        Object.keys(value).forEach(key => {
                if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'month') {
                    this.onMonthChange(value[key].value);
                }

                if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'date') {
                    if (this.state.hasTimeSlotUpdated) {
                        this.blockTimeSlots(value[key].value);
                    } else {
                        this.onDayChange(value[key].value);
                    }
                }
                if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'checkboxSelectedDate' &&
                    this.state.timeSlots) {
                    this.onTimeSlotChange(value[key]);
                    this.setState({hasTimeSlotUpdated: true});
                }

                if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'selectedDate') {
                    this.setState({selectedDate: value.date.value});
                }
            }
        )
    };

    private onMonthChange = (monthValue: any) => {
        let currentMonth = monthValue.getMonth() + 1,
            firstDay = moment(new Date(monthValue.getFullYear(), monthValue.getMonth() - 1, 1)).toISOString(true),
            lastDay = moment(new Date(monthValue.getFullYear(), monthValue.getMonth() + 2, 0)).toISOString(true);

        this.setState({
            currentMonth: currentMonth,
            timeSlots: null,
            selectedDate: null,
        });

        this.props.getAvailableConsultationSlots(firstDay, lastDay)
    };


    private onDayChange = (dayValue: Date) => {
        this.setState({isLoading: true});
        let year = dayValue.getFullYear(),
            month = dayValue.getMonth() + 1,
            day = dayValue.getDate(),
            firstDay = moment(new Date(dayValue.getFullYear(), dayValue.getMonth(), 1)).toISOString(true),
            lastDay = moment(new Date(dayValue.getFullYear(), dayValue.getMonth() + 1, 0)).toISOString(true),
            timeSlots: { [key: string]: any }[] = [];

        this.subscriptions.push(getFreeTermsAPI(this.props.authToken, this.props.calendarId, firstDay, lastDay).pipe(
            tap((response: any) => {
                if (this.props.calendarDetails && this.props.calendarDetails.calendarFreeTerms) {
                    let time = response[year]?.[month]?.[day];
                    const freeTerms = this.props.calendarDetails.calendarFreeTerms;
                    if (isNullOrUndefined(time)) {
                        return;
                    }

                    const formatString = isSafari ? 'MM/DD/YYYY HH:mm' : 'YYYY-MM-DD HH:mm';

                    Object.keys(time).forEach((key: any) => {
                        timeSlots.push({
                            value: this.props.calendarDetails?.timezone ?
                                moment(new Date(time[key].starts_at)).utcOffset(getTimezoneOffset(this.props.calendarDetails.timezone)).format(formatString) :
                                time[key].starts_at,
                            endsAt: this.props.calendarDetails?.timezone ?
                                moment(new Date(time[key].ends_at)).utcOffset(getTimezoneOffset(this.props.calendarDetails.timezone)).format(formatString) :
                                time[key].ends_at,
                            displayValue: this.props.calendarDetails?.timezone ?
                                getTime(moment(new Date(time[key].starts_at)).utcOffset(getTimezoneOffset(this.props.calendarDetails.timezone)).format(formatString)) :
                                getUTCTime(time[key].starts_at),
                            isFree: ((time[key].unlocked_by && time[key].unlocked_by === "rule_slot_unlocker") || this.isSlotAvailable(time[key], freeTerms)) && (time[key].is_free === true)
                        })
                    });

                    return this.setState({
                        timeSlots: timeSlots,
                        selectedDate: dayValue,
                        isLoading: false
                    });
                }
            }),
            catchError((err: any) => {
                this.alertManagerService?.handleApiError(err);
                return of();
            })).subscribe()
        );
    };

    private onTimeSlotChange = (timeSlotValue: any) => {
        if (isNullOrUndefined(this.props.availableConsultationSlots)) {
            return;
        }

        let year = new Date(timeSlotValue.value).getFullYear(),
            month = new Date(timeSlotValue.value).getMonth() + 1,
            day = new Date(timeSlotValue.value).getDate(),
            dayTimeslots = (this.props.availableConsultationSlots as any)[year][month][day],
            timeSlotsToChange = Object.assign({}, dayTimeslots);

        this.setState({timeSlots: timeSlotsToChange});
        let slotsToBlock: typeof IBlockerEvent[] = [...this.props.slotsToBlock];

        if (this.state.timeSlots === null) {
            return;
        }

        const timeSlotsUpdated = [...this.state.timeSlots];

        timeSlotsUpdated.forEach((timeSlot) => {
            if (new Date(timeSlot.value).getTime() !== timeSlotValue.value.getTime()) {
                return;
            }

            const formatString = isSafari ? 'MM/DD/YYYY HH:mm' : 'YYYY-MM-DD HH:mm',
                timezone = this.props.calendarDetails && this.props.calendarDetails.timezone ? this.props.calendarDetails.timezone : 'UTC',
                utcDateStart = momentTz.tz(timeSlot.value, formatString, timezone).toISOString(),
                utcDateEnd = momentTz.tz(timeSlot.endsAt, formatString, timezone).toISOString();

            timeSlot.isFree = !timeSlot.isFree;
            const payloadSlot: typeof IBlockerEvent = {
                startsAt: moment(utcDateStart).utcOffset(0, false).format(formatString),
                endsAt: moment(utcDateEnd).utcOffset(0, false).format(formatString),
                isFree: timeSlot.isFree,
            };
            if (this.props.slotsToBlock.filter((slot) => slot.startsAt === payloadSlot.startsAt).length > 0) {
                slotsToBlock = slotsToBlock.filter((slot) => slot.startsAt !== payloadSlot.startsAt);
            }
            slotsToBlock.push(payloadSlot);
            this.props.setSlotsToBlock(slotsToBlock);
        });
        this.setState({timeSlots: timeSlotsUpdated});
    };

    private getCurrentMonthAvailableSlots(calendarId?: string, month?: number) {
        const date = new Date(),
            firstDay = moment(new Date(date.getFullYear(), date.getMonth() - 1, 1)).toISOString(true),
            lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 2, 0)).toISOString(true);

        this.props.getAvailableConsultationSlots(firstDay, lastDay, calendarId, month);
    }

    private setFormConfig = () => {
        const formConfig = calendarFormConfig(this.state.availableDates, this.state.timeSlots);
        this.setState({formConfig});
    };

    private setAvailableDates = (): void => {
        if (!this.props.availableConsultationSlots || Object.keys(this.props.availableConsultationSlots).length === 0) {
            return;
        }

        const availableDates = this.calendarCreationService.getMonthAvailableDates(
            this.props.availableConsultationSlots,
            this.state.currentMonth
        );

        this.setState({
            availableDates: availableDates,
            isLoading: false
        });
    };

    private isSlotAvailable = (weekDay: { [key: string]: any }, freeTerms: typeof ICalendarFreeTerms[]): boolean => {
      const formatString = isSafari ? 'MM/DD/YYYY HH:mm' : 'YYYY-MM-DD HH:mm',
        startsAt = this.props.calendarDetails?.timezone ?
            moment(new Date(weekDay.starts_at)).utcOffset(getTimezoneOffset(this.props.calendarDetails.timezone)).format(formatString) :
            weekDay.starts_at,
            endsAt = this.props.calendarDetails?.timezone ?
                moment(new Date(weekDay.ends_at)).utcOffset(getTimezoneOffset(this.props.calendarDetails.timezone)).format(formatString) :
                weekDay.ends_at,
            date = freeTerms.find((calendarRule) => {
                return Date.parse(startsAt) >= Date.parse(calendarRule.startsAt) &&
                    Date.parse(endsAt) <= Date.parse(calendarRule.endsAt);
            });

        return !!date;
    };

    private blockTimeSlots = (selectedDay: Date) => {
        const endOfDay = new Date().setHours(23, 59, 59, 999),
            availableUntil = moment(new Date(endOfDay).setMonth(new Date().getMonth() + 2)).toISOString(true);

        this.setState({isLoading: true});

        this.subscriptions.push(editSlotsAvailability(this.props.slotsToBlock, availableUntil, this.props.calendarId, this.props.authToken).pipe(
            tap(() => {
                this.props.setSlotsToBlock([]);
                this.setState({hasTimeSlotUpdated: false});
                this.alertManagerService.addAlert('calendar.modals.confirmBlockSlotsModal.success');
                this.onDayChange(selectedDay);
            }),
            catchError((error: any) => {
                const errorMessage: string = error.response ? error.response['hydra:description'] : 'alerts.baseError';
                this.alertManagerService.handleApiError(errorMessage);
                return of(this.props.setConsultationDataError(error?.message));
            })).subscribe());
    };
}

export default connect(
    (state: RootState) => ({
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
        consultationIsLoading: consultationDataLoadingSelector(state),
        calendarDetails: calendarDetailsSelector(state),
        slotsToBlock: consultationSlotsToBlockSelector(state)
    }),
    {
        setSlotsToBlock,
        setConsultationDataError
    }
)(MonthCalendar);
