import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    isNotNullOrUndefined,
} from 'educat-common-web';

export const calendarDetailsConfig: typeof IFormConfig = (value: { [key: string]: any } | null, isTimezoneDisabled: boolean) => ({
    controlType: 'form',
    class: 'auth-form timezone-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'timezone',
            class: 'form-row',
            controls: {
                timezone: {
                    controlType: 'control',
                    defaultValue: isNotNullOrUndefined(value?.timezone) ? value?.timezone : '',
                    placeholder: 'calendar.calendarDetails.form.timezone.placeholder',
                    formControlType: FormControlType.TIMEZONE,
                    label: 'calendar.calendarDetails.form.timezone.label',
                    validationRules: [],
                    hostClass: 'col-100 required',
                    disabled: isTimezoneDisabled,
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                }
            }
        }
    ]
});

