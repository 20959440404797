import React from 'react';
import styles from './styles.module.scss';
import {Translation} from 'educat-common-web';
import {IApplicantListingOutput} from '../../../api/getApplicants';
import AccountImage from '../../Common/AccountImage';


interface IConnectedApplicantCardProps {
}

interface IExternalApplicantCardProps {
    applicant: IApplicantListingOutput;
}

interface IApplicantCardProps extends IConnectedApplicantCardProps,
    IExternalApplicantCardProps {
}

interface IApplicantCardState {
}

class ApplicantCard extends React.Component<IApplicantCardProps, IApplicantCardState> {
    render() {
        const applicant = this.props.applicant,
            name = `${applicant.account.firstName} ${applicant.account.lastName}`,
            schoolStudyFields = applicant?.applicantSchoolStudyFields?.schoolStudyFields,
            studyField = schoolStudyFields?.studyField?.name,
            school = schoolStudyFields?.school?.name;

        return (
            <div className={styles.applicantDetails}>
                <div className={styles.applicantImgContainer}>
                    <AccountImage account={applicant?.account} type={'mediumThumb'} adaptive={true}/>
                </div>
                <div className={styles.details}>
                    <p className={styles.name}>{name}</p>

                    {studyField && school ?
                        (<>
                            <p className={styles.label}><Translation text={'dashboard.applicants.application'}/></p>
                            <p className={styles.studyField}>{studyField}</p>
                            <p className={styles.school}>{school}</p>
                        </>) : null
                    }
                </div>
            </div>
        );
    }
}

export default ApplicantCard;
