import {accountSelector, authTokenSelector, changeBreadcrumbs, CustomCard, RestQueryParams, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import ApplicantCard from './ApplicantCard';
import EventCalendar from './EventCalendar';
import {getNewApplicantsAPI} from '../../api/getNewApplicants';
import NewApplicantCard from './NewApplicantCard';
import {getApplicantsAPI, IApplicantListingOutput} from '../../api/getApplicants';
import styles from './styles.module.scss';

interface IConnectedDashboardProps {
    readonly authToken: string | null;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly account: { [key: string]: any };
}

interface IExternalDashboardProps {
}

interface IDashboardProps extends IConnectedDashboardProps,
    IExternalDashboardProps,
    RouteComponentProps {
}

interface IDashboardState {
    applicantsList: IApplicantListingOutput[];
    newApplicantsList: { [key: string]: any }[] | [];
}


class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IDashboardProps) {
        super(props);

        this.state = {
            applicantsList: [],
            newApplicantsList: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if (redirectUrl){
            sessionStorage.removeItem('redirectUrl');
            this.props.history.push(redirectUrl);
        }
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'}
        ]);
        this.subscriptions.push(this.getApplicants());
        this.subscriptions.push(this.getNewApplicants());
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.dashboard}>

                <div className="row">
                    <div className="col-xl-8">
                        <header className="header-section">
                            <h1 className="header-title"><Translation text="dashboard.header.title"/> {this.props.account.firstName}!</h1>
                            <p className="header-description">
                                <Translation text="dashboard.header.description"/>
                                <br/>
                                <i><Translation text="dashboard.header.signature"/></i>
                            </p>
                        </header>
                        {(this.state?.newApplicantsList?.length > 0) ?
                            this.state?.newApplicantsList.map(applicant => (
                                <NewApplicantCard key={`new_${applicant.applicantId}`} applicant={applicant}/>)) :
                            null}
                        {this.renderApplicantDetails()}
                    </div>
                    <div className="col-xl-4 dashboard-event-calendar">
                        {this.renderCalendar()}
                    </div>

                </div>
            </div>
        );
    }

    private renderApplicantDetails = () => {
        if (!this.state.applicantsList.length) {
            return;
        }

        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <Translation text="dashboard.applicants.title"/>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className={styles.applicantsList}>
                        {this.state.applicantsList.map(item => <ApplicantCard key={item.id} applicant={item}/>)}
                    </div>
                    <div className={`button-wrapper mt-4`}>
                        <Link className="btn btn-theme" to="/panel/applicants">
                            <Translation text="dashboard.applicants.redirectBtn"/>
                        </Link>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        )
    };

    private renderCalendar = () => {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <Translation text="dashboard.eventCalendar.title"/>
                </CustomCard.Header>
                <CustomCard.Body>
                    <EventCalendar/>
                </CustomCard.Body>
            </CustomCard>
        )
    };

    private getApplicants = () => {
        const params = new RestQueryParams().add('itemsPerPage', 4);

        return getApplicantsAPI(this.props.authToken, params).pipe(
            map((resp: any) => {
                if (resp && resp['hydra:member']) {
                    this.setState({applicantsList: resp['hydra:member']})
                }
            }),
            catchError((error: any) => {
                this.alertManager?.handleApiError(error);
                return of(error);
            })
        ).subscribe()
    };

    private getNewApplicants = () => {
        return getNewApplicantsAPI(this.props.authToken, this.props.account.mentorId).pipe(
            map((resp: any) => {
                this.setState({
                    newApplicantsList: resp['hydra:member'] || []
                })
            }),
            catchError((error: any) => {
                this.alertManager?.handleApiError(error);
                return of();
            })
        ).subscribe();
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(Dashboard));
