import {
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules,
} from 'educat-common-web';

export const registrationFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'auth-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'registration_name',
            controlType: 'group',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.firstName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                    class: 'test'
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.lastName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                }
            }
        },
        {
            key: 'registration_email',
            controlType: 'group',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}}
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control required'
                }
            }
        },
        {
            controlType: 'group',
            key: 'registration_password',
            class: 'row',
            // validationRules: [
            //     {
            //         name: ValidationRules.ARE_FIELDS_SAME,
            //         params: {
            //             accessors: [
            //                 (value: any) => value.password,
            //                 (value: any) => value.newPassword
            //             ]
            //         }
            //     }
            // ],
            controls: {
                plainPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}}
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control required'
                },
                // newPassword: {
                //     controlType: 'control',
                //     defaultValue: '',
                //     formControlType: FormControlType.INPUT,
                //     validationRules: [
                //         {name: ValidationRules.IS_REQUIRED, params: {}},
                //         {name: ValidationRules.MIN_LENGTH, params: {length: 6}}
                //     ],
                //     placeholder: 'Repeat Password',
                //     isLabelHidden: true,
                //     label: 'Confirm your password',
                //     type: InputType.PASSWORD,
                //     hostClass: 'col-xl-12'
                // }
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.phone',
                    type: InputType.TEL,
                    hostClass: 'col-xl-6 form-control',
                },
            }
        },
        {
            controlType: 'group',
            key: 'registration_agreements',
            class: 'row agreement-container',
            controls: {
                registrationTermsAgreement: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                    ],
                    placeholder: '',
                    label: '',
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: `Tworząc konto na Platformie EduCat zgadzam się na <a href="https://educat.study/pl/polityka-prywatnosci/" target="_blank">Regulamin i Politykę Prywatności</a>.`,
                    hostClass: 'col-xl-12 checkbox-container'
                }
            }
        },
        {
            controlType: 'group',
            key: 'registration_submit',
            class: 'row register-form',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: 'submit',
                    inputStyles: 'btn-theme',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.register',
                },
            },
        },
    ]
};
