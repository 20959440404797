import {AuthPanel, MentorPrivateRoute, NotFound, PrivateRoute, UserRole} from "educat-common-web";
import {Route} from "react-router";
import App from "../App";
import OnboardingHost from "../components/OnboardingHost";
import PanelHost from "../components/PanelHost";
import {loginFormConfig} from "./authFormConfig/loginFormConfig";
import {newPasswordFormConfig} from "./authFormConfig/newPasswordFormConfig";
import {registrationFormConfig} from "./authFormConfig/registrationFormConfig";
import {resetPasswordFormConfig} from "./authFormConfig/resetPasswordFormConfig";

const routes = [
	<Route path="/" component={App} exact key="home" />,
	<MentorPrivateRoute path="/panel/" component={() => <PanelHost />} key="panel" />,
	<PrivateRoute path="/onboarding/mentor" component={OnboardingHost} exact key="mentorOnboarding" />,
	<Route path="/auth/:type/:id?"
		   component={() => <AuthPanel formConfig={{
			   login: loginFormConfig,
			   resetPassword: resetPasswordFormConfig,
			   registration: registrationFormConfig,
			   newPassword: newPasswordFormConfig

		   }} envData={process.env} userRole={UserRole.MENTOR}/>}

		   key="auth"
	/>,

	<Route key="not-found" component={NotFound} />,
];
export default routes;
