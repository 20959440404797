import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function sendMentorOnboardingSurveyAPI(authToken: string, mentorId: string, payload: any): Observable<any> {
    return educatAPI.put(
        `mentors/${mentorId}/onboarding`,
        {mentorOnboarding: payload},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
