import {FormControlType, InputType, isNotNullOrUndefined, isNullOrUndefined} from 'educat-common-web';
import {injectable} from 'inversify';
import {fixInjectedProperties} from '../ioc';
import {TupleUnion} from '../utils/types';

export enum AlertType {
    INFO = 'info',
    WARNING = 'warning',
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum MentorRegistrationSteps {
    STUDY_INFORMATION = 'STUDY_INFORMATION',
    HELP_RANGE = 'HELP_RANGE',
    BASIC_INFORMATION = 'BASIC_INFORMATION',
    MENTOR_DESCRIPTION = 'MENTOR_DESCRIPTION',
    CLASSES = 'CLASSES',
    THANK_YOU = 'THANK_YOU',
}

export enum MentorType {
    MENTOR = 'mentor',
    MENTOR_SCHOLAR = 'mentor_scholar',
    SCHOLAR = 'scholar',
}

export type MentorTutoringScopeIds = {
    maturityExamId: string | null;
    examId: string | null;
    otherId: string | null;
};
export type MentorTutoringScopeIdTypes = TupleUnion<keyof MentorTutoringScopeIds>;

export type MentorRegistrationData = {
    login: string;
    account: MentorAccount;
    type: MentorType;
};

export type MentorSchoolStudyField = {
    schoolId: string;
    studyFieldId: string;
    studyYear: number | null;
    statusDescription: string | null;
};

export type MentorAccount = {
    firstName: string;
    lastName: string;
    birthdate: Date | null;
    displayName: string | null;
    address: string | null;
    phone: string | null;
    avatarId: string | null;
};

export type MentorRegistrationSurvey = {
    account: MentorAccount;
    shortDescription: string | null;
    longDescription: string | null;
    videoLink: string | null;
    programme: string | null;
    studyLevel: string | null;
    countries: string[] | null;
    languages: string[] | null;
    studyFields: string[] | null;
    mentorTaskTypes: string[] | null;
    subjects: string[] | null;
    realms: string[] | null,
    mentorSchoolStudyFields: MentorSchoolStudyField[];
    studyFieldNotFound: boolean | null;
};

export type MentorTutoringScope = {
    tutoringScopeTypeId: string | null;
    tutoringScopeItemTypeIds: string[] | null;
    mentorId: string | null;
};

export type TaskTypeList = {
    taskTypeGroups: any[];
    taskTypesWithoutGroups: any[];
};

export const mentorTutoringScope: MentorTutoringScope = {
    tutoringScopeTypeId: null,
    tutoringScopeItemTypeIds: null,
    mentorId: null,
};

export const mentorRegistrationSurvey: MentorRegistrationSurvey = {
    account: {
        firstName: '',
        lastName: '',
        birthdate: null,
        displayName: null,
        address: null,
        phone: null,
        avatarId: null,
    },
    shortDescription: null,
    longDescription: null,
    videoLink: null,
    programme: null,
    studyLevel: null,
    countries: [],
    languages: [],
    studyFields: [],
    mentorTaskTypes: [],
    subjects: [],
    realms: [],
    mentorSchoolStudyFields: [],
    studyFieldNotFound: false,
};

export const stepsArray: any[] = [
    {stepName: MentorRegistrationSteps.STUDY_INFORMATION},
    {stepName: MentorRegistrationSteps.HELP_RANGE},
    {stepName: MentorRegistrationSteps.BASIC_INFORMATION},
    {stepName: MentorRegistrationSteps.MENTOR_DESCRIPTION},
    {stepName: MentorRegistrationSteps.CLASSES},
    {stepName: MentorRegistrationSteps.THANK_YOU},
];

export interface IMentorRegistrationService {
    getTutoringScopeFromStepValue(
        stepValue: any,
        mentorTutoringScopesId: MentorTutoringScopeIds,
        mentorId: string | null
    ): MentorTutoringScope[];

    convertRawDataToMentorPayload(rawFormData: any, account: any): MentorRegistrationSurvey;

    createFormControlsFromTask(taskArray: { name: string; id: string; type?: string }[], isFieldset: boolean): any;
}

@injectable()
class MentorRegistrationService implements IMentorRegistrationService {
    constructor() {
        fixInjectedProperties(this);
    }

    public getTutoringScopeFromStepValue(
        stepValue: any,
        mentorTutoringScopesId: MentorTutoringScopeIds,
        mentorId: string | null
    ): MentorTutoringScope[] {
        const allowedKeys: MentorTutoringScopeIdTypes = ['maturityExamId', 'examId', 'otherId'];
        const mapKey = (key: string) => key.replace(/s$/, 'Id');
        const keys = Object.keys(stepValue)
            .filter(key => {
                if (!stepValue.hasOwnProperty(key)) {
                    return false;
                }
                const mappedKey = mapKey(key);

                return -1 !== allowedKeys.indexOf(mappedKey);
            }) as MentorTutoringScopeIdTypes;

        return keys
            .map((key: keyof MentorTutoringScopeIds) => {
                const items = Object.keys(stepValue[key])
                    .filter(entry => isNotNullOrUndefined(stepValue[key][entry]));

                return {
                    tutoringScopeTypeId: (mentorTutoringScopesId as any)[mapKey(key)],
                    tutoringScopeItemTypeIds: items,
                    mentorId: mentorId,
                };
            })
            .filter(mentorTutoringScopePayload => mentorTutoringScopePayload.tutoringScopeItemTypeIds.length > 0);
    }

    public convertRawDataToMentorPayload(rawFormData: any, account: any): MentorRegistrationSurvey {
        const mentorSurveyPayload: MentorRegistrationSurvey = mentorRegistrationSurvey;

        const studyInformationData = rawFormData.STUDY_INFORMATION,
            countries: string[] = [],
            mentorSchoolStudyFields: any[] = [];
        let studyLevel: string | null = null;

        Object.keys(studyInformationData).forEach(key => {
            if (!studyInformationData.hasOwnProperty(key)) {
                return;
            }
            const value = studyInformationData[key];

            if (isNullOrUndefined(value)) {
                return;
            }
            if ('studyFieldNotFound' === key) {
                mentorSurveyPayload.studyFieldNotFound = value;

                return;
            }

            if (value.country) {
                countries.push(value.country);
            }
            if (value.studyLevel && (null === studyLevel || ('bachelor' === studyLevel && 'master' === value.studyLevel))) {
                // master supersedes bachelor
                studyLevel = value.studyLevel;
            }

            mentorSchoolStudyFields.push({
                schoolStudyFieldsId: value.fieldOfStudy ? value.fieldOfStudy : null,
                status: value.status ? value.status : null,
                statusDescription: value.yearOfStudies ? value.yearOfStudies.toString() : null,
            });
        });
        mentorSurveyPayload.countries = countries;
        mentorSurveyPayload.studyLevel = studyLevel ? studyLevel : null;
        mentorSurveyPayload.mentorSchoolStudyFields = mentorSchoolStudyFields;

        let mentorTaskTypes: string[] = [];
        for (const [key, value] of Object.entries(rawFormData.HELP_RANGE)) {
            if (value && typeof value === 'boolean') {
                mentorTaskTypes.push(key);
            }
        }

        mentorSurveyPayload.realms = (rawFormData.HELP_RANGE.realms || []);

        mentorSurveyPayload.mentorTaskTypes = mentorTaskTypes;
        const basicInformation = rawFormData.BASIC_INFORMATION.basic_information
            ? rawFormData.BASIC_INFORMATION.basic_information
            : rawFormData.BASIC_INFORMATION;
        let languagesValueArray: string[] = [];
        basicInformation.languages?.forEach((language: any) => languagesValueArray.push(language.value));
        const birthdate = new Date(basicInformation.birthdate),
            languages = languagesValueArray;
        mentorSurveyPayload.account.firstName = account && account.firstName ? account.firstName : null;
        mentorSurveyPayload.account.lastName = account && account.lastName ? account.lastName : null;
        mentorSurveyPayload.account.birthdate = birthdate ? birthdate : null;
        mentorSurveyPayload.account.phone = account && account.phone ? account.phone : null;
        mentorSurveyPayload.account.displayName = basicInformation.displayName ? basicInformation.displayName : null;
        mentorSurveyPayload.account.address = basicInformation.address ? basicInformation.address : null;
        mentorSurveyPayload.account.avatarId = basicInformation.userImageId ? basicInformation.userImageId : null;
        mentorSurveyPayload.languages = languages ? languages : null;

        mentorSurveyPayload.programme = rawFormData.MENTOR_DESCRIPTION.programme ? rawFormData.MENTOR_DESCRIPTION.programme : null;
        mentorSurveyPayload.longDescription = rawFormData.MENTOR_DESCRIPTION.longDescription
            ? rawFormData.MENTOR_DESCRIPTION.longDescription
            : null;

        return mentorSurveyPayload;
    }

public createFormControlsFromTask(taskArray: { name: string; id: string; type?: string }[], isFieldset: boolean) {
        let controls = {};
        taskArray.forEach((task: { name: string; id: string; type?: string }) => {
            let name = task.name,
                controlName = task.type ? `consultation_${task.id}` : task.id;
            if (task.type && task.type === 'consultation') {
                name = 'mentorOnboarding.onboarding.hourly_rates.consultationName';
            }
            controls = Object.assign(controls, {
                [`${controlName}`]: {
                    controlType: 'control',
                    defaultValue: true,
                    // value: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [],
                    placeholder: '',
                    label: name,
                    isLabelHidden: false,
                    isCheckboxLabelRaw: false,
                    checkboxLabel: ``,
                    hostClass: 'col-xl-7 checkbox-container',
                },
                [`price_${controlName}`]: {
                    hostClass: `col-xl-5 form-row quantity-input`,
                    controlType: 'control',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    labelHidden: true,
                    placeholder: 'Wpisz',
                    customIncrementButtons: false,
                    minDate: 0,
                    type: InputType.NUMBER,
                },
            });
        });
        return controls;
    }
}

export default MentorRegistrationService;
