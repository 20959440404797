import {
    authTokenSelector,
    deepCloneObject,
    isNotNullOrUndefined,
    Loader,
    RestQueryParams,
    Translation,
    usernameSelector
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {calculatePackagePricesAPI} from '../../../api/calculatePackagePrices';
import {getServiceApplicationElementsAPI} from '../../../api/getServiceApplicationElements';
import {getServiceApplicationPackagesAPI} from '../../../api/getServiceApplicationPackages';
import {getServiceConsultationPackagesAPI} from '../../../api/getServiceConsultationPackages';
import {getServiceConsultationsAPI} from '../../../api/getServiceConsultations';
import {CalculatedPrices} from '../../../api/provider/educatCalculatedPricesAPI';
import {sendMentorOnboardingSurveyAPI} from '../../../api/sendMentorOnboardingSurvey';
import {sendMentorServiceDefinitionsAPI} from '../../../api/sendMentorServiceDefinitions';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {MentorAccount} from '../../../service/mentorRegistrationService';
import {RootState} from '../../../store/reducers';
import {
    IOnboardingHelperService,
    MentorOnboardingSteps,
    MentorOnboardingSurvey,
    mentorOnboardingSurvey,
    onboardingStepsArray,
    ServiceApplicationPackage
} from './Common/converters/onboardingHelperService';
import FormStepApplicationExamPrices from './FormStepApplicationExamPrices';
import FormStepFreeHelpPackages from './FormStepFreeHelpPackages';
import FormStepHourlyRates from './FormStepHourlyRates';
import FormStepHourPackages from './FormStepHourPackages';
import FormStepOnboardingInformation from './FormStepOnboardingInformation';
import FormStepServicePackages from './FormStepServicePackages';
import FormOnboardingThankYou from './FormStepThankYou';

interface IConnectedMentorOnboardingProps {
    authToken: string;
    username: string;
}

interface IExternalMentorOnboardingProps {
    readonly mentorId: string;
    readonly mentorData: any;
    readonly reloadUserData: () => void;
}

interface IMentorOnboardingProps extends IConnectedMentorOnboardingProps, IExternalMentorOnboardingProps {
}

interface IMentorOnboardingState {
    isProcessing: boolean;
    currentStep: MentorOnboardingSteps;
    mentorId: string | null;
    mentorOnboardingSurvey: MentorOnboardingSurvey;
    rawFormValue: any;
    serviceApplicationElementsList: any[];
    serviceApplicationPackagesList: any[];
    serviceConsultationPackagesList: any[];
    freeServiceConsultationPackageList: any[];
    serviceConsultationsList: any[];
    calculatedPackagePrices: CalculatedPrices;
}

class MentorOnboarding extends React.Component<IMentorOnboardingProps, IMentorOnboardingState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
    @lazyInject('OnboardingHelperService') private onboardingHelperService: IOnboardingHelperService;

    constructor(props: IMentorOnboardingProps) {
        super(props);

        this.state = {
            isProcessing: false,
            currentStep: MentorOnboardingSteps.ONBOARDING_INFORMATION,
            mentorId: null,
            mentorOnboardingSurvey: mentorOnboardingSurvey,
            rawFormValue: {},
            serviceApplicationElementsList: [],
            serviceApplicationPackagesList: [],
            serviceConsultationPackagesList: [],
            freeServiceConsultationPackageList: [],
            serviceConsultationsList: [],
            calculatedPackagePrices: {serviceApplicationPackagePrices: [], serviceConsultationPackagePrices: []},
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getMentorServiceData();
        this.convertMentorDataToRawFormData();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const stepKey = this.state.currentStep.toLowerCase();
        return (
            <article className={`col-xl-6 col-12 ${stepKey}`}>
                <header className="onboarding-header">
                    <h1 className="onboarding-title">
                        <Translation text={`mentorOnboarding.onboarding.${stepKey}.title`}/>
                    </h1>
                </header>
                <div className="onboarding-content">{this.renderFormStep()}</div>
                <Loader showLoader={this.state.isProcessing}/>
            </article>
        );
    }

    private get mentorFee() {
        return Number(this.props.mentorData.feePercent ? this.props.mentorData.feePercent : 0);
    }

    private submitStep = (stepName: MentorOnboardingSteps, stepValue: any) => {
        const nextStepIndex = onboardingStepsArray.findIndex((step) => step.stepName === stepName),
            updatedCurrentStep = onboardingStepsArray[nextStepIndex + 1].stepName,
            updatedRawFormValue = deepCloneObject(this.state.rawFormValue);
        updatedRawFormValue[stepName] = stepValue;
        if (stepName === MentorOnboardingSteps.ONBOARDING_INFORMATION && this.props.mentorId) {
            this.setState({
                currentStep: updatedCurrentStep,
                rawFormValue: updatedRawFormValue,
            }, () => {
                window.scrollTo(0, 0)
            });
        }
        if (stepName === MentorOnboardingSteps.HOURLY_RATES && this.props.mentorId) {
            (this.state.rawFormValue.HOURLY_RATES && Object.keys(this.state.rawFormValue.HOURLY_RATES.hourly_rates).length > 0)
                ? this.setState({currentStep: updatedCurrentStep})
                : this.sendMentorServiceDefinitions(stepValue, this.props.mentorId);
        }
        if (stepName === MentorOnboardingSteps.APPLICATION_EXAM_PRICES && this.props.mentorId) {
            if (this.state.rawFormValue.APPLICATION_EXAM_PRICES && Object.keys(this.state.rawFormValue.APPLICATION_EXAM_PRICES.application_exam).length > 0) {
                this.calculatePricesAndChangeStep(updatedCurrentStep);
            } else {
                this.sendMentorServiceDefinitions(stepValue, this.props.mentorId);
            }
        }
        if (stepName === MentorOnboardingSteps.HOUR_PACKAGES && this.props.mentorId) {
            (this.state.rawFormValue.HOUR_PACKAGES && Object.keys(this.state.rawFormValue.HOUR_PACKAGES).length > 0)
                ? this.setState({currentStep: updatedCurrentStep})
                : this.sendMentorServiceDefinitionsPackage(stepValue, this.props.mentorId);
        }
        if (stepName === MentorOnboardingSteps.SERVICE_PACKAGES && this.props.mentorId) {
            (this.state.rawFormValue.SERVICE_PACKAGES && Object.keys(this.state.rawFormValue.SERVICE_PACKAGES).length > 0)
                ? this.setState({currentStep: updatedCurrentStep})
                : this.sendMentorServiceDefinitionsPackage(stepValue, this.props.mentorId);
        }
        if (stepName === MentorOnboardingSteps.FREE_HELP_PACKAGES && this.props.mentorId) {
            const mentorSurveyPayload = this.onboardingHelperService.convertRawDataToPayload(
                    updatedRawFormValue.ONBOARDING_INFORMATION,
                    this.props.mentorId,
                    MentorOnboardingSteps.ONBOARDING_INFORMATION,
                    this.mentorFee,
                ),
                mentorId = this.props.mentorId;
            this.sendOnboardingData(mentorId, mentorSurveyPayload);
        }
        window.scrollTo(0, 0);
    };

    private prevStep = (step: MentorOnboardingSteps, stepData?: any) => {
        // TODO fix sending data when going back to previous step and not having data saved
        // if (step === MentorOnboardingSteps.SERVICE_PACKAGES && stepData !== null && this.props.mentorData.serviceConfig.freeServiceInstanceUsedLimit === 0) {
        // 	this.sendMentorFreePackageData(stepData, this.props.mentorId)
        // }
        this.setState({currentStep: step});
        window.scrollTo(0, 0);
    };

    private renderFormStep() {
        switch (this.state.currentStep) {
            case MentorOnboardingSteps.ONBOARDING_INFORMATION:
                return (
                    <FormStepOnboardingInformation
                        submitStep={this.submitStep}
                        stepData={this.isolatedStepValue(MentorOnboardingSteps.ONBOARDING_INFORMATION)}
                    />
                );
            case MentorOnboardingSteps.HOURLY_RATES:
                return (
                    <FormStepHourlyRates
                        submitStep={this.submitStep}
                        prevStep={() => this.prevStep(MentorOnboardingSteps.ONBOARDING_INFORMATION)}
                        serviceApplicationElementsGeneral={this.state.serviceConsultationsList}
                        stepData={this.isolatedStepValue(MentorOnboardingSteps.HOURLY_RATES)}
                        mentorFee={this.mentorFee}
                    />
                );
            case MentorOnboardingSteps.APPLICATION_EXAM_PRICES:
                return (
                    <FormStepApplicationExamPrices
                        submitStep={this.submitStep}
                        prevStep={() => this.prevStep(MentorOnboardingSteps.HOURLY_RATES)}
                        serviceApplicationElementsExam={this.state.serviceApplicationElementsList}
                        stepData={this.isolatedStepValue(MentorOnboardingSteps.APPLICATION_EXAM_PRICES)}
                        mentorFee={this.mentorFee}
                    />
                );
            case MentorOnboardingSteps.HOUR_PACKAGES:
                const consultationPackageListWithUpdatedPrices = this.onboardingHelperService.updateCurrentConsultationPackages(
                    this.state.serviceConsultationPackagesList,
                    this.state.calculatedPackagePrices.serviceConsultationPackagePrices
                );
                return (
                    <FormStepHourPackages
                        submitStep={this.submitStep}
                        serviceConsultationPackageList={consultationPackageListWithUpdatedPrices}
                        prevStep={() => this.prevStep(MentorOnboardingSteps.APPLICATION_EXAM_PRICES)}
                        stepData={this.isolatedStepValue(MentorOnboardingSteps.HOUR_PACKAGES)}
                    />
                );
            case MentorOnboardingSteps.SERVICE_PACKAGES:
                const serviceApplicationPackageListWithUpdatedPrices = this.onboardingHelperService.updateCurrentServiceApplicationPackages(
                    this.state.serviceApplicationPackagesList,
                    this.state.calculatedPackagePrices.serviceApplicationPackagePrices
                );
                return (
                    <FormStepServicePackages
                        submitStep={this.submitStep}
                        serviceApplicationPackageList={serviceApplicationPackageListWithUpdatedPrices}
                        prevStep={() => this.prevStep(MentorOnboardingSteps.HOUR_PACKAGES)}
                        stepData={this.isolatedStepValue(MentorOnboardingSteps.SERVICE_PACKAGES)}
                    />
                );
            case MentorOnboardingSteps.FREE_HELP_PACKAGES:
                return (
                    <FormStepFreeHelpPackages
                        submitStep={this.submitStep}
                        mentorData={this.props.mentorData}
                        reloadUserData={this.props.reloadUserData}
                        freeServiceConsultationPackageList={this.state.freeServiceConsultationPackageList}
                        prevStep={() => this.prevStep(MentorOnboardingSteps.SERVICE_PACKAGES)}
                    />
                );
            case MentorOnboardingSteps.THANK_YOU:
                return <FormOnboardingThankYou/>;
            default:
                return;
        }
    }

    private convertMentorDataToRawFormData() {
        const updatedRawFormData = this.onboardingHelperService.convertMentorDataToRawFormData(this.props.mentorData.mentorServiceDefinitions, this.props.mentorData);
        this.setState({rawFormValue: updatedRawFormData});
    }

    private isolatedStepValue = (stepName: MentorOnboardingSteps): any => {
        const value = this.state?.rawFormValue?.[stepName];

        return (isNotNullOrUndefined(value) && Object.keys(value).length > 0) ?
            deepCloneObject(value) :
            null;
    };

    private sendMentorServiceDefinitionsPackage(stepValue: any, mentorId: string) {
        const additionalDataPayloadServiceItems = this.state.currentStep === MentorOnboardingSteps.HOUR_PACKAGES ?
                this.onboardingHelperService.updateCurrentConsultationPackages(
                    this.state.serviceConsultationPackagesList,
                    this.state.calculatedPackagePrices.serviceConsultationPackagePrices
                )
                : this.onboardingHelperService.updateCurrentConsultationPackages(
                    this.state.serviceApplicationPackagesList,
                    this.state.calculatedPackagePrices.serviceApplicationPackagePrices
                ),
            nextStepIndex = onboardingStepsArray.findIndex((step) => step.stepName === this.state.currentStep),
            updatedCurrentStep = onboardingStepsArray[nextStepIndex + 1].stepName,
            payload = this.onboardingHelperService.convertRawDataToPayload(
                stepValue,
                mentorId,
                this.state.currentStep,
                this.mentorFee,
                additionalDataPayloadServiceItems
            );
        if (payload.length === 0) {
            return this.setState({
                currentStep: updatedCurrentStep,
            });
        }
        this.setState({isProcessing: true});

        this.subscriptions.push(
            sendMentorServiceDefinitionsAPI(this.props.authToken, payload)
                .pipe(
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    tap((response: any) => {
                        if (response) {
                            let updatedRawFormValue = deepCloneObject(this.state.rawFormValue);
                            updatedRawFormValue[this.state.currentStep] = stepValue;
                            this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                            this.setState({
                                isProcessing: false,
                                rawFormValue: updatedRawFormValue,
                                currentStep: updatedCurrentStep,
                            });
                        }
                    })
                )
                .subscribe()
        );
    }

    private calculatePricesAndChangeStep(updatedCurrentStep: MentorOnboardingSteps) {
        this.setState({isProcessing: true});
        this.subscriptions.push(
            calculatePackagePricesAPI(this.props.authToken, this.props.mentorId)
                .pipe(
                    tap((response: any) =>
                        this.setState({
                            calculatedPackagePrices: response,
                            isProcessing: false,
                            currentStep: updatedCurrentStep,
                        })
                    ),
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.calculatePricesFailure');
                        this.setState({isProcessing: false});
                        return of();
                    })
                )
                .subscribe()
        );
    }

    private sendMentorServiceDefinitions(stepValue: any, mentorId: string) {
        const additionalDataPayloadServiceItems = {
            general: deepCloneObject(this.state.serviceConsultationsList),
            exam: deepCloneObject(this.state.serviceApplicationElementsList),
        };

        const nextStepIndex = onboardingStepsArray.findIndex((step) => step.stepName === this.state.currentStep),
            updatedCurrentStep = onboardingStepsArray[nextStepIndex + 1].stepName,
            payload = this.onboardingHelperService.convertRawDataToPayload(
                stepValue,
                mentorId,
                this.state.currentStep,
                this.mentorFee,
                additionalDataPayloadServiceItems
            );
        if (payload.length === 0) {
            return this.setState({
                currentStep: updatedCurrentStep,
            });
        }

        this.setState({isProcessing: true});

        this.subscriptions.push(
            sendMentorServiceDefinitionsAPI(this.props.authToken, payload)
                .pipe(
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    switchMap(() => calculatePackagePricesAPI(this.props.authToken, mentorId)),
                    tap((response: any) => {
                        if (response) {
                            let updatedRawFormValue = deepCloneObject(this.state.rawFormValue);
                            updatedRawFormValue[this.state.currentStep] = stepValue;
                            this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                            this.props.reloadUserData();
                            this.setState({
                                isProcessing: false,
                                rawFormValue: updatedRawFormValue,
                                calculatedPackagePrices: response,
                                currentStep: updatedCurrentStep,
                            });
                        }
                    })
                )
                .subscribe()
        );
    }

    private sendOnboardingData = (mentorId: string, mentorSurveyPayload: any): void => {
        this.setState({isProcessing: true});

        this.subscriptions.push(
            sendMentorOnboardingSurveyAPI(this.props.authToken, mentorId, mentorSurveyPayload)
                .pipe(
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.onboardingSendingFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    tap((response: MentorAccount) => {
                        if (response) {
                            this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.onboardingSendingSuccess');
                            this.setState({
                                isProcessing: false,
                                currentStep: MentorOnboardingSteps.THANK_YOU,
                            });
                        }
                    })
                )
                .subscribe()
        );
    };

    private getMentorServiceData() {
        const mentorServiceDataArray: any[] = [
            this.retrieveListData(
                getServiceApplicationElementsAPI(this.props.authToken, new RestQueryParams()),
                'serviceApplicationElementsList'
            ),
            this.retrieveListData(
                getServiceApplicationPackagesAPI(this.props.authToken, new RestQueryParams()),
                'serviceApplicationPackagesList'
            ),
            this.retrieveListData(
                getServiceConsultationPackagesAPI(this.props.authToken, new RestQueryParams()),
                'serviceConsultationPackagesList'
            ),
            this.retrieveListData(getServiceConsultationsAPI(this.props.authToken, new RestQueryParams()), 'serviceConsultationsList'),
        ];
        this.setState({isProcessing: true});
        this.subscriptions.push(
            forkJoin(mentorServiceDataArray)
                .pipe(
                    tap(() => {
                        this.setState({isProcessing: false});
                    })
                )
                .subscribe()
        );
    }

    private retrieveListData(api: Observable<any>, listName: string) {
        return api.pipe(
            filter((response) => !!response),
            tap((resp: any) => {
                if (resp['hydra:member']) {
                    let dataList = resp['hydra:member'];
                    const updatedState: any = {};

                    if (listName === 'serviceApplicationElementsList' && this.props.mentorData && this.props.mentorData.mentorTaskTypes) {
                        dataList = this.onboardingHelperService.filterServiceApplicationElements(dataList, this.props.mentorData.mentorTaskTypes);
                    }

                    if (listName === 'serviceApplicationPackagesList') {
                        updatedState['freeServiceConsultationPackageList'] = dataList.filter((dataItem: ServiceApplicationPackage) => dataItem.free);
                        dataList = dataList.filter((dataItem: ServiceApplicationPackage) => !dataItem.free);
                    }

                    updatedState[listName] = dataList;

                    this.setState(updatedState);
                }
            }),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                return of();
            })
        );
    }

}

export default connect(
    (state: RootState) => ({
        username: usernameSelector(state),
        authToken: authTokenSelector(state),
    }),
    {}
)(MentorOnboarding);
