import {FormControlType} from "educat-common-web";

export const addContentFilesFormConfig = (groups: any) => ({
    controlType: 'form',
    class: 'assign-materials',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: groups && groups.length > 0 ? [...groups] : [],
});


export const taskGroup = (groupName: string, controls: any) => ({
    key: `${groupName}`,
    controlType: 'group',
    class: 'row',
    controls: controls
});

export const createFormControls = (applicant: {[key: string]: any}) => {
    let controls = {};
    controls = Object.assign(controls, {
        [applicant.value]: {
            controlType: 'control',
            defaultValue: false,
            value: false,
            formControlType: FormControlType.CHECKBOX,
            validationRules: [],
            placeholder: '',
            label: applicant.label,
            isLabelHidden: false,
            isCheckboxLabelRaw: false,
            checkboxLabel: ``,
            hostClass: 'applicant-checkbox',
        },
    });

    return controls;
};
