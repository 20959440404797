import React from 'react';
import {Observable, of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {
    AddTaskModal,
    changeTaskGradeAPI,
    changeTaskStatusAPI,
    CustomCard,
    DateComponent,
    getDisplayName,
    getTasksAPI,
    IModelTask,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isSameValue,
    TaskStatus,
    TaskStatusCell,
    Translation,
    Task,
    ViewTaskModal
} from 'educat-common-web';
import styles from './styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import {catchError, delay, tap} from 'rxjs/operators';


interface IApplicantTasksConnectedProps {
}

interface IApplicantTasksExternalProps {
    readonly isLoading: boolean;
    readonly applicantId: string | null;
    readonly authToken: string | null;
}

interface IApplicantTasksProps extends IApplicantTasksConnectedProps,
    IApplicantTasksExternalProps,
    WithTranslation {
}

interface IApplicantTasksState {
    changeStatus: boolean;
    expandedTasksShown: boolean;
    selectedId: string | null;
    isAddTaskModalVisible: boolean;
    isLoading: boolean;
    tasks: typeof IModelTask[];
    taskSelected: typeof Task | null;
}

class ApplicantTasks extends React.Component<IApplicantTasksProps, IApplicantTasksState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private statusRef: any;

    constructor(props: IApplicantTasksProps) {
        super(props);

        this.state = {
            changeStatus: false,
            expandedTasksShown: false,
            selectedId: null,
            isAddTaskModalVisible: false,
            isLoading: false,
            tasks: [],
            taskSelected: null,
        };

        fixInjectedProperties(this);
        this.statusRef = React.createRef();
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
        this.getTasks();
    }

    componentDidUpdate(prevProps: Readonly<IApplicantTasksProps>, prevState: Readonly<IApplicantTasksState>, snapshot?: any): void {
        if (!isSameValue(this.props.applicantId, prevProps.applicantId)) {
            this.getTasks();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    render() {
        return (
            <>
                <CustomCard showLocalLoader={this.props.isLoading || this.state.isLoading}>
                    <CustomCard.Header>
                        <h2 className="custom-card-title">
                            <Translation text="applicants.applicantView.tasks.title"/>
                        </h2>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        {!Array.isArray(this.state.tasks) || (this.state.tasks.length === 0 && !this.props.isLoading) ?
                            (<p>
                                <Translation text={'applicants.applicantTasks.table.noData'}/>
                            </p>) :

                            <table className="data-table default-table">
                                <thead>
                                <tr>
                                    <th className="highlight-cell">
                                        <Translation text={'applicants.applicantView.tasks.table.title'}/>
                                    </th>
                                    <th className="highlight-cell">
                                        <Translation text={'applicants.applicantView.tasks.table.deadline'}/>
                                    </th>
                                    <th className="highlight-cell">
                                        <Translation text={'applicants.applicantView.tasks.table.status'}/>
                                    </th>
                                    <th className="highlight-cell">
                                        <Translation text={'applicants.applicantView.tasks.table.grade'}/>
                                    </th>
                                    <th className="highlight-cell">
                                        <Translation text={'applicants.applicantView.tasks.table.mentor'}/>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.renderTableRows()}
                                </tbody>
                            </table>
                        }

                        <div className={`button-wrapper ${styles.btnWrapper}`}>
                            <button className="btn btn-theme" onClick={() => this.toggleAddTaskModal()}>
                                <Translation text="applicants.applicantView.tasks.addNew"/>
                            </button>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
                {this.state.isAddTaskModalVisible ?
                    (<AddTaskModal isModalVisible={this.state.isAddTaskModalVisible}
                                   applicantId={this.props.applicantId}
                                   refreshList={this.getTasks}
                                   toggleModal={() => this.toggleAddTaskModal()}/>) : null
                }
                {this.state.taskSelected ?
                (<ViewTaskModal isModalVisible={this.state.taskSelected}
                    task={this.state.taskSelected}
                    toggleModal={() => this.closeViewTaskModal()} />) : null
                }
            </>
        )
    }

    private renderTableRows() {
        if (isNullOrUndefined(this.state.tasks)) {
            return null;
        }

        return this.state.tasks.map((item: typeof IModelTask) => {
            return <tr key={item.id}>
                <td className="highlight-cell">
                    <button type="button"  id={item.id}  className="main-information task-list-item" onClick={() => this.selectTask(item)}>
                        {item.subject}
                    </button>
                </td>
                <td>
                    <DateComponent date={item.endsAt} format={'DD/MM/yyyy'}/>
                </td>
                <td className="status-type-cell">
                    <TaskStatusCell status={item.status}
                                    id={item.id}
                                    taskStatusChange={this.changeTaskStatus}
                                    isApplicantView={false}/>
                </td>
                <td className="status-type-cell">
                    <TaskStatusCell status={item.review}
                                    id={item.id}
                                    taskStatusChange={this.changeTaskGrade}
                                    isApplicantView={false}
                                    isGradeType={true}/>
                </td>
                <td className="borderless">
                    {isNotNullOrUndefined(item.creator) ? getDisplayName(item.creator) : '--'}
                </td>
            </tr>;
        });
    }


    private closeViewTaskModal() {
        if (this.state.taskSelected) this.setState({taskSelected: null})
    }

    private selectTask(task: typeof Task) {
        this.setState({taskSelected: task});
    }

    private handleOutsideClick = (event: any) => {
        if (this.statusRef && this.statusRef.current && !this.statusRef.current.contains(event.target)) {
            this.setState({changeStatus: false});
        }
    };

    private toggleAddTaskModal = () => {
        this.setState({isAddTaskModalVisible: !this.state.isAddTaskModalVisible});
    };

    private changeTaskStatus = (taskId: string, newStatus: typeof TaskStatus) => {
        if (!this.props.authToken) {
            return null;
        }

        this.subscriptions.push(
            this.handleApiRequest(
                changeTaskStatusAPI(this.props.authToken, taskId, newStatus),
                'applicants.applicantView.tasks.statusChangeSuccess'
            ).subscribe()
        );
    };

    private changeTaskGrade = (taskId: string, newGrade: number) => {
        if (!this.props.authToken) {
            return null;
        }

        this.subscriptions.push(
            this.handleApiRequest(
                changeTaskGradeAPI(this.props.authToken, taskId, Number(newGrade)),
                'applicants.applicantView.tasks.gradeChangeSuccess'
            ).subscribe()
        );
    };

    private handleApiRequest(api: Observable<any>, successMessage: string) {
        this.setState({isLoading: true});
        return api.pipe(
            delay(1000),
            tap(() => {
                this.alertManager.addAlert(successMessage);
                this.setState({isLoading: false});
                this.getTasks();
            }), catchError((err: any) => {
                this.alertManager.handleApiError(err);
                this.setState({isLoading: false});
                return of();
            })
        );
    }

    private getTasks = (): void => {
        if (isNullOrUndefined(this.props.applicantId)) {
            return;
        }
        if (this.state.isLoading) {
            return;
        }
        this.setState({isLoading: true});

        this.subscriptions.push(
            getTasksAPI(this.props.authToken, this.props.applicantId).pipe(
                tap((resp: any) => {
                    this.setState({isLoading: false, tasks: (resp?.['hydra:member'] || [])})
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isLoading: false});
                    return of(error);
                })
            ).subscribe()
        );
    };
}

export default withTranslation()(ApplicantTasks);
