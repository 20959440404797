import {isNotEmpty, Translation} from 'educat-common-web';
import React from 'react';
import styles from './styles.module.scss';


interface IExternalApplicantInformationRowProps {
    readonly keyName: string;
    readonly value: any;
}

interface IApplicantInformationRowProps extends IExternalApplicantInformationRowProps {
}

interface IApplicantInformationRowState {
}


class ApplicantInformationRow extends React.Component<IApplicantInformationRowProps, IApplicantInformationRowState> {

    render() {
        return (
            <tr>
                <td className="data-table-title">
                    <Translation text={`applicants.applicantView.information.${this.props.keyName}`}/>
                </td>
                <td>
                    {isNotEmpty(this.props.value) ?
                        this.props.value :
                        <p className={styles.notSelected}>
                            <Translation text="applicants.applicantView.information.notSelected"/>
                        </p>
                    }
                </td>
            </tr>
        );
    }
}

export default ApplicantInformationRow;
