import React from "react";
import {EditorState, ContentState, convertToRaw, convertFromHTML} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {isNotNullOrUndefined, isSameValue, Translation} from "educat-common-web";
import parse from 'html-react-parser';


const toolbarConfig = {
    options: [ 'blockType', 'list',],
    blockType: {
    inDropdown: false,
        options: ['Normal'],
        className: undefined,
        component: () => (<button className='richtext-paragraph-button'>P</button>),
        dropdownClassName: undefined,
    },
    list: {
        inDropdown: false,
        className: 'richtext-list-controls',
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered'],
    },
};

interface IRichTextComponentProps {
    value: string | undefined | null;
    updateData: (type: string, value: string) => void;
    label: string;
    type?: string;
}

interface IRichTextComponentState {
    editorState: any;
    isEditable: boolean;
}

export class RichTextComponent extends React.Component<IRichTextComponentProps, IRichTextComponentState>  {
    private editor: React.RefObject<any>;

    constructor(props: IRichTextComponentProps) {
        super(props);
        this.state = {
            editorState: isNotNullOrUndefined(this.props.value) ?
                EditorState.createWithContent(ContentState.createFromText(this.props.value)) :
                EditorState.createEmpty(),
            isEditable: false
        };
        this.editor = React.createRef();

    }

    componentDidMount(): void {
        if (isNotNullOrUndefined(this.props.value)) {
            const blocks = convertFromHTML(this.props.value);
            this.setState({
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap))
            });
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IRichTextComponentProps>,
        prevState: Readonly<IRichTextComponentState>,
        snapshot?: any): void {
        if (!isSameValue(this.props.value, prevProps.value)) {
            if (isNotNullOrUndefined(this.props.value)) {
                const blocks = convertFromHTML(this.props.value);
                this.setState({
                    editorState: EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap))
                });
            }
        }
    }

    render() {
        return (
            <>
                <div className="paragraph">
                    <Translation text={this.props.label} />
                    {this.renderEditBtn(this.state.isEditable,
                        () => this.setState({isEditable: !this.state.isEditable}))}
                </div>

                {this.state.isEditable ? (
                    <Editor
                        editorState={this.state.editorState}
                        wrapperClassName="richtext-wrapper"
                        toolbarClassName="richtext-toolbar"
                        editorClassName="richtext-editor"
                        onEditorStateChange={this.onChange}
                        toolbar={toolbarConfig}
                        localization={{
                        locale: 'pl',
                    }}
                    />
                ) : (isNotNullOrUndefined(this.props.value)
                    ? (<div className="description-paragraph">{parse(this.props.value as string)}</div>)
                    : null)
                }
            </>
        );
    }

    private renderEditBtn = (isEditable: boolean, toggleView: () => void) => {
        return (
            <div className={'btnContainer'}>
                {isEditable ?
                    (<>
                        <button className="edit-details-button"
                                onClick={() => toggleView()}>
                            <i className="feather icon-x" />
                        </button>
                        <button className="edit-details-button"
                                onClick={() => {
                                    toggleView();
                                    if (this.props.type) {
                                        let converted = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
                                        this.props.updateData(this.props.type, converted);
                                    }
                                }}>
                            <i className="feather icon-check" />
                        </button>
                    </>) :
                    (<button className="edit-details-button"
                             onClick={() => toggleView()}>
                        <i className="feather icon-edit-2" />
                    </button>)
                }
            </div>
        );
    };

    private onChange = (editorState: any) => {
        this.setState({editorState});
    };
}

export default RichTextComponent;
