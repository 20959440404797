import {
    FormControlType,
    IFormConfig,
    InputType, MultiSelectType, ValidationRules
} from 'educat-common-web';

export const mentorBasicInformationFormConfig: typeof IFormConfig = (languageList: {[key: string]: any}[], value?: any) => ({
    controlType: 'form',
    class: 'onboarding-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'basic_information',
            controlType: 'group',
            class: 'row',
            controls: {
                displayName: {
                    controlType: 'control',
                    defaultValue: value ? value.displayName : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'mentorOnboarding.registration.basic_information.formControls.inputPlaceholder',
                    isLabelHidden: false,
                    label: 'mentorOnboarding.registration.basic_information.formControls.displayName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-row required',
                },
                birthdate: {
                    controlType: 'control',
                    defaultValue: value ? new Date(value.birthdate) : '',
                    additionalStyles: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {
                            name: ValidationRules.IS_DATE_LTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => new Date(),
                            }
                        },
                        {name: ValidationRules.IS_REQUIRED}
                    ],
                    placeholder: 'mentorOnboarding.registration.basic_information.formControls.datePlaceholder',
                    isLabelHidden: false,
                    label: 'mentorOnboarding.registration.basic_information.formControls.birthDate',
                    maxDate: new Date(),
                    type: InputType.DATE,
                    dateFormat: 'dd/MM/yyyy',
                    minDate: new Date(1920,1,1),
                    showYearDropdown: true,
                    showMonthDropdown: true,
                    openToDate: new Date(1990,1,1),
                    hostClass: 'col-xl-12 form-row onboarding-birthdate required',
                },
                address: {
                    controlType: 'control',
                    defaultValue: value ? value.address : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'mentorOnboarding.registration.basic_information.formControls.inputPlaceholder',
                    isLabelHidden: false,
                    label: 'mentorOnboarding.registration.basic_information.formControls.address',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-row required',
                },
                languages: {
                    controlType: 'control',
                    defaultValue: value ? value.languages : [],
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: languageList ? languageList : [],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'mentorOnboarding.registration.study_information.formControls.placeholder',
                    label: 'mentorOnboarding.registration.basic_information.formControls.languages.label',
                    hostClass: 'col-xl-12 form-row required',
                    inputDataMapper: (data: any) => {
                        // if (!data) {
                        //     return [];
                        // }

                        // return data.map((option: any) => ({
                        //     value: option.id,
                        //     label: option.name,
                        // }));
                        return data;
                    }
                },
                // photo: {
                //     hostClass: 'col-xl-12 form-row',
                //     controlType: 'control',
                //     // defaultValue: null,
                //     formControlType: FormControlType.INPUT,
                //     validationRules: [],
                //     placeholder: "",
                //     label: 'onboarding.registration.mentor.basic_information.formControls.photo.label',
                //     type: InputType.FILE,
                // },
            }
        }
    ]
});
