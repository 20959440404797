import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getRealmsAPI(authToken: string): Observable<any> {
    return educatAPI.get(
        'realms?itemsPerPage=1000',
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
