import {Form, IFormConfig, isSameValue, Translation} from "educat-common-web";
import React from "react";
import {MentorRegistrationSteps} from "../../../../service/mentorRegistrationService";
import {mentorDescriptionFormConfig} from "./mentorDescriptionFormConfig";

interface IConnectedFormStepMentorDescriptionProps {}

interface IExternalFormStepMentorDescriptionProps {
	readonly submitStep: (stepName: MentorRegistrationSteps, stepValue: any) => void;
	readonly prevStep: () => void;
	readonly stepData?: any;
}

interface IFormStepMentorDescriptionProps
	extends IConnectedFormStepMentorDescriptionProps,
		IExternalFormStepMentorDescriptionProps {}

interface IFormStepMentorDescriptionState {
	isStepValid: boolean;
	stepValue: any;
	stepName: MentorRegistrationSteps;
	formConfig: typeof IFormConfig;
}

class FormStepMentorDescription extends React.Component<
	IFormStepMentorDescriptionProps,
	IFormStepMentorDescriptionState
> {
	constructor(props: IFormStepMentorDescriptionProps) {
		super(props);

		this.state = {
			isStepValid: false,
			formConfig: mentorDescriptionFormConfig(this.props.stepData),
			stepValue: null,
			stepName: MentorRegistrationSteps.MENTOR_DESCRIPTION,
		};
	}

	componentDidMount() {
		if (this.props.stepData) {
			const updatedFormConfig = mentorDescriptionFormConfig(this.props.stepData);
			this.setState({formConfig: updatedFormConfig, stepValue: this.props.stepData});
		}
	}

	componentDidUpdate(
		prevProps: Readonly<IFormStepMentorDescriptionProps>,
		prevState: Readonly<IFormStepMentorDescriptionState>,
		snapshot?: any
	): void {
		if (this.state.stepValue && !isSameValue(this.state.stepValue, prevState.stepValue)) {
		}
	}

	render() {
		return (
			<>
				<div className="onboarding-form-wrapper">
					<Form
						config={this.state.formConfig}
						controlName={MentorRegistrationSteps.MENTOR_DESCRIPTION}
						onValueStateChange={this.formChangeHandler}
						onValidationStateChange={this.formValidityChange}
						value={this.state.stepValue}
					/>
				</div>
				<footer className="onboarding-navigation">
					<button type="button"
							onClick={() => this.props.prevStep()}
							className="btn btn-theme btn-back">
						<Translation text="buttons.back" />
					</button>
					<button type="submit"
							disabled={!this.state.isStepValid}
							onClick={() => this.props.submitStep(this.state.stepName, this.state.stepValue)}
							className="btn btn-theme btn-rounded">
						<Translation text="buttons.next" />
					</button>
				</footer>
			</>
		);
	}

	private formChangeHandler = (controlName: MentorRegistrationSteps, value: any, changeType: string) => {
		if (changeType !== "init") {
			this.setState({stepName: controlName, stepValue: value});
		}
	};

	private formValidityChange = (controlName: string, isValid: boolean) => {
		this.setState({isStepValid: isValid});
	};
}

export default FormStepMentorDescription;
