import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    authTokenSelector,
    changeBreadcrumbs,
    CustomCard,
    InputBasic,
    InputType,
    Pagination,
    RestQueryParams,
    Translation
} from 'educat-common-web';
import styles from './styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import ApplicantsTable from './ApplicantsTable';
import {catchError, debounceTime, filter, map, tap} from 'rxjs/operators';
import {getApplicantsAPI, IApplicantListingOutput} from '../../api/getApplicants';


interface IConnectedApplicantsProps {
    readonly authToken: string;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalApplicantsProps {
}

interface IApplicantsProps extends IConnectedApplicantsProps,
    IExternalApplicantsProps,
    RouteComponentProps,
    WithTranslation {
}

interface IApplicantsState {
    searchValue: string;
    applicants: IApplicantListingOutput[];
    listMetadata: { [key: string]: any } | null;
    isLoading: boolean;
}


class Applicants extends React.Component<IApplicantsProps, IApplicantsState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IApplicantsProps) {
        super(props);

        this.state = {
            searchValue: '',
            applicants: [],
            listMetadata: null,
            isLoading: true
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.applicants', path: '/panel/applicants'}
        ]);

        this.subscriptions.push(this.getApplicants());
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data),
                debounceTime(100),
                tap((data: any) => this.onFormValueChange(data.value))
            ).subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const {t} = this.props;
        return (
            <section className={`${styles.applicantsSection} applicants-section row`}>
                <div className="col-12 col-lg-10">
                    <CustomCard showLocalLoader={this.state.isLoading}>
                        <CustomCard.Header>
                            <div className={styles.header}>
                                <h2 className="custom-card-title">
                                    <Translation text="applicants.title"/>
                                </h2>

                                <div className={styles.actions}>
                                    <div className="form-control input-search ml-4">
                                        <InputBasic type={InputType.TEXT}
                                                    placeholder={t('applicants.search')}
                                                    className="input"
                                                    value={this.state.searchValue}
                                                    name="searchInput"
                                                    handleChange={(e: any) => this.onValueStateChange(e)}
                                                    autoComplete="off"/>
                                    </div>
                                </div>
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <ApplicantsTable applicants={this.state.applicants} isLoading={false}/>
                        </CustomCard.Body>
                    </CustomCard>
                    <Pagination listMetadata={this.state.listMetadata}
                                changePage={this.getApplicants}
                    />
                </div>
            </section>
        );
    }

    private onValueStateChange = (value: any) => {
        this.onValueStateChange$.next({value: value.target.value});
    };

    private onFormValueChange = (value: any) => {
        this.setState({
            isLoading: true,
            searchValue: value
        });

        this.subscriptions.push(this.getApplicants(null, value));
    };

    private getApplicants = (params?: typeof RestQueryParams, value?: string) => {
        let queryParams = params || new RestQueryParams();
        if (value) {
            queryParams = queryParams.add('account.lastName', value);
        }

        return getApplicantsAPI(this.props.authToken, queryParams).pipe(
            map((resp: any) => {
                this.setState({
                    isLoading: false,
                    applicants: resp['hydra:member'],
                    listMetadata: resp['hydra:view']
                })
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager?.handleApiError(error);
                return of(error);
            })
        ).subscribe();
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs,
    }
)(withRouter(Applicants)));
