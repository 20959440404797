import {Translation} from 'educat-common-web';
import React from 'react';
import {Subscription} from 'rxjs';
import {MentorOnboardingSteps} from '../converters/onboardingHelperService';

interface IConnectedOnboardingFormHeaderProps {
}

interface IExternalOnboardingFormHeaderProps {
    stepName: MentorOnboardingSteps;
}

interface IOnboardingFormHeaderProps extends IConnectedOnboardingFormHeaderProps, IExternalOnboardingFormHeaderProps {
}

interface IOnboardingFormHeaderState {
    tableHeadingItems: any[];
    formTitleTranslationKey: string | null;
    formSubtitleTranslationKey: string | null;
}

class OnboardingFormHeader extends React.Component<IOnboardingFormHeaderProps, IOnboardingFormHeaderState> {
    readonly subscriptions: Subscription[] = [];

    // TODO PPP configi powinny być przekazywane z zewnątrz komponentu

    constructor(props: IOnboardingFormHeaderProps) {
        super(props);
        this.state = {
            tableHeadingItems: [],
            formTitleTranslationKey: null,
            formSubtitleTranslationKey: null,
        };
    }

    componentDidMount() {
        this.setTranslationKeys(this.props.stepName);
    }

    render() {
        return (
            <div className="onboarding-form-title-wrapper">
                <h2 className="form-title">
                    <Translation text={this.state.formTitleTranslationKey}/>
                </h2>
                <div className="form-subtitle">
                    <Translation text={this.state.formSubtitleTranslationKey}/>
                </div>
                <div className="form-heading">{this.renderFormHeading(this.state.tableHeadingItems)}</div>
            </div>
        );
    }

    private setTranslationKeys(stepName: MentorOnboardingSteps) {
        let translationKeyName = '',
            headingItems: any[] = [];
        switch (stepName) {
            case MentorOnboardingSteps.HOURLY_RATES:
                translationKeyName = 'hourly_rates';
                headingItems = [
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.helpRange',
                        styles: 'col-xl-4',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.mentorGrossPrice',
                        styles: 'col-xl-2',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.eduCatCommission',
                        styles: 'col-xl-2',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.vat',
                        styles: 'col-xl-2',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.platformPrice',
                        styles: 'col-xl-2',
                    },
                ];
                break;
            case MentorOnboardingSteps.APPLICATION_EXAM_PRICES:
                translationKeyName = 'application_exam_prices';
                headingItems = [
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.helpRange',
                        styles: 'col-xl-4',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.mentorGrossPrice',
                        styles: 'col-xl-2',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.eduCatCommission',
                        styles: 'col-xl-2',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.vat',
                        styles: 'col-xl-2',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.platformPrice',
                        styles: 'col-xl-2',
                    },
                ];
                break;
            case MentorOnboardingSteps.HOUR_PACKAGES:
                translationKeyName = 'hour_packages';
                headingItems = [
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.hourPackages',
                        styles: 'col-xl-3',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.nettoWithoutDiscount',
                        styles: 'col-xl-3',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.clientDiscount',
                        styles: 'col-xl-3',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.nettoAfterDiscount',
                        styles: 'col-xl-3',
                    },
                ];
                break;
            case MentorOnboardingSteps.FREE_HELP_PACKAGES:
                translationKeyName = 'free_help_packages';
                headingItems = [
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.packages',
                        styles: 'col-xl-4',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.servicesInPackage',
                        styles: 'col-xl-5',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.maxApplicantNo',
                        styles: 'col-xl-3',
                    },
                ];
                break;
            case MentorOnboardingSteps.SERVICE_PACKAGES:
                translationKeyName = 'service_packages';
                headingItems = [
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.packages',
                        styles: 'col-xl-5',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.servicesInPackage',
                        styles: 'col-xl-4',
                    },
                    {
                        name: 'mentorOnboarding.onboarding.formTableHeader.eduCatPrice',
                        styles: 'col-xl-3',
                    },
                ];
                break;
            case MentorOnboardingSteps.THANK_YOU:
                break;
        }
        this.setState({
            formTitleTranslationKey: `mentorOnboarding.onboarding.${translationKeyName}.formTitle`,
            formSubtitleTranslationKey: `mentorOnboarding.onboarding.${translationKeyName}.formSubtitle`,
            tableHeadingItems: headingItems,
        });
    }

    private renderFormHeading = (headingItems: any[]) => {
        return headingItems.map((headingItem: any, index: number) => (
            <span key={`${headingItem.name}_${index}`} className={headingItem.styles ? headingItem.styles : ''}>
				<Translation text={headingItem.name}/>
			</span>
        ));
    };
}

export default OnboardingFormHeader;
