import React from 'react';
import {
    authTokenSelector,
    BasicModal,
    changeConsultationDataLoading,
    consultationSlotsToBlockSelector,
    CustomCard,
    CustomCardType,
    getAvailableConsultationSlots,
    IBlockerEvent,
    setConsultationDataError,
    setSlotsToBlock,
    Translation
} from 'educat-common-web';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import moment from "moment";
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {RootState} from '../../../../store/reducers';
import {calendarIdSelector} from '../../../../store/selectors/calendarSelectors';
import {editSlotsAvailability} from '../../../../api/editSlotsAvailabilityAPI';

interface IConnectedBlockSlotsCalendarModalProps {
    readonly slotsToBlock: typeof IBlockerEvent[];
    readonly calendarId: string,
    readonly authToken: string;
    readonly getAvailableConsultationSlots: typeof getAvailableConsultationSlots;
    readonly setSlotsToBlock: typeof setSlotsToBlock;
    readonly changeConsultationDataLoading: typeof changeConsultationDataLoading;
    readonly setConsultationDataError: typeof setConsultationDataError;
}

export interface IBlockSlotsCalendarModalProps extends IConnectedBlockSlotsCalendarModalProps {
    readonly blockSlotsModalShown: boolean,
    readonly redirectToMonth: any
    readonly toggleModal: () => void;
    readonly retrieveCalendarData: (from?: string, until?: string) => void;
}

interface IBlockSlotsCalendarModalState {
    isLoading: boolean;
}

class BlockSlotsCalendarModal extends React.Component<IBlockSlotsCalendarModalProps, IBlockSlotsCalendarModalState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
        };
        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.blockSlotsModalShown} closeModal={this.props.toggleModal}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <h2 className="modal-title"><Translation text={'calendar.modals.confirmBlockSlotsModal.title'}/></h2>
                            <button className="btn-modal-close" onClick={() => this.props.toggleModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><Translation text={'calendar.modals.confirmBlockSlotsModal.question'}/></p>
                        </div>
                    </CustomCard.Body>
                    <CustomCard.Footer>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-danger-outline me-4"
                                    type="button"
                                    onClick={() => this.props.toggleModal()}>
                                <Translation text={'calendar.modals.buttons.no'}/>
                            </button>
                            <button className="btn btn-theme"
                                    type="button"
                                    onClick={() => this.blockTimeSlots()}>
                                <Translation text={'calendar.modals.buttons.yes'}/>
                            </button>
                        </div>
                    </CustomCard.Footer>
                </CustomCard>
            </BasicModal>
        );
    }

    private blockTimeSlots = () => {
        const endOfDay = new Date().setHours(23, 59, 59, 999),
            availableUntil = moment(new Date(endOfDay).setMonth(new Date().getMonth() + 2)).toISOString(true);

        this.setState({isLoading: true});

        this.subscriptions.push(editSlotsAvailability(this.props.slotsToBlock, availableUntil, this.props.calendarId, this.props.authToken).pipe(
            tap(() => {
                this.props.setSlotsToBlock([]);
                this.alertManagerService.addAlert('calendar.modals.confirmBlockSlotsModal.success');
                this.setState({isLoading: false});
                this.getCurrentMonthAvailableSlots(this.props.calendarId);
                this.props.retrieveCalendarData();
                this.props.changeConsultationDataLoading(false);
                this.props.toggleModal();
            }),
            catchError((error: any) => {
                const errorMessage: string = error.response ? error.response['hydra:description'] : 'alerts.baseError';
                this.props.toggleModal();
                this.alertManagerService.handleApiError(errorMessage);
                return of(
                    this.props.changeConsultationDataLoading(false),
                    this.props.setConsultationDataError(error?.message)
                );
            })).subscribe());
    };

    private getCurrentMonthAvailableSlots(calendarId: string) {
        const date = new Date(),
            month = new Date().getMonth() + 1,
            firstDay = moment(new Date(date.getFullYear(), date.getMonth() - 1, 1)).toISOString(true),
            lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 2, 0)).toISOString(true);

        this.props.getAvailableConsultationSlots(firstDay, lastDay, calendarId, month);
    }
}

export default connect(
    (state: RootState) => ({
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
        slotsToBlock: consultationSlotsToBlockSelector(state),
    }),
    {
        setSlotsToBlock,
        getAvailableConsultationSlots,
        changeConsultationDataLoading,
        setConsultationDataError
    }
)(BlockSlotsCalendarModal);
