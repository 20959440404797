import {deepCloneObject, isNotNullOrUndefined, isNullOrUndefined, isSameValue} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {ITimeSlotItem, toggleTimeSlotAvailability} from '../../../../../../../store/reducers/calendarSlice';
import styles from './styles.module.scss';

interface ICalendarTimeSlotState {
    isFree: boolean;
}

interface ICalendarTimeSlotProps {
    readonly timeSlot: ITimeSlotItem;
    readonly toggleTimeSlotAvailability: typeof toggleTimeSlotAvailability;
}

class CalendarTimeSlot extends React.Component<ICalendarTimeSlotProps, ICalendarTimeSlotState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFree: true
        };
    }

    componentDidMount() {
        if (this.props.timeSlot && this.props.timeSlot.isFree !== null) {
            return this.setState({isFree: this.props.timeSlot.isFree});
        }
        return;
    }


    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (isNotNullOrUndefined(this.props.timeSlot) && !isSameValue(this.props.timeSlot.isFree, prevProps.timeSlot.isFree)) {
            this.setState({isFree: this.props.timeSlot.isFree});
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.renderDaySlot()}
            </React.Fragment>
        );
    }


    private renderDaySlot = () => {
        if (isNullOrUndefined(this.props.timeSlot)) {
            return;
        }
        return <li key={`${this.props.timeSlot.dayName} + ${this.props.timeSlot.label}`}
                   className={`${styles.calendarTimeSlot} ${this.state.isFree ? styles.isFree : ''}`}
                   onClick={() => this.toggleSlotFree()}>
            {this.props.timeSlot.label}
        </li>;
    };

    private toggleSlotFree = () => {
        const timeSlotToToggle = deepCloneObject(this.props.timeSlot);
        this.props.toggleTimeSlotAvailability(timeSlotToToggle);
        this.setState({isFree: !this.state.isFree});
    };
}

export default connect(
    () => ({}),
    {
        toggleTimeSlotAvailability
    }
)(CalendarTimeSlot);
