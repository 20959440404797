import {Form, IFormConfig, Translation} from 'educat-common-web';
import React from 'react';
import {Subscription} from 'rxjs';
import {MentorOnboardingSteps} from '../Common/converters/onboardingHelperService';
import {mentorOnboardingInformationFormConfig} from './mentorOnboardingInformationFormConfig';

interface IConnectedFormStepOnboardingInformationProps {
}

interface IExternalFormStepOnboardingInformationProps {
    readonly submitStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly stepData?: any;
}

interface IFormStepOnboardingInformationProps
    extends IConnectedFormStepOnboardingInformationProps,
        IExternalFormStepOnboardingInformationProps {
}

interface IFormStepOnboardingInformationState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorOnboardingSteps;
    formConfig: typeof IFormConfig;
}

class FormStepOnboardingInformation extends React.Component<IFormStepOnboardingInformationProps,
    IFormStepOnboardingInformationState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IFormStepOnboardingInformationProps) {
        super(props);

        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: mentorOnboardingInformationFormConfig(this.props.stepData),
            stepValue: null,
            stepName: MentorOnboardingSteps.ONBOARDING_INFORMATION,
        };
    }

    componentDidMount() {
        if (this.props.stepData) {
            const updatedFormConfig = mentorOnboardingInformationFormConfig(this.props.stepData);
            this.setState({formConfig: updatedFormConfig, stepValue: this.props.stepData});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className="onboarding-form-wrapper">
                    <div className="onboarding-form-title-wrapper">
                        <div className="form-subtitle">
                            <Translation text="mentorOnboarding.onboarding.onboarding_information.additionalInfo"/>
                        </div>
                    </div>
                    <Form
                        config={this.state.formConfig}
                        controlName={MentorOnboardingSteps.ONBOARDING_INFORMATION}
                        onValueStateChange={this.formChangeHandler}
                        onValidationStateChange={this.formValidityChange}
                        value={this.state.stepValue}
                    />
                </div>
                <footer className="onboarding-navigation justify-content-end">
                    <button
                        type="submit"
                        disabled={!this.state.isStepValid}
                        onClick={(e) => this.props.submitStep(this.state.stepName, this.state.stepValue)}
                        className="btn btn-theme btn-rounded">
                        <Translation text="buttons.next"/>
                    </button>
                </footer>
            </>
        );
    }

    private formChangeHandler = (controlName: MentorOnboardingSteps, value: any, changeType: string) => {
        if (changeType !== 'init') {
            this.setState({stepName: controlName, stepValue: value});
        }
    };
    private formValidityChange = (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };
}

export default FormStepOnboardingInformation;
