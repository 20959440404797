import {FormControlType, IFormConfig, InputType} from 'educat-common-web';


export const filterFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: 'row',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'start_date',
            class: 'col-4',
            controls: {
                startDate: {
                    controlType: 'control',
                    defaultValue: null,
                    formControlType: FormControlType.DATE,
                    validationRules: [],
                    placeholder: '',
                    label: 'consultations.form.labels.from',
                    type: InputType.DATE,
                    hostClass: 'consultation-date'
                }
            }
        },
        {
            controlType: 'group',
            key: 'end_date',
            class: 'col-4',
            controls: {
                endDate: {
                    controlType: 'control',
                    defaultValue: null,
                    formControlType: FormControlType.DATE,
                    validationRules: [],
                    placeholder: '',
                    label: 'consultations.form.labels.to',
                    type: InputType.DATE,
                    hostClass: 'consultation-date'
                }
            }
        },
        {
            controlType: 'group',
            key: 'applicant',
            class: 'col-4',
            controls: {
                applicant: {
                    controlType: 'control',
                    defaultValue: null,
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'consultations.form.placeholders.search',
                    isLabelHidden: true,
                    label: '',
                    type: InputType.TEXT,
                    hostClass: 'form-control input-search',
                }
            }
        },
    ]
};
