import {
    IFormConfig
} from 'educat-common-web';

export const mentorServicePackagesFormConfig: typeof IFormConfig = (groups: any[]) => ({
    controlType: 'form',
    class: 'onboarding-form onboarding-table',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: groups && groups.length > 0 ? [...groups] : [],
});


export const taskGroup: typeof IFormConfig = (groupKey: string, groupName: string, controls: any, isFieldset: boolean) => ({
    key: `${groupKey}`,
    controlType: isFieldset ? 'fieldset' : 'group',
    fieldsetTitle: isFieldset ? groupName : null,
    class: isFieldset ? 'row help-range onboarding-fieldset' : 'row',
    controls: controls
});
