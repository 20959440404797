import React, {Component} from 'react';
import {
    authTokenSelector,
    diffKeys,
    Form,
    FormControlChangeType,
    IFormConfig,
    IMultiselectOption,
    isNotEmpty,
    isNullOrUndefined,
    isSameCollection,
    isSameValue,
    Loader,
    RestQueryParams,
    SchoolType,
    LoaderType,
    isNotNullOrUndefined,
    deepCloneObject
} from "educat-common-web";
import {catchError, filter, map, tap} from "rxjs/operators";
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from "rxjs";
import {addScheduleFormConfig, VOLATILE_SCHEDULE_FORM_KEYS} from "./scheduleStudyFieldFormConfig";
import {getSchoolStudyFieldsAPI} from "../../../../api/getSchoolStudyFields";
import {getSchoolAPI} from "../../../../api/getSchool";
import {getSchoolStudyFieldAPI} from "../../../../api/getSchoolStudyField";
import {getSchoolsAPI} from "../../../../api/getSchools";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {getCountriesAPI} from "../../../../api/getCountries";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {WithTranslation, withTranslation} from "react-i18next";


export enum ScheduleStudyFieldFormType {
    SEARCH_SCHEDULE = 'search',
    ADD_SCHEDULE = 'add-schedule'
}
interface IChoicesCollectionUpdateMetadata<T = any> {
    readonly collection: T[];
    readonly changed: boolean | null;
}

interface IConnectedScheduleStudyFieldFormProps {
    readonly authToken: string | null;
}

interface IExternalScheduleStudyFieldFormProps {
    readonly updateScheduleDetails: (value: {[key: string]: any}) => void;
    readonly type: ScheduleStudyFieldFormType;
    readonly schedule?: {[key: string]: any} | null;
}

interface IScheduleStudyFieldFormProps extends
    IConnectedScheduleStudyFieldFormProps,
    IExternalScheduleStudyFieldFormProps,
    WithTranslation {
}

interface IScheduleStudyFieldFormState {
    formConfig: typeof IFormConfig;
    value: any;
    countryValues: typeof IMultiselectOption[];
    collegeValues: typeof IMultiselectOption[];
    schoolStudyFieldValues: typeof IMultiselectOption[];
    isDataLoading: boolean | null;
    preloadedCollegeValue: typeof IMultiselectOption | null;
    preloadedSchoolStudyFieldValue: typeof IMultiselectOption | null;
}

class ScheduleStudyFieldForm extends Component<IScheduleStudyFieldFormProps, IScheduleStudyFieldFormState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IScheduleStudyFieldFormProps) {
        super(props);

        this.state = {
            formConfig: null,
            value: null,
            countryValues: [],
            collegeValues: [],
            schoolStudyFieldValues: [],
            isDataLoading: null,
            preloadedCollegeValue: null,
            preloadedSchoolStudyFieldValue: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getCountries();

        if (this.props.schedule) {
            this.createFormValuesFromState(this.props.schedule);
        }
        this.preloadFormValues();
        this.setAddScheduleFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );

        this.configureForm();
    }

    componentDidUpdate(
        prevProps: Readonly<IScheduleStudyFieldFormProps>,
        prevState: Readonly<IScheduleStudyFieldFormState>,
        snapshot?: any
    ) {
        this.subscriptions = this.subscriptions.filter(subscription => !subscription.closed);

        if (!isSameValue(this.props.schedule, prevProps.schedule) && this.props.schedule) {
            this.createFormValuesFromState(this.props.schedule)
        }
        this.preloadFormValues();
        this.updateFormConfigIfNecessary(prevProps, prevState);
        this.configureForm(prevState);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.setState({value: null, formConfig: null});
    }

    render() {
        return (
            <>
                <Form config={this.state.formConfig}
                      value={this.state.value}
                      controlName={'scheduleStudyFieldFormConfig'}
                      onValueStateChange={this.onValueStateChange} />
                <Loader type={LoaderType.Local} showLoader={this.state.isDataLoading}/>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.setState({value: value});
        this.props.updateScheduleDetails(value);
    };

    private setAddScheduleFormConfig = (
        colleges: typeof IMultiselectOption[] = [],
        schoolStudyFields: typeof IMultiselectOption[] = []
    ): void => {
        const scheduleFormConfig = addScheduleFormConfig(
            this.state.value,
            this.state.countryValues,
            colleges,
            schoolStudyFields,
            (value: any, controlName: string) => this.handleMultiselectInputChange(value, controlName)
        );

        this.setState({formConfig: scheduleFormConfig});
    };

    private configureForm = (prevState?: IScheduleStudyFieldFormState) => {
        const hasCountry = isNotEmpty(this.state?.value?.country);
        const updates: ((state: { value: any }) => any)[] = [];

        if (hasCountry && isNotNullOrUndefined(prevState?.value) && this.state.value.country !== (prevState as any).value?.country) {
            updates.push((state) => {
                const scheduleFormValue = Object.assign({}, state.value);
                scheduleFormValue.school = null;
                scheduleFormValue.studyField = null;

                return {
                    collegeValues: [],
                    schoolStudyFieldValues: [],
                    preloadedCollegeValue: null,
                    preloadedSchoolStudyFieldValue: null,
                    scheduleFormValue
                };
            });
        }

        const hasSchool = isNotEmpty(this.state?.value?.school);
        if (hasSchool && (!prevState || this.state.value.school !== prevState.value?.school)) {
            let queryParams = new RestQueryParams();
            queryParams = queryParams.add('school.id', this.state.value.school);
            this.getMultiselectsData(getSchoolStudyFieldsAPI(this.props.authToken, queryParams), 'schoolStudyFieldValues');
        }

        if (updates.length > 0) {
            this.setState((state) => {
                let result: any = null;
                let handled = false;
                updates.forEach(update => {
                    result = handled ? update(result) : update(state);
                    handled = true;
                });

                return result;
            });
        }
    };

    private updateFormConfigIfNecessary = (prevProps: Readonly<IScheduleStudyFieldFormProps>, prevState: Readonly<IScheduleStudyFieldFormState>): void => {
        let newFormConfigNecessary = false;

        if (!newFormConfigNecessary && this.state.countryValues && this.state.countryValues !== prevState.countryValues) {
            newFormConfigNecessary = true;
        }

        if (!newFormConfigNecessary && !isSameValue(this.state.value, prevState.value)) {
            const diff = diffKeys(this.state.value, prevState.value);
            const changedVolatileKeys = VOLATILE_SCHEDULE_FORM_KEYS.filter(item => diff.indexOf(item) > -1);
            if (changedVolatileKeys) {
                newFormConfigNecessary = true;
            }
        }

        const colleges = this.hasChoicesCollectionChanged('collegeValues', prevState, newFormConfigNecessary);
        if (colleges.changed) {
            newFormConfigNecessary = true;
        }

        const schoolStudyFields = this.hasChoicesCollectionChanged('schoolStudyFieldValues', prevState, newFormConfigNecessary);
        if (schoolStudyFields.changed) {
            newFormConfigNecessary = true;
        }

        if (newFormConfigNecessary) {
            this.setAddScheduleFormConfig(colleges.collection, schoolStudyFields.collection);
        }
    };

    /**
     * Checks whether a given collection with form choices from State has changed.
     */
    private hasChoicesCollectionChanged<T = any>(
        stateKey: 'collegeValues' | 'schoolStudyFieldValues',
        prevState: Readonly<IScheduleStudyFieldFormState> | undefined | null,
        changedCheckRedundant: boolean): IChoicesCollectionUpdateMetadata<T> {

        const extract = (s: Readonly<IScheduleStudyFieldFormState> | undefined | null) => {
            const collection: any[] = [];
            if (isNullOrUndefined(s)) {
                return collection;
            }

            const state: any = s;
            collection.push(...(s as any)[stateKey]);
            const preloadedStateKey = `preloaded${stateKey.charAt(0).toUpperCase()}${stateKey.slice(1, stateKey.length - 1)}`;
            if (state[preloadedStateKey] && !collection.some(entry => entry.value === state[preloadedStateKey].value)) {
                collection.push(state[preloadedStateKey]);
            }

            return collection;
        };

        const current = extract(this.state),
            previous = extract(prevState);

        let changed = null;
        if (!changedCheckRedundant) {
            changed = !isSameCollection(current, previous);
        }

        return {
            collection: current,
            changed,
        };
    };

    private createFormValuesFromState = (schedule: any) => {
        const value = {
                country: schedule.schoolStudyFields.school.countries[0]?.id,
                school: schedule.schoolStudyFields.school.id,
                studyField: schedule.schoolStudyFields.studyField.id,
            };

        this.setState({
            value
        });

        this.props.updateScheduleDetails(value);
    };

    private preloadFormValues = (): void => {
        if (null !== this.state.isDataLoading || !this.state.value) {
            return;
        }

        const requests = {
            preloadedCollegeValue: this.state.value.school ?
                getSchoolAPI(this.state.value.school, this.props.authToken).pipe(
                    map((data: any) => ({value: data.id, label: data.name})),
                ) :
                of(null),
            preloadedSchoolStudyFieldValue: this.state.value.schoolStudyField ?
                getSchoolStudyFieldAPI(this.state.value.schoolStudyField, this.props.authToken).pipe(
                    map((data: any) => ({value: data.studyField.id, label: data.studyField.name})),
                ) :
                of(null)
        };

        this.setState({isDataLoading: true});
        this.subscriptions.push(
            forkJoin(requests).pipe(
                tap((result: any) => {
                    result.isDataLoading = false;

                    this.setState(result);
                })
            ).subscribe()
        );
    };

    private handleMultiselectInputChange = (value: string, controlName: string) => {
        if (value.length < 3) {
            return;
        }
        let queryParams = new RestQueryParams();
        queryParams = queryParams.add('name', value);

        if (controlName === 'school') {
            queryParams = queryParams.add('countries.id', this.state.value.country);
            queryParams = queryParams.add('type', SchoolType.College);

            this.loadSchools(queryParams, schools => this.setState({collegeValues: schools}));
        }
    };

    private loadSchools = (queryParams: typeof RestQueryParams, onSuccess: (schools: typeof IMultiselectOption[]) => void) => {
        return this.subscriptions.push(
            getSchoolsAPI(this.props.authToken, queryParams).pipe(
                map((resp: any) => resp['hydra:member']
                    .map((school: { [key: string]: any }) => ({
                        value: school.id,
                        label: school.name
                    }))),
                tap(onSuccess)
            ).subscribe()
        );
    };

    private getMultiselectsData = (api: Observable<any>, stateMultiselectOptions: string) => {
        return this.subscriptions.push(
            api.pipe(
                map((resp: any) => {
                    if (resp['hydra:member']) {
                        const multiselectOptions: {[key: string]: any}[] = (resp['hydra:member'] || [])
                            .map((option: {[key: string]: any}) => {
                                const label = option.name ? option.name :
                                    (option.studyField && option.studyField.name ? option.studyField.name : option.id),
                                    value = this.props.type === ScheduleStudyFieldFormType.ADD_SCHEDULE ? option.id : option.studyField.id;
                                return ({
                                    value: value,
                                    label: label
                                })});
                        let updatedState = deepCloneObject(this.state);
                        updatedState[stateMultiselectOptions] = multiselectOptions;

                        this.setState(updatedState);
                    }
                    return [];
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        );
    };

    private getCountries = () => {
        const {t} = this.props;
        return this.subscriptions.push(
            getCountriesAPI(this.props.authToken).pipe(
                map((resp: any) => {
                    if (resp['hydra:member']) {
                        const multiselectOptions: {[key: string]: any}[] = (resp['hydra:member'] || [])
                            .map((option: {[key: string]: any}) => {
                                return ({
                                    value: option.id,
                                    label: t(`country.${option.id}`)
                                })
                            });

                        this.setState({countryValues: multiselectOptions});
                    }
                    return [];
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        );
    };
}


export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(ScheduleStudyFieldForm));
