import React from 'react';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {
    accountSelector,
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    isSameValue,
    Translation
} from 'educat-common-web';
import styles from './styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import {assignScheduleFormConfig} from './assignScheduleFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {assignScheduleAPI} from '../../../../api/assignSchedule';
import {getApplicantsAPI} from '../../../../api/getApplicants';


interface IAssignApplicantModalConnectedProps {
    readonly authToken: string | null;
    readonly account: { [key: string]: any };
}

interface IAssignApplicantModalExternalProps {
    readonly schedule: { [key: string]: any } | null;
    readonly isModalVisible: boolean;
    readonly toggleModal: () => void;
}

interface IAssignApplicantModalProps extends IAssignApplicantModalConnectedProps,
    IAssignApplicantModalExternalProps,
    WithTranslation {
}

interface IApplicantChoiceOption {
    readonly value: string,
    readonly label: string,
    readonly logo: string | null,
}

interface IAssignApplicantModalState {
    formConfig: typeof IFormConfig;
    value: any;
    isModalVisible: boolean;
    applicants: IApplicantChoiceOption[];
    isLoading: boolean;
}

class AssignApplicantModal extends React.Component<IAssignApplicantModalProps, IAssignApplicantModalState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IAssignApplicantModalProps) {
        super(props);

        this.state = {
            formConfig: null,
            value: null,
            isModalVisible: false,
            applicants: [],
            isLoading: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getApplicants();
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IAssignApplicantModalProps>,
        prevState: Readonly<IAssignApplicantModalState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.schedule, prevProps.schedule) ||
            !isSameValue(this.state.applicants, prevState.applicants)) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.isModalVisible} closeModal={this.props.toggleModal}>
                <div className={styles.modalWrapper}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Header>
                            <div className={styles.modalHeader}>
                                <h2 className={styles.modalTitle}>
                                    <Translation text="schedules.details.assignToApplicant.title"/>
                                </h2>

                                <button className={styles.btnClose} onClick={() => this.props.toggleModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <Form config={this.state.formConfig}
                                  value={this.state.value}
                                  controlName={'assignScheduleFormConfig'}
                                  onValueStateChange={this.onValueStateChange}/>

                            <div className={styles.btnContainer}>
                                <button className="btn btn-theme"
                                        disabled={!this.state.value || !this.state.value.applicant}
                                        onClick={() => this.assignSchedule()}>
                                    <Translation text={'schedules.details.assignToApplicant.confirm'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
            </BasicModal>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.setState({value: value});
    };

    private setFormConfig = () => {
        const formConfig = assignScheduleFormConfig(
            this.props.schedule?.schoolStudyFields?.school?.name,
            this.props.schedule?.schoolStudyFields?.studyField?.name,
            this.state.applicants
        );

        this.setState({formConfig});
    };

    private getApplicants = () => {
        return this.subscriptions.push(
            getApplicantsAPI(this.props.authToken).pipe(
                map((resp: any) => {
                    if (resp['hydra:member']) {
                        const multiselectOptions: IApplicantChoiceOption[] = (resp['hydra:member'] || [])
                            .map((option: { [key: string]: any }) => {
                                const applicant = option.account,
                                    school = option.applicantSchoolStudyFields?.schoolStudyFields?.school?.name,
                                    studyField = option.applicantSchoolStudyFields?.schoolStudyFields?.studyField?.name,
                                    suffix = (school || studyField) ? `${school ? school + ', ' : ''}${studyField ? studyField : ''}` : null;

                                return {
                                    value: option.id,
                                    label: `${applicant.firstName} ${applicant.lastName}${suffix ? ' - ' + suffix : ''}`,
                                    logo: applicant.avatar?.tinyThumb,
                                }
                            });
                        this.setState({applicants: multiselectOptions});
                    }
                }),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };

    private assignSchedule = () => {
        this.setState({isLoading: true});
        let mentorId = this.state.value && this.state.value.addToEducat ? null : this.props.account.mentorId;

        if (this.props.schedule) {
            this.subscriptions.push(
                assignScheduleAPI(
                    this.props.authToken,
                    this.props.schedule.id,
                    this.state.value.applicant,
                    mentorId
                ).pipe(
                    map(() => {
                        this.setState({isLoading: false});
                        this.props.toggleModal();
                        this.alertManager.addAlert('schedules.details.assignToApplicant.scheduleAssignedToApplicant');
                    }),
                    catchError((error: any) => {
                        this.setState({isLoading: false});
                        this.alertManager.handleApiError(error);
                        return of(error);
                    })
                ).subscribe()
            )
        }
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state)
    }),
    {}
)(withTranslation()(AssignApplicantModal));
