import {authTokenSelector, Form, IFormConfig, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {changeMentorServiceConfigAPI} from '../../../../api/changeMentorServiceConfig';
import {sendMentorServiceDefinitionsAPI} from '../../../../api/sendMentorServiceDefinitions';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {MentorAccount} from '../../../../service/mentorRegistrationService';
import {RootState} from '../../../../store/reducers';
import {MentorOnboardingSteps, MentorServiceDefinitionPayload} from '../Common/converters/onboardingHelperService';
import OnboardingFormHeader from '../Common/OnboardingFormHeader';
import {mentorFreeHelpPackagesFormConfig} from './mentorFreeHelpPackagesFormConfig';

interface IConnectedFormStepFreeHelpPackagesProps {
    authToken: string;
}

interface IExternalFormStepFreeHelpPackagesProps {
    readonly submitStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly prevStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly reloadUserData: () => void;
    readonly freeServiceConsultationPackageList: any[];
    readonly mentorData: any;
}

interface IFormStepFreeHelpPackagesProps
    extends IConnectedFormStepFreeHelpPackagesProps,
        IExternalFormStepFreeHelpPackagesProps {
}

interface IFormStepFreeHelpPackagesState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorOnboardingSteps;
    formConfig: typeof IFormConfig;
    isStepFilled: boolean;
}

class FormStepFreeHelpPackages extends React.Component<IFormStepFreeHelpPackagesProps, IFormStepFreeHelpPackagesState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IFormStepFreeHelpPackagesProps) {
        super(props);

        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: null,
            stepValue: null,
            stepName: MentorOnboardingSteps.FREE_HELP_PACKAGES,
            isStepFilled: false,
        };
        fixInjectedProperties(this);

    }

    componentDidMount() {
        if (this.props.freeServiceConsultationPackageList.length) {
            const freePackage = this.props.freeServiceConsultationPackageList[0],
                stepData = this.getStepDataIfPresent(freePackage.id),
                isStepFilled = !!stepData;
            const updatedFormConfig = mentorFreeHelpPackagesFormConfig(freePackage, stepData);
            this.setState({formConfig: updatedFormConfig, isStepFilled: isStepFilled, stepValue: stepData});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className="onboarding-form-wrapper">
                    <OnboardingFormHeader stepName={this.state.stepName}/>
                    {this.state.formConfig &&
                        <Form
                            config={this.state.formConfig}
                            controlName={MentorOnboardingSteps.FREE_HELP_PACKAGES}
                            onValueStateChange={this.formChangeHandler}
                            onValidationStateChange={this.formValidityChange}
                            value={this.state.stepValue}
                        />
                    }
                    <div className="additional-information">
                        <Translation text="mentorOnboarding.onboarding.maxApplicantsAdditionalInformation"/>
                    </div>
                </div>
                <footer className="onboarding-navigation">
                    <button
                        type="button"
                        onClick={() => this.prevStep()}
                        className="btn btn-theme btn-back">
                        <Translation text="buttons.back"/>
                    </button>
                    <button
                        type="submit"
                        disabled={!this.state.isStepValid}
                        onClick={() => this.submitStep()}
                        className="btn btn-theme btn-rounded">
                        <Translation text="buttons.sendRequest"/>
                    </button>
                </footer>
            </>
        );
    }

    private formChangeHandler = (controlName: MentorOnboardingSteps, value: any, changeType: string) => {
        if (changeType !== 'init') {
            this.setState({stepName: controlName, stepValue: value});
        }
    };
    private formValidityChange = (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };

    private getStepDataIfPresent(packageId: string) {
        const mentorServiceDefinitions = this.props.mentorData.mentorServiceDefinitions;
        if (mentorServiceDefinitions.length === 0) {
            return null;
        }
        const packageDefinition = mentorServiceDefinitions.find((mentorServiceDefinition: any) => mentorServiceDefinition.serviceApplicationPackage?.id === packageId),
            maxApplicationsCount = this.props.mentorData.serviceConfig.freeServiceInstanceUsedLimit,
            stepValue = {
                selected: true,
                services: null,
                maxApplicants: maxApplicationsCount
            };
        return packageDefinition ? stepValue : null;
    }


    private submitStep() {
        if (this.state.isStepFilled || this.state.stepValue?.selected === false ||
            this.state.stepValue?.maxApplicants === 0 || !this.state.stepValue) {
            return this.props.submitStep(this.state.stepName, this.state.stepValue);
        }
        this.sendMentorFreePackageDataAndSubmitStep(this.state.stepValue, this.props.mentorData.id);
    }

    private prevStep() {
        if (this.state.isStepFilled || this.state.stepValue?.selected === false ||
            this.state.stepValue?.maxApplicants === 0 || !this.state.stepValue) {
            return this.props.prevStep(MentorOnboardingSteps.SERVICE_PACKAGES, this.state.stepValue);
        }
        this.sendMentorFreePackageDataAndSubmitStep(this.state.stepValue, this.props.mentorData.id, true);
    }

    private sendMentorFreePackageDataAndSubmitStep(stepValue: any, mentorId: string, isPrevStep?: boolean) {
        const freePackageItem = this.props.freeServiceConsultationPackageList[0],
            mentorServiceDefinition: MentorServiceDefinitionPayload = {
                name: freePackageItem.name,
                description: '',
                itemPrice: {
                    amount: 0,
                    currency: {
                        code: 'PLN',
                    },
                },
                mentorId: mentorId,
                serviceApplicationElementId: null,
                serviceApplicationPackageId: freePackageItem.id,
                serviceConsultationId: null,
                serviceConsultationPackageId: null
            },
            newMaxApplicants = stepValue.maxApplicants;
        const payload = [];

        payload.push(mentorServiceDefinition);
        this.setState({isProcessing: true});

        this.subscriptions.push(
            sendMentorServiceDefinitionsAPI(this.props.authToken, payload)
                .pipe(
                    switchMap(() => changeMentorServiceConfigAPI(this.props.authToken, mentorId, newMaxApplicants)),
                    tap((response: MentorAccount) => {
                        if (response) {
                            this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                            this.setState({
                                isProcessing: false,
                            });
                            if (isPrevStep) {
                                this.props.reloadUserData();
                                this.props.prevStep(this.state.stepName, this.state.stepValue);
                            } else {
                                this.props.submitStep(this.state.stepName, this.state.stepValue);
                            }
                        }
                    }),
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                )
                .subscribe()
        );
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(FormStepFreeHelpPackages);
