import React from 'react';
import {ConsultationStatus, DateComponent, finishOnlineConsultationAPI, Translation} from 'educat-common-web';
import styles from './styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import CancelConsultationModal from './CancelConsultationModal';
import {IConsultationTableData} from '../index';
import {RouteComponentProps, withRouter} from 'react-router-dom';


interface IConsultationsTableConnectedProps {
}

interface IConsultationsTableExternalProps {
    readonly groupedConsultations: IConsultationTableData[];
    readonly isLoading: boolean;
    readonly changeConsultationsList: () => void;
    readonly authToken: string;
}

interface IConsultationsTableProps extends IConsultationsTableConnectedProps,
    IConsultationsTableExternalProps,
    WithTranslation,
    RouteComponentProps {
}

interface IConsultationsTableState {
    isModalShown: boolean;
    consultationId: string | null;
}

class ConsultationsTable extends React.Component<IConsultationsTableProps, IConsultationsTableState> {
    constructor(props: IConsultationsTableProps) {
        super(props);

        this.state = {
            isModalShown: false,
            consultationId: null
        };
    }

    render() {
        if (!this.props.groupedConsultations ||
            !Array.isArray(this.props.groupedConsultations) ||
            !this.props.groupedConsultations.length) {
            return (
                <div className={styles.noResults}>
                    <p className={styles.description}><Translation text={'consultations.noData'}/></p>
                </div>
            )
        }

        return (
            <React.Fragment>
                <table className="data-table default-table">
                    <thead>
                    <tr>
                        <th className="highlight-cell">
                            <Translation text={'consultations.table.applicant'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'consultations.table.package'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'consultations.table.service'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'consultations.table.status'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'consultations.table.meetingsNo'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'consultations.table.meetings'}/>
                        </th>
                        <th className="highlight-cell"/>
                    </tr>
                    </thead>
                    {this.renderFormatTableRows()}
                </table>

                {this.state.isModalShown ?
                    (<CancelConsultationModal isModalVisible={this.state.isModalShown}
                                              consultationId={this.state.consultationId}
                                              closeModal={() => this.setState({
                                                  isModalShown: false,
                                                  consultationId: null
                                              })}
                                              changeConsultationsList={this.props.changeConsultationsList}/>) :
                    null
                }
            </React.Fragment>
        )
    }

    private toggleModal = (consultationId: string) => {
        this.setState({isModalShown: !this.state.isModalShown, consultationId: consultationId});
    };

    private getPackageName = (mentorServiceDefinitions: { [key: string]: any } | null): string => {
        let packageName = '---';
        if (!mentorServiceDefinitions) {
            return packageName;
        }
        if (mentorServiceDefinitions.serviceApplicationPackage) {
            packageName = mentorServiceDefinitions.serviceApplicationPackage.name;
        }
        if (mentorServiceDefinitions.serviceConsultationPackage) {
            packageName = mentorServiceDefinitions.serviceConsultationPackage.name;
        }

        return packageName;
    };


    private getServiceName = (mentorServiceDefinitions: { [key: string]: any } | null): string => {
        let serviceName = '---';
        if (!mentorServiceDefinitions) {
            return serviceName;
        }
        if (mentorServiceDefinitions.serviceConsultation) {
            serviceName = 'Godzina mentorska'
        }
        if (mentorServiceDefinitions.serviceApplicationElement) {
            serviceName = mentorServiceDefinitions.serviceApplicationElement.name;
        }

        return serviceName;
    };


    private renderFormatTableRows = () => {
        const {t} = this.props;
        return this.props.groupedConsultations.map((group: { [key: string]: any }) => {

            const eventValues = group.consultationsList,
                applicantName = group.applicant && group.applicant.account ?
                    `${group.applicant.account.firstName} ${group.applicant.account.lastName}` : '---';

            return (
                <tbody key={group.id}>
                {eventValues.map((item: { [key: string]: any }, i: number) => {
                    const isFirstRow = i === 0,
                        serviceInstance = item.serviceInstance,
                        mentorServiceDefinitions = item.mentorServiceDefinitions,
                        status = (item.status === ConsultationStatus.FINISHED) ? 'closed' : 'open',
                        usedLimit = serviceInstance ? serviceInstance.usedLimit : '---';

                    return (
                        <tr key={item.id}>
                            {
                                isFirstRow ?
                                    (
                                        <td className="highlight-cell custom-cell" rowSpan={eventValues.length + 1}>
                                            <a href={item.webContentLink} rel="noreferrer" target="_blank" className={styles.itemLink}>
                                                {applicantName}
                                            </a>
                                        </td>
                                    ) :
                                    null
                            }
                            <td>{isFirstRow ? this.getPackageName(mentorServiceDefinitions) : null}</td>
                            <td>{isFirstRow ? this.getServiceName(mentorServiceDefinitions) : null}</td>
                            <td>
                                <div className={styles.status}>
                                    <Translation text={`consultations.applicationStatuses.${status}`}/>
                                </div>
                            </td>
                            <td>{isFirstRow ? usedLimit : null}</td>
                            <td>
                                <div className={styles.meetingDate}>
                                    <span>
                                        {item.startsAt ? <DateComponent date={item.startsAt} format="DD/MM/YYYY, HH:mm" /> : '---'}
                                    </span>
                                    <button className={styles.cancelMeetingBtn}
                                            data-descr={t('consultations.cancelMeetingTooltip')}
                                            onClick={() => this.toggleModal(item.id)}>
                                        <i className="feather icon-trash-2"/>
                                    </button>
                                </div>
                            </td>
                            <td className="borderless text-center">
                                {this.renderConsultationButtons(item)}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            )
        });
    }

    private renderConsultationButtons(item: any) {

        if (item && item.status === 'finished') {
            return <p className={styles.consultationStatus}>
                <Translation text="consultations.meetingEnded"/>
            </p>
        } else if (item && item.agoraChannel && item.status === ConsultationStatus.STARTED) {
            return <div style={{display:'inline-grid', gridTemplateColumns: '1fr 1fr', gap: '5px'}}>
                <button className={styles.actionBtn}
                        onClick={() => this.props.history.push(`/panel/consultations/${item.id}`)}>
                    <Translation text={'consultations.joinMeeting'}/>
                </button>
                <button className={styles.actionBtn}
                           onClick={() => this.endConsultation(item)}>
                <Translation text={'consultations.endMeeting'}/>
                </button>
            </div>

        } else {
            return <button className={styles.actionBtn}
                           onClick={() => this.props.history.push(`/panel/consultations/${item.id}`)}>
                <Translation text={'consultations.startMeeting'}/>
                <i className="feather icon-video"/>
            </button>
        }
    }

    private endConsultation(consultation: any){
        finishOnlineConsultationAPI(this.props.authToken, consultation.id).subscribe(()=>{
            window.location.reload();
        });
    }
}

export default withTranslation()(withRouter(ConsultationsTable));
