import {Observable} from 'rxjs';
import {educatAPI, IModelAccount, IModelApplicantSchoolStudyFields, RestQueryParams} from 'educat-common-web';

export interface IApplicantListingOutput {
    readonly id: string;
    readonly account: typeof IModelAccount;
    readonly usedAmount: number | null;
    readonly usedLimit: number | null;
    readonly paidRange: boolean | null;
    readonly applicantSchoolStudyFields: typeof IModelApplicantSchoolStudyFields;
}

export function getApplicantsAPI(
    authToken: string | null,
    params?: typeof RestQueryParams
): Observable<IApplicantListingOutput> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        'applicants',
        queryParams,
        headers
    );
}
