import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getSchedulesAPI(authToken: string | null, params?: typeof RestQueryParams): Observable<any> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams();

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `schedules`,
        queryParams,
        headers
    );
}
