import {
    loginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    accountEpic,
    changePasswordEpic,
    onlineConsultationEpic,
    insurancesEpic
} from 'educat-common-web';
import {combineEpics} from 'redux-observable';
import sentryUserContextEpic from './sentryUserContextEpic';


export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    changePasswordEpic,
    accountEpic,
    onlineConsultationEpic,
    sentryUserContextEpic,
    insurancesEpic
);
