import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {connect} from 'react-redux';
import {authTokenSelector, changeBreadcrumbs, CustomCard, RestQueryParams, Translation, UserRole} from 'educat-common-web';
import {RootState} from '../../store/reducers';
import ConsultationsFilters from './ConsultationsFilters';
import ConsultationsTable from './ConsultationsTable';
import {catchError, map} from 'rxjs/operators';
import styles from './styles.module.scss';
import {getConsultationsAPI} from '../../api/getConsultations';


export interface IConsultationTableData {
    readonly applicant: { [key: string]: any };
    readonly consultationsList: { [key: string]: any }[];
    readonly id: string;
}

interface IConnectedConsultationsProps {
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly authToken: string;
}

interface IExternalConsultationsProps {
}

interface IConsultationsProps extends IConnectedConsultationsProps,
    IExternalConsultationsProps,
    RouteComponentProps {
}

interface IConsultationsState {
    groupedConsultations: IConsultationTableData[] | [];
    isLoading: boolean;
    isAddScheduleModalShown: boolean;
}


class Consultations extends React.Component<IConsultationsProps, IConsultationsState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IConsultationsProps) {
        super(props);

        this.state = {
            groupedConsultations: [],
            isLoading: true,
            isAddScheduleModalShown: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.consultations', path: '/panel/consultations'}
        ]);

        this.getConsultations();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <section className={`${styles.consultationsSection} row`}>
                    <div className="col-12">
                        <CustomCard showLocalLoader={this.state.isLoading}>
                            <CustomCard.Header>
                                <div className="row">
                                    <div className="col-xl-4 d-flex align-items-center">
                                        <h2 className="custom-card-title">
                                            <Translation text="consultations.title"/>
                                        </h2>
                                    </div>
                                    <div className="col-xl-8">
                                        <ConsultationsFilters changeConsultationsList={this.getConsultations}/>
                                    </div>
                                </div>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <ConsultationsTable groupedConsultations={this.state.groupedConsultations}
                                                    isLoading={this.state.isLoading}
                                                    changeConsultationsList={this.getConsultations}
                                                    authToken={this.props.authToken}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </section>
            </React.Fragment>
        );
    }

    private getConsultations = (params?: typeof RestQueryParams | null, path?: string) => {
        this.setState({isLoading: true});
        return this.subscriptions.push(
            getConsultationsAPI(this.props.authToken, params, path).pipe(
                map((resp: any) => {
                    let consultations: any[] = [];
                    let formattedConsultations: IConsultationTableData[] = [];
                    if (resp['hydra:member']) {
                        consultations = resp['hydra:member']
                            .filter((consultation: { [key: string]: any }) => consultation.status !== 'cancelled')
                            .reduce((prevValue: any, currValue: any) => {
                                prevValue[currValue?.serviceInstance?.id] = [...prevValue[currValue?.serviceInstance?.id] || [], currValue];
                                return prevValue;
                            }, {});

                        Object.keys(consultations).map((key: any) => {
                            return formattedConsultations.push({
                                applicant: consultations[key].map((item: { [key: string]: any }) => item.participants && item.participants.filter((participant: { [key: string]: any }) => {
                                    return participant.role === UserRole.APPLICANT;
                                })[0])[0],
                                consultationsList: consultations[key],
                                id: key,
                            });
                        });
                    }
                    this.setState({
                        groupedConsultations: formattedConsultations,
                        isLoading: false,
                    })
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isLoading: false});
                    return of();
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(Consultations));
