import {educatAPI, RestQueryParams} from "educat-common-web";
import {Observable} from "rxjs";

export function getMentorServicesAPI(authToken: string | null, mentorId: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.get(
        `mentors/${mentorId}/services`,
        new RestQueryParams(),
        headers
    );
}
