import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {authTokenSelector, changeBreadcrumbs, getApplicantName, IModelApplicant} from 'educat-common-web';
import {catchError, tap} from 'rxjs/operators';
import styles from '../styles.module.scss';
import ApplicantInfoCard from './ApplicantInfoCard';
import ApplicantInformation from './ApplicantInformation';
import ApplicantStudyFields from './ApplicantStudyFields';
import ApplicantContentFiles from './ApplicantContentFiles';
import {getApplicantAPI} from '../../../api/getApplicant';
import ApplicantSchedule from './ApplicantSchedule';
import ApplicantTasks from './ApplicantTasks';


interface IConnectedApplicantsProps {
    readonly authToken: string | null;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalApplicantsProps {
}

interface IApplicantsProps extends IConnectedApplicantsProps,
    IExternalApplicantsProps,
    RouteComponentProps {
}

interface IApplicantsState {
    isLoading: boolean;
    applicant: typeof IModelApplicant | null;
}


class ApplicantView extends React.Component<IApplicantsProps, IApplicantsState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IApplicantsProps) {
        super(props);

        this.state = {
            isLoading: true,
            applicant: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getApplicant();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <section className={`${styles.applicantsSection} row`}>
                <div className="col-xl-8">
                    <ApplicantInformation isLoading={this.state.isLoading} applicant={this.state.applicant}/>

                    <ApplicantStudyFields isLoading={this.state.isLoading}
                                          applicantSchoolStudyFields={this.state.applicant?.applicantSchoolStudyFields}/>

                    <ApplicantSchedule isLoading={this.state.isLoading}
                                       authToken={this.props.authToken}
                                       applicantId={this.state.applicant?.id}/>

                    <ApplicantTasks isLoading={this.state.isLoading}
                                    applicantId={this.state.applicant?.id}
                                    authToken={this.props.authToken}/>

                    <ApplicantContentFiles isLoading={this.state.isLoading}
                                           applicantAccountId={this.state.applicant?.account?.id}
                                           authToken={this.props.authToken}/>
                </div>

                <div className="col-4">
                    <ApplicantInfoCard isLoading={this.state.isLoading} account={this.state.applicant?.account}/>
                </div>
            </section>
        );
    }

    private getApplicant = () => {
        const routeState: any = this.props.location.state;
        let id: string | null | undefined = null;
        if (routeState) {
            this.changeBreadcrumbs(routeState.id, routeState.name);
            id = routeState.id;
        } else {
            const matches = /\/panel\/.+\/(.+)$/.exec(this.props.location.pathname);
            id = matches?.[1];
        }

        this.subscriptions.push(
            getApplicantAPI(this.props.authToken, id as string).pipe(
                tap((resp: any) => {
                    this.changeBreadcrumbs(id as string, getApplicantName(resp));

                    this.setState({isLoading: false, applicant: resp})
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isLoading: false});
                    return of(error);
                })
            ).subscribe());
    };

    private changeBreadcrumbs = (id: string, name: string): void => {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.applicants', path: '/panel/applicants'},
            {labelKey: name, path: `/panel/applicants/${id}`}
        ]);
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs,
    }
)(withRouter(ApplicantView));
