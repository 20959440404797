import {
    FormControlType,
    IFormConfig,
    InputType, MultiSelectType, ValidationRules
} from 'educat-common-web';

export const mentorClassesFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'onboarding-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: []
};

export const classesFormGroup = (subjectList: {[key: string]: any}[], value?: any) => ({
    key: 'classes',
    controlType: 'group',
    class: 'row',
    controls: {
        subjectClasses: {
            hostClass: 'col-xl-12 form-row required',
                controlType: 'control',
                defaultValue:  value && value.classes ? value.classes.subjectClasses : null,
                formControlType: FormControlType.RADIO,
                options: [
                    {value: 'yes', displayValue: 'tak'},
                    {value: 'no', displayValue: 'nie'},
                ],
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: "",
                label: 'mentorOnboarding.registration.classes.formControls.subjectClasses',
                type: InputType.RADIO,
        },
        subject: {
            controlType: 'control',
            defaultValue:  value && value.classes ? value.classes.subject : null,
            placeholder: 'mentorOnboarding.registration.classes.formControls.subjectClassesPlaceholder',
            formControlType: FormControlType.AUTOCOMPLETE,
            multiselectType: MultiSelectType.SINGLE,
            validationRules: [
            // { name: ValidationRules.IS_REQUIRED },
            ],
            multiselectOptions: subjectList ? subjectList : [],
            inputDataMapper: (data: any) => {
                if (!data) {
                    return [];
                }

                let subjectId = data.value ? data.value : data;
                return subjectList
                    .filter((subject: any) => subject.value === subjectId)
                    .map((subjectOption: any) => ({
                        value: subjectOption.value,
                        label: subjectOption.label,
                    }));
            },
            outputDataMapper: (data: any) => {
                if (Array.isArray(data)) {
                    return (data.length > 0) ? data[0].value : null;
                }
                if (data && typeof data === 'object') {
                    return data.value;
                }

                return data;
            },
            hostClass: `col-xl-12 form-row ${value && value.classes && value.classes.subjectClasses ===  'yes' ? '' : 'd-none'}`
        }
    }
});
