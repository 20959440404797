import { interfaces } from "inversify";
import "reflect-metadata";

const INJECTION = Symbol.for("INJECTION");

function _proxyGetter(
    proto: any,
    key: string,
    resolve: () => any,
    doCache: boolean
) {
    function getter(this: any) {
        if (doCache && !Reflect.hasMetadata(INJECTION, this, key)) {
            Reflect.defineMetadata(INJECTION, resolve(), this, key);
        }
        if (Reflect.hasMetadata(INJECTION, this, key)) {
            return Reflect.getMetadata(INJECTION, this, key);
        } else {
            return resolve();
        }
    }

    function setter(this: any, newVal: any) {
        Reflect.defineMetadata(INJECTION, newVal, this, key);
    }

    Object.defineProperty(proto, `__DI__${key}$`, {
        configurable: true,
        enumerable: true,
        get: getter,
        set: setter
    });
}

function makePropertyInjectDecorator(container: interfaces.Container, doCache: boolean) {
    return function(serviceIdentifier: interfaces.ServiceIdentifier<any>) {
        return function(proto: any, key: string): void {

            let resolve = () => {
                return container.get(serviceIdentifier);
            };

            _proxyGetter(proto, key, resolve, doCache);

        };
    };
}

function makePropertyInjectNamedDecorator(container: interfaces.Container, doCache: boolean) {
    return function(serviceIdentifier: interfaces.ServiceIdentifier<any>, named: string) {
        return function(proto: any, key: string): void {

            let resolve = () => {
                return container.getNamed(serviceIdentifier, named);
            };

            _proxyGetter(proto, key, resolve, doCache);

        };
    };
}

function makePropertyInjectTaggedDecorator(container: interfaces.Container, doCache: boolean) {
    return function(serviceIdentifier: interfaces.ServiceIdentifier<any>, key: string, value: any) {
        return function(proto: any, propertyName: string): void {

            let resolve = () => {
                return container.getTagged(serviceIdentifier, key, value);
            };

            _proxyGetter(proto, propertyName , resolve, doCache);

        };
    };
}

function makePropertyMultiInjectDecorator(container: interfaces.Container, doCache: boolean) {
    return function(serviceIdentifier: interfaces.ServiceIdentifier<any>) {
        return function(proto: any, key: string): void {

            let resolve = () => {
                return container.getAll(serviceIdentifier);
            };

            _proxyGetter(proto, key, resolve, doCache);

        };
    };
}

export {
    makePropertyInjectDecorator,
    makePropertyMultiInjectDecorator,
    makePropertyInjectTaggedDecorator,
    makePropertyInjectNamedDecorator
};

function getDecorators(container: interfaces.Container, doCache = true) {

    let lazyInject = makePropertyInjectDecorator(container, doCache);
    let lazyInjectNamed = makePropertyInjectNamedDecorator(container, doCache);
    let lazyInjectTagged = makePropertyInjectTaggedDecorator(container, doCache);
    let lazyMultiInject = makePropertyMultiInjectDecorator(container, doCache);

    return {
        lazyInject ,
        lazyInjectNamed,
        lazyInjectTagged,
        lazyMultiInject
    };

}

const regex = /__DI__(\S+)\$/;
export function fixInjectedProperties(target: any): void {
    if (typeof target !== 'object' || undefined === target.__proto__) {
        return;
    }
    const proto = target.__proto__;

    Object.getOwnPropertyNames(proto).forEach(name => {
        const result = regex.exec(name);
        if (null === result || result.length < 2) {
            return;
        }
        const descriptor = Object.getOwnPropertyDescriptor(proto, result[0]) as PropertyDescriptor;
        Object.defineProperty(target, result[1], descriptor);
        // delete proto[result[0]];
    });
}

export default getDecorators;
