import { Translation } from "educat-common-web";
import React from "react";
import { MentorCreationStage } from "..";

interface IExternalConfirmationInfoProps {
	readonly registrationStage: MentorCreationStage;
}

interface IConfirmationInfoProps extends IExternalConfirmationInfoProps {}

class ConfirmationInfo extends React.Component<IConfirmationInfoProps> {
	render() {
		const stageTranslationName = this.getTranslationName();
		return (
			<article className="col-xl-6">
				<header className="onboarding-header">
					<h1 className="onboarding-title">
						<Translation text={`mentorOnboarding.onboarding.confirmation_page.${stageTranslationName}.title`} />
					</h1>
				</header>
				<div className="onboarding-awaiting-info-container">
					<p className="onboarding-awaiting-info">
						<Translation
							text={`mentorOnboarding.onboarding.confirmation_page.${stageTranslationName}.confirmInformation`}
						/>
					</p>
					<a className="btn btn-theme" href={ process.env.REACT_APP_EDUCAT_LANDING_HOST }>
						<Translation text={'buttons.returnToHomepage'} />
					</a>
				</div>
			</article>
		);
	}

	private getTranslationName() {
		if (this.props.registrationStage === MentorCreationStage.REGISTRATION_BASIC_NOT_CONFIRMED) {
			return "registrationBasic";
		}
		return this.props.registrationStage === MentorCreationStage.REGISTRATION_EXTENDED_NOT_CONFIRMED
			? "registration"
			: "onboarding";
	}
}

export default ConfirmationInfo;
