import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function createTutoringScopeAPI(authToken: string, payload: any): Observable<any> {
    return educatAPI.post(
        `tutoring_scopes`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
