import {
    accountSelector,
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    IFormConfig,
    Translation
} from "educat-common-web";
import React from 'react';
import {connect} from "react-redux";
import {forkJoin, of, Subscription} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {addCalendarRulesAPI} from '../../../../api/addCalendarRules';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {RootState} from '../../../../store/reducers';
import {ICalendar, ICalendarRule, ITimeSlotItem, weekDays, WeekDays} from '../../../../store/reducers/calendarSlice';
import {calendarIdSelector, calendarSelector} from '../../../../store/selectors/calendarSelectors';
import {mentorKeepCollidingDatesFormConfig} from "./collidingDatesFormConfig";
import {deleteCalendarSlotLockersAPI} from "../../../../api/deleteCalendarSlotLockers";



interface IConnectedPopulateCalendarModalProps {
    readonly calendarId: string,
    readonly calendar: ICalendar;
    readonly calendarRules: {[key: string]: any}[] | null;
    readonly authToken: string;
    readonly account: {[key: string]: any};
}

export interface IPopulateCalendarModalProps extends IConnectedPopulateCalendarModalProps {
    readonly populateModalShown: boolean,
    readonly redirectToMonth: any;
    readonly toggleModal: () => void;
    readonly freeTerms: { [key: string]: any } | null;
}

interface IPopulateCalendarModalState {
    isLoading: boolean;
    collidingDates: FreeTermsSlot[];
    formConfig: typeof IFormConfig;
    formData: any;
}

export interface FreeTermsSlot {
    is_free : boolean;
    starts_at : string;
    ends_at : string;
    blocked_by : string|undefined;
    unlocked_by : string|undefined;
}

class PopulateCalendarModal extends React.Component<IPopulateCalendarModalProps, IPopulateCalendarModalState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            collidingDates: [],
            formConfig: null,
            formData: null
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getCollidingDates();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.populateModalShown} closeModal={this.props.toggleModal}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <h2 className="modal-title"><Translation text={'calendar.modals.confirmPopulateModal.newTitle'}/></h2>
                            <button className="btn-modal-close" onClick={() => this.props.toggleModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        {this.renderModalBody()}
                    </CustomCard.Body>
                    <CustomCard.Footer>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-danger-outline me-4"
                                    type="button"
                                    onClick={() => this.props.toggleModal()}>
                                <Translation text={'calendar.modals.buttons.no'}/>
                            </button>
                            <button className="btn btn-theme"
                                    type="button"
                                    onClick={() => this.populateCalendar()}>
                                <Translation text={'calendar.modals.buttons.yes'}/>
                            </button>
                        </div>
                    </CustomCard.Footer>
                </CustomCard>
            </BasicModal>
        );
    }

    private renderModalBody(){
        return (
            <div className="modal-body">
                <p><Translation text={'calendar.modals.confirmPopulateModal.content1'}/></p>
                <br/>
                <p><Translation text={'calendar.modals.confirmPopulateModal.content2'}/></p>
                <p><Translation text={'calendar.modals.confirmPopulateModal.content3'}/></p>
                <br/>
                {this.state.collidingDates?.length > 0 &&
                    <>
                        <p><Translation text={'calendar.modals.confirmPopulateModal.content4'}/></p>
                        <br/>
                        {this.renderCollidingDates()}
                    </>
                }
                <p><Translation text={'calendar.modals.confirmPopulateModal.question'}/></p>
            </div>
        );
    }

    private renderCollidingDates(){

        return <Form
            config={this.state.formConfig}
            controlName={'colliding_dates_checkboxes'}
            onValueStateChange={(controlName: string, value: any)=>{
                const formData = this.state.formData ?? {};
                Object.assign(formData, value.colliding_dates);
                this.setState({formData: formData});
            }}
            onValidationStateChange={()=>{}}
            value={this.state.formData}/>;

    }

    private getBlockedSlotsFromFreeTerms(): FreeTermsSlot[] {
        const blockedSlots: FreeTermsSlot[] = [];
        const freeTerms = this.props.freeTerms as any;
        for (const [, yearValue] of Object.entries(freeTerms)) {
            for (const [, monthValue] of Object.entries(yearValue as any)) {
                for (const [, dayValue] of Object.entries(monthValue as any)) {
                    for (const [, slotValue] of Object.entries(dayValue as any)) {
                        const slot: FreeTermsSlot = slotValue as FreeTermsSlot;
                        if (slot.blocked_by === 'slot_blocker'){
                            blockedSlots.push(slotValue as any);
        }}}}}
        return blockedSlots;
    }

    private getUnlockedSlotsFromFreeTerms(): FreeTermsSlot[] {
        const unlockedSlots: FreeTermsSlot[] = [];
        const freeTerms = this.props.freeTerms as any;
        for (const [, yearValue] of Object.entries(freeTerms)){
            for (const [, monthValue] of Object.entries(yearValue as any)){
                for (const [, dayValue] of Object.entries(monthValue as any)){
                    for (const [, slotValue] of Object.entries(dayValue as any)){
                        const slot: FreeTermsSlot = slotValue as FreeTermsSlot;
                        if (slot.unlocked_by === 'rule_slot_unlocker'){
                            unlockedSlots.push(slotValue as any);
        }}}}}
        return unlockedSlots;
    }

    private getCollidingDates() {
        const today = new Date();
        const unlockedSlots = this.getUnlockedSlotsFromFreeTerms().filter(slot => new Date(slot.starts_at).getFullYear() === today.getFullYear() && new Date(slot.starts_at).getMonth() === today.getMonth());
        const blockedSlots = this.getBlockedSlotsFromFreeTerms().filter(slot => new Date(slot.starts_at).getFullYear() === today.getFullYear() && new Date(slot.starts_at).getMonth() === today.getMonth());
        const calendar = this.props.calendar;
        const collidingTimeSlots: FreeTermsSlot[] = [];
        weekDays.forEach((weekday: WeekDays) => {
            calendar[weekday].forEach((timeSlot: ITimeSlotItem) => {
                const collidingTermSlots = this.getCollidingTermSlots(timeSlot, timeSlot.isFree ? blockedSlots : unlockedSlots);
                collidingTermSlots.forEach(slot => {
                    if (!collidingTimeSlots.find(cts => (new Date(cts.starts_at).getDate() === new Date(slot.starts_at).getDate()))) {
                        collidingTimeSlots.push(slot);
                    }
                })
            });
        });
        collidingTimeSlots.sort((a, b) => { return new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime() });
        this.setState({
            collidingDates: collidingTimeSlots,
            formConfig: mentorKeepCollidingDatesFormConfig(collidingTimeSlots)
        });
     }

    private getCollidingTermSlots(timeSlot: ITimeSlotItem, manuallySetSlots: FreeTermsSlot[]) : FreeTermsSlot[] {
        const collidingManuallySetSlots: FreeTermsSlot[] = [];
        for (const manuallySetSlot of manuallySetSlots) {
            if (!this.areSlotsOnTheSameWeekdayAndHour(timeSlot, manuallySetSlot)) {
                continue;
            }

            if ((timeSlot?.isFree && manuallySetSlot?.blocked_by === 'slot_blocker') || (!timeSlot?.isFree && manuallySetSlot?.unlocked_by === 'rule_slot_unlocker')) {
                collidingManuallySetSlots.push(manuallySetSlot);
            }
        }
        return collidingManuallySetSlots;
    }


    private areSlotsOnTheSameWeekdayAndHour(slot1: ITimeSlotItem, slot2: FreeTermsSlot): boolean {
        let slot1Date = new Date(slot1.dayDateTimeStart);
        let slot1Time = slot1.dayDateTimeStart.split('T')[1];
        let slot1Hour = slot1Time.split(':')[0];

        let slot2Date = new Date(slot2.starts_at);
        let slot2Time = slot2.starts_at.split('T')[1];
        let slot2Hour = slot2Time.split(':')[0];

        return (slot1Date.getDay() === slot2Date.getDay() && slot1Hour === slot2Hour);
    }

    private populateCalendar = (): void => {
        if (!this.props.calendarId) {
            return;
        }
        this.setState({isLoading: true});
        const abstractWeekBlockedTimeSlots: ITimeSlotItem[] = [],
            calendar = this.props.calendar;

        weekDays.forEach((weekday: WeekDays) => {
            calendar[weekday].forEach((timeSlot: ITimeSlotItem) => {
                    if (!timeSlot.isFree) {
                        abstractWeekBlockedTimeSlots.push(timeSlot);
                    }
                    return timeSlot;
                }
            )
        });

        const payload: ICalendarRule[] = abstractWeekBlockedTimeSlots.map((blockedTimeSlot: ITimeSlotItem): ICalendarRule => {
            const timeString = blockedTimeSlot.dayDateTimeEnd.split('T')[1].split('.')[0];
            return {
                startsAt: blockedTimeSlot.dayDateTimeStart,
                endsAt: timeString === "00:00:00" ? blockedTimeSlot.dayDateTimeEnd.replace("00:00:00", "23:59:59") : blockedTimeSlot.dayDateTimeEnd,
                interval: "P0Y0M7DT0H0M0S",
                intervalStart: blockedTimeSlot.dayDateTimeStart
            }
        });

        this.props.calendarRules?.forEach((rule: {[key: string]: any}) => {
            const date = rule.intervalStart.split('T').shift(),
                ruleStartsAt = date + 'T' + rule.startsAt.split('T').pop(),
                ruleEndsAt = date + 'T' + rule.endsAt.split('T').pop();

            payload.map((payloadRule: ICalendarRule) => {
                if (new Date(ruleStartsAt).getTime() === new Date(payloadRule.startsAt).getTime() &&
                    new Date(ruleEndsAt).getTime() === new Date(payloadRule.endsAt).getTime()) {
                    return payloadRule.id = rule.id;
                } else {
                    return payloadRule;
                }
            })
        });

        this.subscriptions.push(
            forkJoin([
                deleteCalendarSlotLockersAPI(this.props.authToken, this.props.calendarId, this.prepareFormDataForPayload()),
                addCalendarRulesAPI(this.props.authToken, this.props.calendarId, payload)
            ]).pipe(
                tap(() => {
                    this.setState({isLoading: false});
                    this.props.redirectToMonth();
                    this.props.toggleModal();
                    this.alertManagerService.addAlert('calendar.modals.confirmPopulateModal.success');
                }),
                catchError((error: any) => {
                    this.props.toggleModal();
                    this.setState({isLoading: false});
                    this.alertManagerService.handleApiError(error.response);
                    return of(error);
                })
            ).subscribe());
    };

    private prepareFormDataForPayload(){
        const dates = [];
        if (this.state.formData){
            for (const [date, shouldDateSlotsBePreserved] of Object.entries(this.state.formData)){
                if (!shouldDateSlotsBePreserved){
                    dates.push(date)
                }
            }
        }
        return dates;
    }
}

export default connect(
    (state: RootState) => ({
        calendar: calendarSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
        account: accountSelector(state)
    }),
    {}
)(PopulateCalendarModal);
