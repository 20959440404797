import {
    CustomCard,
    getApplicantSchoolNames,
    IModelApplicant,
    IModelApplicantSchoolStudyFields,
    IModelCountry,
    Translation
} from 'educat-common-web';
import React from 'react';
import styles from './styles.module.scss';
import ApplicantInformationRow from '../ApplicantInformationRow';


interface IConnectedApplicantInformationProps {
}

interface IExternalApplicantInformationProps {
    readonly isLoading: boolean;
    readonly applicant: typeof IModelApplicant | null;
}

interface IApplicantInformationProps extends IConnectedApplicantInformationProps,
    IExternalApplicantInformationProps {
}

interface IApplicantInformationState {
}

class ApplicantInformation extends React.Component<IApplicantInformationProps, IApplicantInformationState> {
    render() {
        const applicant = this.props.applicant;

        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Header>
                    <h2 className={styles.title}>
                        <Translation text={'applicants.applicantView.information.title'}/>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div>
                        <table className="data-table">
                            <tbody>
                            <ApplicantInformationRow keyName={'highSchool'}
                                                     value={getApplicantSchoolNames(applicant)}/>
                            <ApplicantInformationRow keyName={'country'}
                                                     value={applicant?.countries
                                                         ?.map((country: typeof IModelCountry) =>
                                                             <Translation text={`country.${country.id}`} key={country.id}/>)}/>
                            <ApplicantInformationRow keyName={'studyField'}
                                                     value={applicant?.applicantSchoolStudyFields
                                                         ?.map((item: typeof IModelApplicantSchoolStudyFields) =>
                                                             item.schoolStudyFields.studyField.name)}/>
                            <ApplicantInformationRow keyName={'school'}
                                                     value={applicant?.applicantSchoolStudyFields
                                                         ?.map((item: typeof IModelApplicantSchoolStudyFields) =>
                                                             item.schoolStudyFields.school.name)}/>
                            <ApplicantInformationRow keyName={'studyLevel'}
                                                     value={applicant?.studyLevel ?
                                                         <Translation
                                                             text={`applicants.applicantView.studyLevel.${applicant.studyLevel}`}/> :
                                                         null}/>
                            <ApplicantInformationRow keyName={'baccalaureateType'}
                                                     value={applicant?.baccalaureateType ?
                                                         <Translation
                                                             text={`applicants.applicantView.information.${applicant.baccalaureateType}`}/> :
                                                         null}/>
                            <ApplicantInformationRow keyName={'baccalaureateSubjects'}
                                                     value={applicant?.applicantBaccalaureateSubjects
                                                         ?.map((item: { [key: string]: any }) =>
                                                             <React.Fragment key={item.id}>
                                                                 {item.subject.name}
                                                                 {item.level ?
                                                                     <>
                                                                         ,&nbsp;
                                                                         <Translation
                                                                             text={`applicants.applicantView.information.baccalaureateLevel.${item.level}`}/>
                                                                     </> :
                                                                     null}
                                                             </React.Fragment>)}/>
                            <ApplicantInformationRow keyName={'studyStartYear'}
                                                     value={applicant?.studyStartYear}/>
                            </tbody>
                        </table>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default ApplicantInformation;
