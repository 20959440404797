import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getConsultationsAPI(
    authToken: string | null,
    params?: typeof RestQueryParams | null,
    pathParams?: string): Observable<any> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams(),
        path = pathParams ? `online_consultations?${pathParams}` : 'online_consultations';

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        path,
        queryParams,
        headers
    );
}
