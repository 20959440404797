import {Translation, refreshAccountStateData} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';

interface IConnectedFormStepThankYouProps {
    readonly refreshAccountStateData: typeof refreshAccountStateData;
}

interface IExternalFormStepThankYouProps {
}

interface IFormStepThankYouProps extends IConnectedFormStepThankYouProps, IExternalFormStepThankYouProps {
}

interface IFormStepThankYouState {
}

class FormStepThankYou extends React.Component<IFormStepThankYouProps, IFormStepThankYouState> {

    componentDidMount() {
        this.props.refreshAccountStateData();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <article>
                <div className="onboarding-awaiting-info-container p-0">
                    <p className="onboarding-awaiting-info">
                        <Translation
                            text={`mentorOnboarding.registration.thank_you.info`}
                        />
                    </p>
                    <a className="btn btn-theme" href={process.env.REACT_APP_EDUCAT_LANDING_HOST}>
                        <Translation text={'buttons.returnToHomepage'}/>
                    </a>
                </div>
            </article>
        );
    }
}

export default connect(
    () => ({}),
    {
        refreshAccountStateData,
    }
)(FormStepThankYou);
