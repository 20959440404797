import {deepCloneObject, Form, FormControlType, IFormConfig, Translation} from 'educat-common-web';
import React from 'react';
import {Subscription} from 'rxjs';
import {MentorOnboardingSteps} from '../Common/converters/onboardingHelperService';
import OnboardingFormHeader from '../Common/OnboardingFormHeader';
import {mentorServicePackagesFormConfig, taskGroup} from './mentorServicePackagesFormConfig';

interface IConnectedFormStepServicePackagesProps {
}

interface IExternalFormStepServicePackagesProps {
    readonly submitStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly prevStep: () => void;
    readonly stepData?: any;
    readonly serviceApplicationPackageList: any[];
}

interface IFormStepServicePackagesProps extends IConnectedFormStepServicePackagesProps, IExternalFormStepServicePackagesProps {
}

interface IFormStepServicePackagesState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorOnboardingSteps;
    formConfig: typeof IFormConfig;
}

class FormStepServicePackages extends React.Component<IFormStepServicePackagesProps, IFormStepServicePackagesState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IFormStepServicePackagesProps) {
        super(props);

        this.state = {
            isStepValid: true,
            isProcessing: false,
            formConfig: null,
            stepValue: null,
            stepName: MentorOnboardingSteps.SERVICE_PACKAGES,
        };
    }

    componentDidMount() {
        if (this.props.stepData) {
            const groups = this.createFormGroupsFromPackages();
            let updatedGroups = deepCloneObject(groups);
            if (this.props.stepData) {
                const servicePackagesStepData = this.props.stepData;
                updatedGroups.map((control: any) => {
                    if (control.hasOwnProperty('controls')) {
                        Object.keys(control.controls).map((key: string) => {
                            if (key.includes('net_price') || key.includes('services_')) {
                                return control.controls[key];
                            }
                            const stepDataItem = servicePackagesStepData[key];
                            if (stepDataItem !== null && stepDataItem !== undefined) {
                                control.controls[key].defaultValue = stepDataItem;
                                control.controls[key].value = stepDataItem;
                                control.controls[key].checked = stepDataItem;
                            } else {
                                control.controls[key].defaultValue = false;
                                control.controls[key].value = false;
                                control.controls[key].checked = false;
                            }
                            control.controls[key].disabled = true;
                            return control.controls[key];
                        });
                    }
                    return control;
                });
            }
            const updatedFormConfig = mentorServicePackagesFormConfig(updatedGroups);
            this.setState({formConfig: updatedFormConfig, stepValue: this.props.stepData});
        } else {
            this.setState({formConfig: mentorServicePackagesFormConfig(this.createFormGroupsFromPackages())});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className="onboarding-form-wrapper">
                    <OnboardingFormHeader stepName={this.state.stepName}/>
                    {this.state.formConfig &&
                        <Form config={this.state.formConfig}
                              controlName={MentorOnboardingSteps.SERVICE_PACKAGES}
                              onValueStateChange={this.formChangeHandler}
                              onValidationStateChange={this.formValidityChange}
                              value={this.state.stepValue}
                        />}

                </div>
                <footer className="onboarding-navigation">
                    <button type="button" onClick={() => this.props.prevStep()} className="btn btn-theme btn-back">
                        <Translation text="buttons.back"/>
                    </button>
                    <button
                        type="submit"
                        disabled={!this.state.isStepValid}
                        onClick={() => this.props.submitStep(this.state.stepName, this.state.stepValue)}
                        className="btn btn-theme btn-rounded"
                    >
                        <Translation text="buttons.next"/>
                    </button>
                </footer>
            </>
        );
    }

    private formChangeHandler = (controlName: MentorOnboardingSteps, value: any) => {
        if (!this.state.stepValue) {
            return this.setState({stepValue: value});
        }
        let updatedStepValue = deepCloneObject(this.state.stepValue);
        for (const [key, inputValue] of Object.entries(value)) {
            updatedStepValue[key] = inputValue;
        }
        this.setState({stepValue: updatedStepValue});
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };

    private createFormGroupsFromPackages() {
        if (this.props.serviceApplicationPackageList.length === 0) {
            return [];
        }

        return this.props.serviceApplicationPackageList.map(
            (serviceApplicationPackage: { name: string; id: string; services: string; price: number; currency: string }) => {
                const controls = this.createFormControlsFromPackageList(serviceApplicationPackage);

                return taskGroup(`group_${serviceApplicationPackage.id}`, serviceApplicationPackage.name, controls, false);
            }
        );
    }

    private createFormControlsFromPackageList(serviceApplicationPackage: {
        name: string;
        id: string;
        services: string;
        price: number;
        currency: string;
    }) {
        let controls = {};
        controls = Object.assign(controls, {
            [serviceApplicationPackage.id]: {
                controlType: 'control',
                defaultValue: true,
                value: true,
                formControlType: FormControlType.CHECKBOX,
                validationRules: [],
                placeholder: '',
                label: serviceApplicationPackage.name,
                isLabelHidden: false,
                isCheckboxLabelRaw: false,
                checkboxLabel: ``,
                hostClass: 'col-xl-5 checkbox-container',
            },
            [`services_${serviceApplicationPackage.id}`]: {
                hostClass: `col-xl-4 onboarding-table-cell hide-label input-readonly textarea-mask`,
                controlType: 'control',
                defaultValue: serviceApplicationPackage?.services ? serviceApplicationPackage.services : '--',
                disabled: true,
                formControlType: FormControlType.TEXTAREA,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
            },
            [`net_price_${serviceApplicationPackage.id}`]: {
                hostClass: `col-xl-3 onboarding-table-cell hide-label input-readonly bg-transparent`,
                controlType: 'control',
                defaultValue: serviceApplicationPackage.price
                    ? `${serviceApplicationPackage.price / 100} ${serviceApplicationPackage.currency}`
                    : '--',
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
            },
        });

        return controls;
    }
}

export default FormStepServicePackages;
