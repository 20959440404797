import React from 'react';
import {Subscription, BehaviorSubject} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {
    IFormConfig,
    Form,
    FormControlChangeType,
    authTokenSelector,
    RestQueryParams,
    isNotNullOrUndefined
} from "educat-common-web";
import {debounceTime, filter, tap} from "rxjs/operators";
import {WithTranslation, withTranslation} from "react-i18next";
import {filterFormConfig} from './filterFormConfig';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";


interface IConnectedConsultationsFiltersProps {
    authToken: string;
}

interface IExternalConsultationsFiltersProps {
    changeConsultationsList: (params?: typeof RestQueryParams | null, path?: string ) => void;
}

interface IConsultationsFiltersProps extends IConnectedConsultationsFiltersProps,
    IExternalConsultationsFiltersProps,
    WithTranslation {}

interface IConsultationsFiltersState {
    isLoading: boolean;
    formConfig: typeof IFormConfig;
    value: any;
}

class ConsultationsFilters extends React.Component<IConsultationsFiltersProps, IConsultationsFiltersState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IConsultationsFiltersProps) {
        super(props);

        this.state = {
            isLoading: false,
            formConfig: filterFormConfig,
            value: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                debounceTime(500),
                tap((data: any)  => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <Form config={this.state.formConfig}
                  onValueStateChange={this.onValueStateChange}
                  value={this.state.value}
                  controlName={'filterForm'}/>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        let pathKeys: string[] = [];
        Object.keys(value).forEach((key: string) => {
            if ((key === 'startDate')&& isNotNullOrUndefined(value[key])) {
                let startDate = this.getTimeString(value[key]);
                pathKeys.push(`startsAt[after]=${startDate}`);
            }

            if (key === 'endDate' && isNotNullOrUndefined(value[key])) {
                let endDate = this.getTimeString(value[key]);
                pathKeys.push(`startsAt[before]=${endDate}`);
            }

            if (key === 'applicant' && isNotNullOrUndefined(value[key])) {
                pathKeys.push(`participants.account.lastName=${value[key]}`);
            }
        });

        this.setState({value: value});
        if (pathKeys.length) this.props.changeConsultationsList(null, pathKeys.join('&'));
    };

    private getTimeString(value: Date): string {
        return new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    }

}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
    }
)(withTranslation()(ConsultationsFilters));
