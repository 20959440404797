import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function sendMentorRegistrationSurveyAPI(authToken: string, mentorId: string, payload: any): Observable<any> {
    return educatAPI.put(
        `mentors/${mentorId}/send_mentor_registration_survey`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
