import {
    accountSelector,
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IApplicantBaccalaureateSubject,
    IFormConfig,
    isNotEmpty,
    isNotNullOrUndefined,
    isSameValue,
    removeExtraValueKeys,
    Translation
} from 'educat-common-web';
import moment from 'moment';
import React from 'react';
import {connect} from 'react-redux';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {addScheduleAPI, IScheduleItem, ITaskDefinitionItem} from '../../../api/addSchedule';
import {editScheduleAPI, IEditSchedulePayload} from '../../../api/editSchedule';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {RootState} from '../../../store/reducers';
import {addScheduleEventFormConfig} from './addScheduleFormConfig';
import SchedulePreviewModal from './SchedulePreview';
import styles from './styles.module.scss';
import ScheduleStudyFieldForm, {ScheduleStudyFieldFormType} from './ScheduleStudyFieldForm';


interface IAddScheduleModalConnectedProps {
    authToken: string | null;
    account: { [key: string]: any };
}

interface IAddScheduleModalExternalProps {
    isModalVisible: boolean;
    toggleModal: () => void;
    schedule?: { [key: string]: any } | null;
    refreshList?: () => void;
    updateStateSchedule?: (item: { [key: string]: any }) => void;
}

interface IAddScheduleModalProps extends IAddScheduleModalConnectedProps,
    IAddScheduleModalExternalProps {
}

interface IAddScheduleModalState {
    formConfig: typeof IFormConfig;
    value: any;
    isPreviewModalVisible: boolean;
    eventsNumber: number;
    isLoading: boolean;
    scheduleSchoolDetails: { [key: string]: any } | null;
}

class AddScheduleModal extends React.Component<IAddScheduleModalProps, IAddScheduleModalState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IAddScheduleModalProps) {
        super(props);

        this.state = {
            formConfig: null,
            value: null,
            isPreviewModalVisible: false,
            eventsNumber: 0,
            isLoading: false,
            scheduleSchoolDetails: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (this.props.schedule) {
            this.createFormValuesFromState(this.props.schedule);
        }

        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IAddScheduleModalProps>,
        prevState: Readonly<IAddScheduleModalState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.schedule, prevProps.schedule) && this.props.schedule) {
            this.createFormValuesFromState(this.props.schedule)
        }

        this.updateFormConfigIfNecessary(prevProps, prevState);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.setState({value: null, formConfig: null});
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.isModalVisible} closeModal={this.props.toggleModal}>
                    <div className={styles.modalWrapper}>
                        <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                            <CustomCard.Header>
                                <div className={styles.modalHeader}>
                                    <h2 className={styles.modalTitle}>
                                        {this.props.schedule ? (<Translation text="schedules.editSchedule"/>) :
                                            (<Translation text="schedules.addScheduleModal.title"/>)
                                        }
                                    </h2>

                                    <button className={styles.btnClose} onClick={() => this.props.toggleModal()}>
                                        <span className="feather icon-x"/>
                                    </button>
                                </div>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <ScheduleStudyFieldForm updateScheduleDetails={this.updateScheduleSchoolDetails}
                                                        type={ScheduleStudyFieldFormType.ADD_SCHEDULE}
                                                        schedule={this.props.schedule}/>

                                <div className={styles.scheduleEventsContainer}>
                                    <div className={`row ${styles.scheduleEventsHeader}`}>
                                        <div className={`col-5 ${styles.tHeadCell}`}>
                                            <div className={styles.tHead}>
                                                <Translation text={'schedules.details.table.events'}/>
                                            </div>
                                        </div>
                                        <div className={`col-3 ${styles.tHeadCell}`}>
                                            <div className={styles.tHead}>
                                                <Translation text={'schedules.details.table.start'}/>
                                            </div>
                                        </div>
                                        <div className={`col-3 ${styles.tHeadCell}`}>
                                            <div className={styles.tHead}>
                                                <Translation text={'schedules.details.table.end'}/>
                                            </div>
                                        </div>
                                        <div className={`col-1 ${styles.tHeadCell}`}>
                                            <div className={styles.tHead}/>
                                        </div>

                                    </div>

                                    <div>
                                        {this.state.formConfig && <Form config={this.state.formConfig}
                                                                        value={this.state.value}
                                                                        controlName={'scheduleEventFormConfig'}
                                                                        onValueStateChange={this.onValueStateChange}/>
                                        }
                                    </div>

                                </div>
                                <div className={styles.btnContainer}>
                                    <button className="btn btn-theme"
                                            disabled={!this.state.value}
                                            onClick={() => this.togglePreviewModal()}>
                                        <Translation text={'schedules.addScheduleModal.preview'}/>
                                    </button>

                                    <button className="btn btn-theme"
                                            disabled={!this.state.value || !this.state.scheduleSchoolDetails}
                                            onClick={() => {
                                                this.props.schedule ? this.editSchedule() : this.addSchedule()
                                            }}>
                                        <Translation text={'schedules.details.assignToApplicant.confirm'}/>
                                    </button>
                                </div>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </BasicModal>

                {this.state.isPreviewModalVisible ?
                    (<SchedulePreviewModal isModalVisible={this.state.isPreviewModalVisible}
                                           toggleModal={this.togglePreviewModal}
                                           scheduleDetails={{
                                               scheduleList: this.getScheduleListFromState(),
                                               schoolName: this.state.scheduleSchoolDetails?.school,
                                           }}
                                           acceptSchedule={this.props.schedule ? this.editSchedule : this.addSchedule}/>
                    ) : null
                }
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.setState({value: value});
    };


    private togglePreviewModal = () => {
        this.setState({isPreviewModalVisible: !this.state.isPreviewModalVisible});
    };

    private addSchedule = () => {
        if (!this.state.scheduleSchoolDetails) {
            return;
        }

        this.setState({isLoading: true});
        let payload = {
            schoolStudyFieldsId: this.state.scheduleSchoolDetails.studyField,
            scheduleList: {
                schedules: []
            },
            taskDefinitions: this.getEventsDetails(this.state.value),
            mentorId: this.props.account.mentorId ? this.props.account.mentorId : null
        };

        this.subscriptions.push(
            addScheduleAPI(this.props.authToken, payload).pipe(
                map(() => {
                    this.setState({isLoading: false});
                    this.props.toggleModal();
                    if (this.state.isPreviewModalVisible) this.togglePreviewModal();
                    this.alertManager.addAlert('schedules.addScheduleModal.scheduleAdded');
                    if (this.props.refreshList) {
                        this.props.refreshList();
                    }
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.alertManager.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };

    private getScheduleListFromState = (): { [key: string]: any }[] | [] => {
        return Object.keys(this.state.value)
            .filter(key => key.startsWith('event'))
            .map(key => {
                const index = key.substr('event'.length);

                return {
                    from: moment(this.state.value[`startDate${index}`]).startOf('day').format(),
                    icon: '',
                    id: index,
                    name: this.state.value[`event${index}`],
                    to: moment(this.state.value[`endDate${index}`]).endOf('day').format()
                };
            })
    };

    private editSchedule = () => {
        if (!this.props.schedule) {
            return;
        }
        this.setState({isLoading: true});
        let schedules: IScheduleItem[] = [];

        if (this.props.schedule.scheduleList && this.props.schedule.scheduleList.schedules) {
            this.props.schedule.scheduleList.schedules.map((item: { [key: string]: any }) => {
                return schedules.push({
                    name: item.name,
                    icon: item.icon,
                    from: item.from,
                    to: item.to,
                    itemType: item.itemType,
                })
            })
        }

        let payload: IEditSchedulePayload = {
            scheduleList: {
                schedules: schedules
            },
            taskDefinitions: this.getEventsDetails(this.state.value)
        };

        this.subscriptions.push(
            editScheduleAPI(this.props.authToken, this.props.schedule.id, payload).pipe(
                map((resp: { [key: string]: any }) => {
                    this.setState({isLoading: false});
                    this.alertManager.addAlert('schedules.addScheduleModal.scheduleDetailsUpdated');
                    if (this.props.updateStateSchedule) {
                        this.props.updateStateSchedule(resp);
                    }
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.alertManager.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };

    private setFormConfig = (): void => {
        const scheduleFormConfig = addScheduleEventFormConfig(
            {
                eventsNumber: this.state.eventsNumber,
                value: this.state.value,
                onAdd: () => {
                    this.setState((state) => {
                        return {eventsNumber: state.eventsNumber + 1};
                    });
                },
                onRemove: (index: number) => {
                    this.setState((state) => {
                        if (state.eventsNumber < 2) {
                            return null;
                        }
                        const value = Object.assign({}, state.value);
                        removeExtraValueKeys(value, 'event', index);
                        removeExtraValueKeys(value, 'startDate', index);
                        removeExtraValueKeys(value, 'endDate', index);
                        removeExtraValueKeys(value, 'remove', index);

                        return {
                            eventsNumber: state.eventsNumber - 1,
                            value,
                        };
                    });
                },
            },
        );

        this.setState({formConfig: scheduleFormConfig});
    };

    private createFormValuesFromState = (schedule: any) => {
        const hasTaskDefinitions = Array.isArray(schedule.taskDefinitions);
        const value: { [key: string]: any } = {};
        let eventsNumber = 0;
        if (hasTaskDefinitions) {
            schedule.taskDefinitions.forEach((taskDefinition: typeof IApplicantBaccalaureateSubject, index: number) => {
                value[`id${index}`] = taskDefinition.id;
                value[`event${index}`] = taskDefinition.subject;
                value[`startDate${index}`] = new Date(taskDefinition.startsAt);
                value[`endDate${index}`] = new Date(taskDefinition.endsAt);
            });
            eventsNumber = schedule.taskDefinitions.length;
        }

        this.setState({
            value,
            eventsNumber
        });
    };

    private getEventsDetails = (value: any): ITaskDefinitionItem[] => {
        const prefix = 'event';

        return Object.keys(value)
            .filter(key => key.startsWith(prefix))
            .map(key => {
                const index = key.substr(prefix.length);

                return {
                    id: isNotEmpty(value[`id${index}`]) ? value[`id${index}`] : null,
                    subject: value[`event${index}`],
                    content: '',
                    needsReview: false,
                    startsAt: moment(value[`startDate${index}`]).startOf('day').format(),
                    endsAt: moment(value[`endDate${index}`]).startOf('day').format(),
                };
            })
            .filter(entry => isNotNullOrUndefined(entry.subject));
    };

    private updateScheduleSchoolDetails = (value: { [key: string]: any }) => {
        this.setState({scheduleSchoolDetails: value});
    };

    private updateFormConfigIfNecessary = (prevProps: Readonly<IAddScheduleModalProps>, prevState: Readonly<IAddScheduleModalState>): void => {
        let newFormConfigNecessary = false;

        if (!newFormConfigNecessary && this.state.value !== prevState.value) {
            newFormConfigNecessary = true;
        }

        if (this.state.eventsNumber !== prevState.eventsNumber) {
            newFormConfigNecessary = true;
        }

        if (newFormConfigNecessary) {
            this.setFormConfig();
        }
    };

}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state)
    }),
    {}
)(AddScheduleModal);
