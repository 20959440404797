import React from 'react';
import {connect} from 'react-redux';
import styles from './styles.module.scss';
import CalendarTimeSlot from './CalendarSlot';
import {deepCloneObject, isSameValue, Switch} from "educat-common-web";
import {RootState} from '../../../../../../store/reducers';
import {
    calendarWeekDay,
    changeCalendarDay,
    ICalendar,
    ITimeSlotItem
} from '../../../../../../store/reducers/calendarSlice';
import {calendarSelector} from '../../../../../../store/selectors/calendarSelectors';

interface ICalendarDayState {
    weekDay: calendarWeekDay | null;
}

interface ICalendarDayProps extends ICalendarDayConnectedProps {
    weekDay: calendarWeekDay | null;
    calendarRulesForDay: any[];
}

interface ICalendarDayConnectedProps {
    readonly changeCalendarDay: typeof changeCalendarDay;
    readonly calendar: ICalendar;
}

class CalendarDay extends React.Component<ICalendarDayProps, ICalendarDayState> {
    constructor(props: any) {
        super(props);
        this.state = {
            weekDay: null
        };
    }

    componentDidMount() {
        if (this.props.weekDay !== null && this.props.weekDay.dayTimeSlots !== null && this.props.weekDay.dayName !== null) {
            if (this.props.calendarRulesForDay.length > 0) {
                return this.setSlotAvailabilityFromRules(this.props.weekDay, this.props.calendarRulesForDay);
            }
            this.props.changeCalendarDay(this.props.weekDay);
            this.setState({weekDay: this.props.weekDay});
        }
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.weekDay, prevProps.weekDay) && this.props.weekDay && this.props.weekDay.dayTimeSlots) {
            this.props.changeCalendarDay(this.props.weekDay);
            this.setState({weekDay: this.props.weekDay});
        }
        if (this.props.weekDay && this.props.calendarRulesForDay.length > 0 && !isSameValue(this.props.calendarRulesForDay, prevProps.calendarRulesForDay)) {
            this.setSlotAvailabilityFromRules(this.props.weekDay, this.props.calendarRulesForDay);
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderTableColumn()}
            </React.Fragment>
        );
    }

    private renderTableColumn() {
        if (null === this.state.weekDay || null === this.state.weekDay.dayName) {
            return;
        }

        return (
            <ul key={this.state.weekDay.dayIndex}
                id={this.state.weekDay.dayName}
                className={styles.abstractDay}>
                {this.renderDayHeader()}
                {this.renderDaySlots()}
            </ul>
        );
    }

    private renderDayHeader() {
        if (!this.state.weekDay) {
            return;
        }

        let isSlotAvailable = this.state.weekDay.dayTimeSlots.some((item: any) => item.isFree);
        let isLastDay = this.state.weekDay.dayIndex === 6;

        return (
            <li key={`${this.state.weekDay.dayName} + ' header'`}
                className={styles.abstractDayHeader}>

                <div className={`${styles.headerWrapper} ${isLastDay ? styles.lastDay : ''}`}>
                    <span className={styles.headerTitle}>
                        {this.state.weekDay.dayName}
                    </span>

                    <Switch checked={isSlotAvailable}
                            handleChange={() => this.blockDayTimeSlots()}
                            inputStyles='sm'/>
                </div>

            </li>
        );
    }

    private renderDaySlots() {
        if (!this.state.weekDay || this.state.weekDay.dayTimeSlots.length <= 0) {
            return;
        }

        return this.state.weekDay.dayTimeSlots.map((timeSlot: ITimeSlotItem) => {
            return <CalendarTimeSlot timeSlot={timeSlot} key={`${timeSlot.dayName}:${timeSlot.label}`}/>;
        });
    }

    private setSlotAvailabilityFromRules(weekDay: calendarWeekDay, calendarRulesForDay: any[]) {
        let calendarDayWithRules: calendarWeekDay = deepCloneObject(weekDay);
        calendarDayWithRules.dayTimeSlots.forEach((timeSlot: any) => {
            const rule = calendarRulesForDay.find((calendarRule) => {
                return Date.parse(calendarRule.intervalStart) === Date.parse(timeSlot.dayDateTimeStart)
            });

            timeSlot.isFree = !rule;
        });
        this.props.changeCalendarDay(calendarDayWithRules);

        this.setState({weekDay: calendarDayWithRules});
    }

    private blockDayTimeSlots() {
        if (this.state.weekDay !== null && this.state.weekDay.dayName !== null) {
            let blockedDay = deepCloneObject(this.state.weekDay);
            blockedDay.dayTimeSlots.forEach((timeSlot: ITimeSlotItem) => timeSlot.isFree = !timeSlot.isFree);
            this.props.changeCalendarDay(blockedDay);
            this.setState({weekDay: blockedDay});
        }
    }
}

export default connect(
    (state: RootState) => ({
        calendar: calendarSelector(state),
    }),
    {
        changeCalendarDay
    }
)(CalendarDay);
