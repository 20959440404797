import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    isNullOrUndefined,
    MultiselectMenuPlacement,
    MultiSelectType
} from 'educat-common-web';


// list of value keys that can possibly influence form config
export const VOLATILE_SCHEDULE_FORM_KEYS: ReadonlyArray<string> = ['country', 'school'];

export const addScheduleFormConfig = (
    value: any,
    countriesList: typeof IMultiselectOption[] = [],
    schoolList: typeof IMultiselectOption[] = [],
    subjectsList: typeof IMultiselectOption[] = [],
    handleMultiselectInputChange?: any
): typeof IFormConfig => {

    return {
        controlType: 'group',
        class: 'base-form add-schedule-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'country',
                class: 'row',
                controls: {
                    country: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        validationRules: [],
                        placeholder: 'schedules.addSchedule.form.placeholders.allOptions',
                        label: 'schedules.addSchedule.form.labels.country',
                        multiselectOptions: countriesList,
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        menuPlacement: MultiselectMenuPlacement.BOTTOM,
                        isCustomMultiValueContainer: true,
                        hostClass: 'add-schedule-control'
                    }
                }
            },
            {
                controlType: 'group',
                key: 'school',
                class: 'row',
                controls: {
                    school: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        validationRules: [],
                        placeholder: 'schedules.addSchedule.form.placeholders.typeOption',
                        label: 'schedules.addSchedule.form.labels.school',
                        multiselectOptions: schoolList,
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        menuPlacement: MultiselectMenuPlacement.BOTTOM,
                        disabled: isNullOrUndefined(value?.country),
                        hostClass: 'add-schedule-control',
                        handleMultiselectInputChange: (value: any) => {
                            if (handleMultiselectInputChange) {
                                return handleMultiselectInputChange(value, 'school');
                            }
                        }
                    }
                }
            },
            {
                controlType: 'group',
                key: 'studyField',
                class: 'row',
                controls: {
                    studyField: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        validationRules: [],
                        placeholder: 'schedules.addSchedule.form.placeholders.allOptions',
                        label: 'schedules.addSchedule.form.labels.studyField',
                        multiselectOptions: subjectsList,
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        menuPlacement: MultiselectMenuPlacement.BOTTOM,
                        disabled: isNullOrUndefined(value?.school),
                        hostClass: 'add-schedule-control'
                    }
                }
            }
        ]
    }
};
