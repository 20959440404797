import {IModelAccount, isNotNullOrUndefined} from 'educat-common-web';
import React from 'react';
import noUser from '../../../assets/images/no_user.jpg';


interface IConnectedAccountImageProps {
}

interface IExternalAccountImageProps {
    readonly account: typeof IModelAccount;
    readonly type: 'largeThumb' | 'mediumThumb' | 'smallThumb' | 'tinyThumb';
    readonly adaptive?: boolean;
}

interface IAccountImageProps
    extends IConnectedAccountImageProps,
        IExternalAccountImageProps {
}

interface IAccountImageState {
}

class AccountImage extends React.Component<IAccountImageProps, IAccountImageState> {
    render() {
        const image = isNotNullOrUndefined(this.props.account?.avatar)
            ? this.props.account.avatar[this.props.type]
            : noUser;

        return <div className={`mentor-img ${true === this.props.adaptive ? 'adaptive' : ''}`}
                    style={{backgroundImage: `url(${image}`}}
                    aria-hidden="true"/>;
    }
}

export default AccountImage;
