import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';
import {IScheduleItem, ITaskDefinitionItem} from './addSchedule';


export interface IEditSchedulePayload {
    scheduleList: {
        schedules: IScheduleItem[] | [];
    },
    taskDefinitions: ITaskDefinitionItem[] | [];
}

export function editScheduleAPI(
    authToken: string | null,
    scheduleId: string,
    payload: IEditSchedulePayload
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.put(
        `schedules/${scheduleId}`,
        payload,
        new RestQueryParams(),
        headers
    );
}
