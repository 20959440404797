import React from 'react';
import {
    ConsultationPackageItemPrice,
    IModelMentorServiceDefinitionRates,
    IModelServiceDefinitionRateDetails,
    isNotNullOrUndefined,
    PriceChangeRequestStatus,
    Switch,
    Tooltip,
    TooltipDirection,
    Translation
} from 'educat-common-web';
import styles from './styles.module.scss';
// import ChangeServicePriceModal from "../ChangeServicePriceModal";


interface IConnectedServiceItemProps {
}

interface IExternalServiceItemProps {
    readonly service: typeof IModelMentorServiceDefinitionRates;
    readonly serviceDetails: typeof IModelServiceDefinitionRateDetails;
    readonly refreshList?: () => void;
    readonly hideSwitch?: boolean;
}

interface IServiceItemProps extends IExternalServiceItemProps,
    IConnectedServiceItemProps {
}

interface IServiceItemState {
    isModalVisible: boolean;
    selectedItem: any;
}


class ServiceItem extends React.Component<IServiceItemProps, IServiceItemState> {
    constructor(props: IServiceItemProps) {
        super(props);

        this.state = {
            isModalVisible: false,
            selectedItem: null
        };
    }

    render() {
        const service = this.props.service,
            serviceDetails = this.props.serviceDetails,
            isServiceActive = isNotNullOrUndefined(service.mentorServiceDefinitions) && service.mentorServiceDefinitions.active,
            isFreePackage = service?.serviceApplicationPackage?.paidRange === false,
            isServiceProcessing = isNotNullOrUndefined(service.mostRecentPriceChangeRequest) &&
                service.mostRecentPriceChangeRequest.status !== PriceChangeRequestStatus.Accepted;

        if (isFreePackage) {
            return null;
        }

        return (
            <>
                <div className={styles.consultationPackageSummary}>
                    {!this.props.hideSwitch && <Switch name={''}
                             handleChange={null}
                             inputStyles={`packages-status ${isServiceActive ? 'active' : ''} ${isServiceProcessing ? 'processing' : ''}`}
                             checked={isServiceActive}
                             disabled={true}/>}
                    <h3 className={styles.title}>{serviceDetails.name}</h3>
                    <div className={styles.infoPart}>
                        {isServiceActive ?
                            (<ConsultationPackageItemPrice consultation={service.mentorServiceDefinitions}/>) :
                            this.renderServiceStatus(isServiceProcessing)
                        }
                    </div>
                    <Tooltip content={''}
                             direction={TooltipDirection.TOP}
                             customComponent={() => <div className={styles.tooltipInfo}>
                                 {isServiceProcessing ? <p><Translation text={"profile.services.tooltip.processingText"}/></p> :
                                     (isServiceActive ? <p><Translation text={"profile.services.tooltip.activeText"}/></p> :
                                         <p><Translation text={"profile.services.tooltip.notActiveText"}/></p>
                                     )
                                 }

                                 {/*Hidden in current version*/}
                                 {/*{!isServiceProcessing ?*/}
                                 {/*    (<button className="btn btn-theme-no-outline btn-tooltip"*/}
                                 {/*             onClick={() => this.toggleModal(service)}>*/}
                                 {/*       <Translation text={"profile.services.tooltip.requestChange"}/>*/}
                                 {/*    </button>) : null*/}
                                 {/*}*/}
                             </div>}>
                        <div className={styles.tooltipIcon}>
                            <span>?</span>
                        </div>
                    </Tooltip>
                </div>

                {/*Hidden in current version*/}
                {/*{this.state.isModalVisible ? (*/}
                {/*        <ChangeServicePriceModal isModalVisible={this.state.isModalVisible}*/}
                {/*                                 toggleModal={this.toggleModal}*/}
                {/*                                 serviceInstance={this.props.service}*/}
                {/*                                 serviceInstanceDetails={this.props.serviceDetails}*/}
                {/*                                 refreshList={this.props.refreshList}/>) :*/}
                {/*    null*/}
                {/*}*/}
            </>
        );
    }

    // Hidden in current version
    // private toggleModal = (item: any) => {
    //     this.setState({selectedItem: item, isModalVisible: !this.state.isModalVisible})
    // };

    private renderServiceStatus = (isProcessing: boolean) => {
        return (
            <div className={`${styles.serviceStatus} ${isProcessing ? styles.processing : ''}`}>
                {isProcessing ? <Translation text={'profile.services.servicesRates.statuses.processing'}/> :
                    <Translation text={'profile.services.servicesRates.statuses.notActive'}/>
                }
            </div>
        )
    };
}

export default ServiceItem;
