import React from "react";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    DateComponent,
    isSameValue,
    Translation
} from "educat-common-web";
import styles from "./styles.module.scss";
import {WithTranslation, withTranslation} from "react-i18next";
import AssignApplicantModal from "../AssignApplicantModal";
import AddScheduleModal from "../../AddScheduleModal";
import {editScheduleAPI, IEditSchedulePayload} from "../../../../api/editSchedule";
import {catchError, map} from "rxjs/operators";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {IScheduleItem} from "../../../../api/addSchedule";


interface ISchedulesDetailsConnectedProps {
    readonly authToken: string | null;
}

interface ISchedulesDetailsExternalProps {
    schedule: {[key: string]: any} | null;
    isModalVisible: boolean;
    closeModal: () => void;
}

interface ISchedulesDetailsProps extends ISchedulesDetailsConnectedProps,
    ISchedulesDetailsExternalProps,
    WithTranslation {
}

interface ISchedulesDetailsState {
    isAssignModalVisible: boolean;
    isEditModalVisible: boolean;
    selectedSchedule: any;
    isLoading: boolean;
}

class SchedulesDetails extends React.Component<ISchedulesDetailsProps, ISchedulesDetailsState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ISchedulesDetailsProps) {
        super(props);

        this.state = {
            isAssignModalVisible: false,
            isEditModalVisible: false,
            selectedSchedule: null,
            isLoading: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.setState({selectedSchedule: this.props.schedule});
    }

    componentDidUpdate(
        prevProps: Readonly<ISchedulesDetailsProps>,
        prevState: Readonly<ISchedulesDetailsState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.schedule, prevProps.schedule)) {
            this.setState({selectedSchedule: this.props.schedule});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const school = this.state.selectedSchedule?.schoolStudyFields?.school,
            studyField = this.state.selectedSchedule?.schoolStudyFields?.studyField;

        return (
            <React.Fragment>
                <BasicModal
                    isModalShown={this.props.isModalVisible}
                    closeModal={this.props.closeModal}>
                    <CustomCard showLocalLoader={this.state.isLoading}  type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Header>
                            <div className={styles.modalHeader}>
                                <div className={styles.modalTitle}>
                                    <h2>
                                        <Translation text="schedules.details.title" />
                                        <span className={styles.scheduleSchool}>{school ? school.name : null}</span>
                                    </h2>
                                    <p>{studyField ? studyField.name : null}</p>
                                </div>
                                <button className={styles.btnClose} onClick={() => this.props.closeModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <table className="data-table default-table">
                                <thead>
                                <tr>
                                    <th className="highlight-cell schedule-cell">
                                        <Translation text={'schedules.details.table.events'}/>
                                    </th>
                                    <th className="highlight-cell schedule-cell">
                                        <Translation text={'schedules.details.table.start'}/>
                                    </th>
                                    <th className="highlight-cell schedule-cell">
                                        <Translation text={'schedules.details.table.end'}/>
                                    </th>
                                    <th className="highlight-cell" />
                                </tr>
                                </thead>
                                <tbody>
                                    {this.renderTableRows()}
                                </tbody>
                            </table>

                            <div className={styles.btnContainer}>
                                <button className="btn btn-theme"
                                        onClick={() => this.assignSchedule()}>
                                    <Translation text={"schedules.details.assignToApplicant.assignBtn"} />
                                </button>

                                <button className="btn btn-theme"
                                    onClick={() => this.toggleEditModal()}>
                                    <Translation text={"schedules.details.edit"} />
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>

                {this.state.isAssignModalVisible ?
                    (<AssignApplicantModal schedule={this.state.selectedSchedule}
                                      isModalVisible={this.state.isAssignModalVisible}
                                      toggleModal={() => this.setState({isAssignModalVisible: false})}/>) : null}

                {this.state.isEditModalVisible ?
                    (<AddScheduleModal isModalVisible={this.state.isEditModalVisible}
                                       toggleModal={this.toggleEditModal}
                                       schedule={this.state.selectedSchedule}
                                       updateStateSchedule={(item: {[key: string]: any}) => this.setState({selectedSchedule: item})}/>) : null}
            </React.Fragment>
        )
    }
    private renderTableRows() {
        if (!this.state.selectedSchedule || !this.state.selectedSchedule.taskDefinitions ||
            !this.state.selectedSchedule.taskDefinitions.length) {
            return null;
        }

        const rows: any[] = [];
        this.state.selectedSchedule.taskDefinitions.map((item: any, index: number) => {
            let isBtnDisabled = index === 0 && this.state.selectedSchedule && this.state.selectedSchedule.taskDefinitions.length <= 1;
            return rows.push((
                <tr key={item.id}>
                    <td className="highlight-cell schedule-event-name">
                        {item.subject}
                    </td>
                    <td>
                        <DateComponent date={item.startsAt} />
                    </td>
                    <td>
                        <DateComponent date={item.endsAt} />
                    </td>
                    <td className="borderless text-center">
                        <button className={`${styles.actionBtn} ${isBtnDisabled ? styles.disabled : ''}`}
                                onClick={() => this.toggleDeleteModal(item.id)}>
                            <i className="feather icon-trash-2" />
                        </button>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private toggleDeleteModal = (itemId: string) => {
        if (!this.state.selectedSchedule || !this.state.selectedSchedule.taskDefinitions ||
            this.state.selectedSchedule.taskDefinitions.length === 1) {
            return;
        }
        this.setState({isLoading: true});

        let tasks = this.state.selectedSchedule.taskDefinitions.filter((schedule: {[key: string]: any}) => {
           return schedule.id !== itemId;
        });
        let schedules: IScheduleItem[] = [];

        if (this.state.selectedSchedule.scheduleList && this.state.selectedSchedule.scheduleList.schedules) {
            this.state.selectedSchedule.scheduleList.schedules.map((item: {[key: string]: any}) => {
                return schedules.push({
                    name: item.name,
                    icon: item.icon,
                    from: item.from,
                    to: item.to,
                    itemType: item.itemType,
                })
            })
        }

        tasks = tasks.map((item: {[key: string]: any}) => {
            return {
                subject: item.subject,
                content: '',
                needsReview: false,
                startsAt: item.startsAt,
                endsAt: item.endsAt
            }
        });

        let payload: IEditSchedulePayload = {
            scheduleList: {
                schedules: schedules
            },
            taskDefinitions: tasks
        };

        this.subscriptions.push(
            editScheduleAPI(this.props.authToken, this.state.selectedSchedule.id, payload).pipe(
                map((resp: any) => {
                    this.setState({isLoading: false, selectedSchedule: resp});
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.alertManager.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };

    private toggleEditModal = () => {
        this.setState({
            isEditModalVisible: !this.state.isEditModalVisible
        });
    };

    private assignSchedule = () => {
        this.setState({isAssignModalVisible: true});
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(withTranslation()(SchedulesDetails));
