import {
    IFormConfig
} from 'educat-common-web';


export const mentorHourlyRatesFormConfig: typeof IFormConfig = (groups: any[]) => ({
    controlType: 'form',
    class: 'onboarding-form onboarding-table',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: groups
});


