import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function assignScheduleAPI(
    authToken: string | null,
    scheduleId: string,
    applicantId: string,
    mentorId: string | null
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.put(
        `schedules/${scheduleId}/create_instance`,
        {
            applicantId,
            mentorId,
        },
        new RestQueryParams(),
        headers
    );
}
