import React, {Component} from 'react';
import {CustomCard, InputBasic, InputType, Translation, YouTubeVideo} from "educat-common-web";
import styles from "./styles.module.scss";
import {WithTranslation, withTranslation} from "react-i18next";


interface IConnectedVideoPresentationProps {}

interface IExternalVideoPresentationProps {
    videoLink: string | null;
    updateData: (value: {[key: string]: any},
                 onSuccess: () => void,
                 onError: () => void) => void;
}

interface IVideoPresentationProps extends
    IConnectedVideoPresentationProps,
    IExternalVideoPresentationProps,
    WithTranslation {}

interface IVideoPresentationState {
    videoUrl: string | null;
    linkValue: string;
    isVideoEditable: boolean;
    isLoading: boolean;
}

class VideoPresentation extends Component<IVideoPresentationProps, IVideoPresentationState> {
    constructor(props: IVideoPresentationProps) {
        super(props);
         this.state = {
             videoUrl: this.props.videoLink,
             linkValue: '',
             isVideoEditable: false,
             isLoading: false
         };

    }

    render() {
        const {t} = this.props;

        return (
            <CustomCard showLocalLoader={this.state.isLoading}>
                <CustomCard.Body>
                    <h2 className="secondary-title">
                        <Translation text={'profile.video.title'} />
                    </h2>

                    {this.props.videoLink ?
                        <YouTubeVideo youtubeLink={this.props.videoLink} /> :
                        <p><Translation text={'profile.video.noVideo'} /></p>
                    }
                    <div className={styles.btnContainer}>
                        <button className="btn btn-theme-no-outline" disabled={!this.props.videoLink}
                                onClick={() => {
                                    this.setState({isLoading: true});
                                    this.props.updateData(
                                        {videoLink: null},
                                        () => this.setState({isLoading: false}),
                                        () => this.setState({isLoading: false})
                                    )
                                }}>
                            <Translation text={'profile.video.deleteVideo'}/>
                        </button>

                        <button className="btn btn-theme-no-outline"
                                onClick={() => this.setState({isVideoEditable: !this.state.isVideoEditable})}>
                            <Translation text={'profile.video.addVideo'}/>
                        </button>
                    </div>

                    {this.state.isVideoEditable ?
                        (<div className={styles.inputWrapper}>
                            <InputBasic value={this.state.linkValue}
                                        name={'videoLink'}
                                        placeholder={t('profile.video.videoLinkPlaceholder')}
                                        type={InputType.TEXT}
                                        handleChange={(e: any) => {
                                            this.setState({
                                                linkValue: e.target.value,
                                            })
                                        }}
                                        inputStyles={styles.input}
                                        autoComplete="off"/>

                            <div className={styles.editBtnContainer}>
                                <button onClick={() => this.setState({isVideoEditable: !this.state.isVideoEditable})}>
                                    <i className="feather icon-x" />
                                </button>
                                <button onClick={() => {
                                    this.setState({isLoading: true});

                                    this.props.updateData(
                                        {videoLink: this.state.linkValue},
                                        () => this.setState({
                                            isLoading: false,
                                            isVideoEditable: false,
                                            linkValue: ''
                                        }),
                                        () => this.setState({isLoading: false})
                                    )
                                }}>
                                    <i className="feather icon-check" />
                                </button>
                            </div>
                        </div>)
                    : null}
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default withTranslation()(VideoPresentation);
