import React from "react";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    TimeLine,
    Translation
} from "educat-common-web";
import styles from "./styles.module.scss";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {ScheduleMode} from "../../../Applicants/ApplicantView/ApplicantSchedule";
import {getSchoolAPI} from "../../../../api/getSchool";
import {catchError, map} from "rxjs/operators";


interface ISchedulePreviewModalConnectedProps {
    authToken: string | null;
}

interface ISchedulePreviewModalExternalProps {
    isModalVisible: boolean;
    toggleModal: () => void;
    scheduleDetails: {[key: string]: any};
    acceptSchedule: () => void;
}

interface ISchedulePreviewModalProps extends ISchedulePreviewModalConnectedProps,
    ISchedulePreviewModalExternalProps,
    WithTranslation {
}

interface ISchedulePreviewModalState {
    isLoading: boolean;
    data: any[];
}

class SchedulePreviewModal extends React.Component<ISchedulePreviewModalProps, ISchedulePreviewModalState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ISchedulePreviewModalProps) {
        super(props);

        this.state = {
            isLoading: true,
            data: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (this.props.scheduleDetails) {
           this.getSchoolDetails(this.props.scheduleDetails.schoolName);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.isModalVisible} closeModal={this.props.toggleModal}>
                <div className={styles.modalWrapper}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Header>
                            <div className={styles.modalHeader}>
                                <div className={styles.headerBtn}>
                                    <button className={styles.btnClose} onClick={() => this.props.toggleModal()}>
                                        <span className="feather icon-x"/>
                                    </button>
                                </div>
                                <h2 className={styles.modalTitle}>
                                    {this.state.data.length ? (
                                        <Translation text="schedules.addScheduleModal.previewTitle"
                                                 config={{school: this.state.data[0].schoolName}}/>) : null}
                                </h2>
                                <p className={styles.description}><Translation text="schedules.addScheduleModal.previewDescription" /></p>
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>


                            <div className={styles.scheduleContainer}>
                                <TimeLine data={this.state.data}
                                          mode={ScheduleMode.MONTH}
                                          itemHeight={40}
                                          dayWidth={24}
                                          interactiveMode={false}
                                />
                            </div>
                            <div className={styles.btnContainer}>
                                <button className="btn btn-theme"
                                        onClick={() => this.props.toggleModal()}>
                                    <Translation text={"schedules.addScheduleModal.backToEdit"} />
                                </button>

                                <button className="btn btn-theme"
                                        onClick={() => this.props.acceptSchedule()}>
                                    <Translation text={"schedules.addScheduleModal.accept"} />
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
            </BasicModal>
        )
    }

    private getSchoolDetails = (schoolId: string) => {
        return this.subscriptions.push(
            getSchoolAPI(schoolId, this.props.authToken).pipe(
                map((school: any) => {
                    this.setState({
                        isLoading: false,
                        data: [{
                            scheduleList: this.props.scheduleDetails.scheduleList,
                            schoolName: school.name,
                        }]
                    })
                }),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    this.setState({isLoading: false});
                    return of(null);
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(withTranslation()(SchedulePreviewModal));
