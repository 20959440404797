import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {RootState} from "../../../store/reducers";
import {
    consultationSlotsToBlockSelector,
    CustomCard,
    IBlockerEvent,
    Tabs,
    Translation
} from "educat-common-web";
import CalendarGenerator from "./CalendarGenerator";
import CalendarDetailsForm from "./CalendarDetailsForm";
import MonthCalendar from "./MonthCalendar";
import PopulateCalendarModal from "./PopulateCalendarModal";
import BlockSlotsCalendarModal from "./BlockSlotsCalendarModal";
import { isSameValue } from "educat-common-web";

interface IConnectedCalendarEditProps {
    readonly slotsToBlock: typeof IBlockerEvent[];
}

interface ICalendarEditState {
    populateModalShown: boolean;
    blockSlotsModalShown: boolean;
    chosenTab: number;
    calendarPopulated: boolean;
}

interface IExternalCalendarEditProps {
    readonly calendar: any;
    readonly freeTerms: { [key: string]: any } | null;
    readonly getAvailableConsultationSlots: (from?: string, until?: string) => void;
}

interface ICalendarEditProps extends IConnectedCalendarEditProps, IExternalCalendarEditProps, RouteComponentProps {
}


class CalendarEdit extends React.Component<ICalendarEditProps, ICalendarEditState> {
    constructor(props: any) {
        super(props);

        this.state = {
            populateModalShown: false,
            blockSlotsModalShown: false,
            chosenTab: 0,
            calendarPopulated: false,
        };
    }

    componentDidMount(): void {
        if (this.props.calendar !== null) {
            this.setState({
                calendarPopulated: !(!this.props.calendar?.calendarRules?.length || !this.props.calendar?.calendarFreeTerms?.length)
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<ICalendarEditProps>, prevState: Readonly<ICalendarEditState>, snapshot?: any) {
        if (!isSameValue(this.props.calendar, prevProps.calendar) && this.props.calendar !== null) {
            if (this.props.calendar.calendarRules && this.props.calendar.availableFrom && this.props.calendar.availableUntil) {
                this.setState({
                    calendarPopulated: !(!this.props.calendar?.calendarRules?.length || !this.props.calendar?.calendarFreeTerms?.length)
                });
            }
        }
    }

    render() {
        const calendarRules = this.props.calendar && this.props.calendar.calendarRules ? this.props.calendar.calendarRules : null;
        return (
            <section className="d-flex flex-column" id="calendar">
                <CustomCard showLocalLoader={!this.props.calendar}>
                    <CustomCard.Header>
                        <div className="view-header">
                            <h2 className="view-title">
                                <Translation text={"calendar.title"}/>
                            </h2>
                        </div>
                    </CustomCard.Header>

                    <CustomCard.Body>
                        <div className="row">
                            <CalendarDetailsForm calendar={this.props.calendar} reloadCalendar={this.props.getAvailableConsultationSlots}/>
                            {this.renderCalendarGenerator()}
                        </div>
                        {this.state.populateModalShown &&
                        <PopulateCalendarModal populateModalShown={this.state.populateModalShown}
                                               toggleModal={this.togglePopulateModal}
                                               calendarRules={calendarRules}
                                               redirectToMonth={this.redirectToMonth}
                                               freeTerms={this.props.freeTerms}/>
                        }

                        {this.state.blockSlotsModalShown &&
                        <BlockSlotsCalendarModal blockSlotsModalShown={this.state.blockSlotsModalShown}
                                                 toggleModal={this.toggleBlockSlotsModal}
                                                 redirectToMonth={this.redirectToMonth}
                                                 retrieveCalendarData={this.props.getAvailableConsultationSlots}
                        />
                        }
                    </CustomCard.Body>
                </CustomCard>
            </section>
        );
    }

    private renderCalendarGenerator() {
        return (
            <div className="col-12">
                <div className="d-flex justify-content-end">
                    {this.renderActionButton()}
                </div>
                <Tabs chosenTab={this.state.chosenTab} selectedTab={this.selectedTab}>
                    <div className="tab-headers">
                        <span>
                          <Translation text={"calendar.calendarTimeSlots.tabs.week.timeSlots"}/>
                        </span>
                        <span>
                            <Translation text={"calendar.calendarTimeSlots.tabs.calendar.title"}/>
                        </span>
                    </div>
                    <div className="tabs-content">
                        <div className="tabs-content">
                            <CalendarGenerator retrievedCalendar={this.props.calendar} reloadCalendar={this.props.getAvailableConsultationSlots}/>
                        </div>
                        <div className="tabs-content">
                            <MonthCalendar availableConsultationSlots={this.props.freeTerms}
                                           getAvailableConsultationSlots={this.props.getAvailableConsultationSlots}
                            />
                        </div>
                    </div>
                </Tabs>
            </div>
        );
    }

    private renderActionButton() {
        if (this.state.chosenTab === 1) {
            return (
                <button className="btn btn-theme"
                        type="button"
                        onClick={() => this.toggleBlockSlotsModal()}
                        disabled={(this.props.slotsToBlock.length < 1)}>
                    <Translation text={'calendar.buttons.blockSlots'}/>
                </button>
            )
        } else {
            return (
                <button className="btn btn-theme"
                        type="button"
                        onClick={() => this.togglePopulateModal()}
                        disabled={!this.props.calendar?.id}>
                    <Translation text={this.state.calendarPopulated ? 'calendar.buttons.update' : 'calendar.buttons.populate'}/>
                </button>
            )
        }
    }

    private redirectToMonth = () => {
        this.setState({chosenTab: 1});
    };

    private selectedTab = (tabNumber: number) => {
        this.setState({chosenTab: tabNumber});
    };

    private togglePopulateModal = () => {
        this.setState({populateModalShown: !this.state.populateModalShown});
    };

    private toggleBlockSlotsModal = () => {
        this.setState({blockSlotsModalShown: !this.state.blockSlotsModalShown});
    };
}

export default connect(
    (state: RootState) => ({
        slotsToBlock: consultationSlotsToBlockSelector(state),
    }),
    {}
)(withRouter(CalendarEdit));
