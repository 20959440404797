import {Epic, StateObservable} from 'redux-observable';
import {distinctUntilChanged, mergeMap, map} from 'rxjs/operators';
import * as Sentry from "@sentry/react";
import * as jose from 'jose';
import {isNullOrUndefined, authTokenSelector} from 'educat-common-web';
import {of} from 'rxjs';
import {dummyAction} from '../reducers/authSlice';

const sentryUserContextEpic: Epic = (action$, state$: StateObservable<any>) => {
    process(authTokenSelector(state$.value));

    return state$.pipe(
        map((state: any) => authTokenSelector(state)),
        distinctUntilChanged(),
        mergeMap((token: string|null): any => {
            process(token);

            return of(dummyAction());
        }),
    );
};

const process = (token: string|null) => {
    if (isNullOrUndefined(token)) {
        Sentry.setUser(null);
    } else {
        const claims = jose.decodeJwt(token as string);
        Sentry.setUser({ email: claims.username as string, id: claims.user_id as string });
    }};

export default sentryUserContextEpic;
