import {Observable} from "rxjs";
import {educatAPI, RestQueryParams} from "educat-common-web";
import {ICalendarRule} from "../store/reducers/calendarSlice";

export function addCalendarRulesAPI(
    authToken: string,
    calendarId: string,
    calendarRules: ICalendarRule[]
): Observable<any> {
    const payload: any = {
        calendarRules: calendarRules
    };

    return educatAPI.put(
        `calendars/${calendarId}/calendar_rules`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
