import {ItemPrice} from '../../components/OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService';
import createAPIHandler from '../base/apiFactory';


export enum ServiceType {
    SERVICE_CONSULTATION_PACKAGE = 'serviceConsultationPackage',
    SERVICE_APPLICATION_PACKAGE = 'serviceApplicationPackage'
}

export type CalculatedPriceDTO = {
    discount: number | null;
    discountedPrice: number | null;
    description: string;
    price: ItemPrice | null;
    serviceId: string;
    serviceType: ServiceType;
};
export type TutoringSubject = {
    id: string;
    name: string;
    maturityExamId: string | null;
    examId: string | null;
    otherId: string | null;
    maturityExamArray: [];
    examArray: [];
    otherArray: [];
}

export type CalculatedPrices = {
    serviceApplicationPackagePrices: CalculatedPriceDTO[];
    serviceConsultationPackagePrices: CalculatedPriceDTO[];
};

export const educatCalculatedPricesAPI = createAPIHandler({
    url: process.env.REACT_APP_API_URL,
    defaultHeaders: {
        'Accept': 'application/ld+json',
        'Content-type': 'application/ld+json',
        'Accept-Language': 'pl-PL, pl;q=0.9, en;q=0.5, *;q=0.2'
    },
    mapper: (data: any): CalculatedPrices => {
        let calculatedServiceApplicationPackagePrices: CalculatedPriceDTO[] = [],
            calculatedServiceConsultationPackagePrices: CalculatedPriceDTO[] = [],
            mappedData: CalculatedPrices;
        if (data && data['hydra:member']) {
            const apiData = data['hydra:member'];
            apiData.forEach((calculatedPrice: CalculatedPriceDTO) => {
                calculatedPrice.serviceType === ServiceType.SERVICE_APPLICATION_PACKAGE ? calculatedServiceApplicationPackagePrices.push(calculatedPrice) : calculatedServiceConsultationPackagePrices.push(calculatedPrice);
            })

        }
        mappedData = {
            serviceApplicationPackagePrices: calculatedServiceApplicationPackagePrices,
            serviceConsultationPackagePrices: calculatedServiceConsultationPackagePrices
        };

        return mappedData;
    },
    isList: false
});
