import {
    ContentFilesTable,
    CustomCard,
    getContentFilesAPI,
    FileUploader,
    IContentFile,
    isNullOrUndefined,
    RestQueryParams,
    Translation,
    UserRole,
    assignContentFileToApplicantAPI,
    isSameValue
} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import styles from './styles.module.scss';
import {catchError, map, tap} from 'rxjs/operators';
import AddContentFilesModal from "./AddContentFilesModal";


interface IApplicantContentFilesExternalProps {
    readonly applicantAccountId?: string | null;
    readonly isLoading: boolean;
    readonly authToken: string | null;
}

interface IApplicantContentFilesProps extends IApplicantContentFilesExternalProps,
    WithTranslation {
}

interface IApplicantContentFilesState {
    isLoading: boolean;
    contentFiles: typeof IContentFile[] | null;
    isContentFilesModalVisible: boolean;
}

class ApplicantContentFiles extends React.Component<IApplicantContentFilesProps, IApplicantContentFilesState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IApplicantContentFilesProps) {
        super(props);

        this.state = {
            isLoading: false,
            contentFiles: null,
            isContentFilesModalVisible: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.fetchContentFiles();
    }

    componentDidUpdate(
        prevProps: Readonly<IApplicantContentFilesProps>,
        prevState: Readonly<IApplicantContentFilesState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.applicantAccountId, prevProps?.applicantAccountId)) {
            this.fetchContentFiles();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <CustomCard showLocalLoader={this.isLoading}>
                    <CustomCard.Header>
                        <h2 className="custom-card-title">
                            <Translation text="dashboard.contentFiles.title"/>
                        </h2>
                    </CustomCard.Header>
                    <CustomCard.Body>

                        {!Array.isArray(this.state.contentFiles) || (this.state.contentFiles.length === 0 && !this.isLoading) ?
                            (<p>
                                <Translation text={'contentFiles.applicantMaterials.table.noData'}/>
                            </p>) :
                            (<ContentFilesTable contentFiles={this.state.contentFiles}
                                                userRole={UserRole.APPLICANT}
                                                reloadContentFileList={this.fetchContentFiles}/>)
                        }

                        <div className={`button-wrapper ${styles.btnWrapper}`}>
                            <button className="btn btn-theme" onClick={() => this.toggleAddContentFilesModal()}>
                                <Translation text="applicants.applicantView.contentFiles.buttons.addList"/>
                            </button>

                            <FileUploader onFileUpload={this.onFileUploadSuccess} isDragDropHidden={true} onUpload={() => this.setState({isLoading: true})}/>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
                {this.state.isContentFilesModalVisible &&
                    (<AddContentFilesModal isModalVisible={this.state.isContentFilesModalVisible}
                                           applicantId={this.props.applicantAccountId}
                                           toggleModal={this.toggleAddContentFilesModal}/>)
                }
            </>
        )
    }

    private get isLoading(): boolean {
        return this.props.isLoading || this.state.isLoading;
    }

    private onFileUploadSuccess = (file: {[key: string]: any}) => {
        if (isNullOrUndefined(this.props.applicantAccountId) || isNullOrUndefined(this.props.authToken)) {
            this.setState({isLoading: false, contentFiles: null});

            return;
        }

        this.subscriptions.push(
            assignContentFileToApplicantAPI(
            this.props.authToken,
            file.id,
            this.props.applicantAccountId,
            file.name
            ).pipe(
                tap(() => this.fetchContentFiles()),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };

    private fetchContentFiles = (): void => {
        if (isNullOrUndefined(this.props.applicantAccountId) || isNullOrUndefined(this.props.authToken)) {
            this.setState({isLoading: false, contentFiles: null});

            return;
        }
        this.setState({isLoading: true});

        const params = new RestQueryParams()
            .add('accounts.id', this.props.applicantAccountId);

        this.subscriptions.push(
            getContentFilesAPI(this.props.authToken as string, params).pipe(
                map((resp: any) => {
                    this.setState({
                        isLoading: false,
                        contentFiles: resp['hydra:member'].map((output: { readonly contentFile: typeof IContentFile }) => output.contentFile)
                    });
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isLoading: false});
                    return of(error);
                })
            ).subscribe()
        )
    };

    private toggleAddContentFilesModal = () => {
        this.setState({isContentFilesModalVisible: !this.state.isContentFilesModalVisible});
    }
}

export default withTranslation()(ApplicantContentFiles);
