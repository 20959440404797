import {AccountImageHost, CustomCard, MultiSelect, MultiSelectType, Translation, UserRole,} from 'educat-common-web';
import React from 'react';
import RichTextComponent from './RichTextEditor';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import SchoolDetails from './SchoolDetails';


export enum MentorStatus {
    MASTER = 'master',
    STUDENT = 'bachelor'
}

interface IConnectedProfileInfoCardProps {
}

interface IExternalProfileInfoCardProps {
    mentorDetails: { [key: string]: any } | null;
    realmsList: { [key: string]: any }[] | [];
    updateData: (value: { [key: string]: any },
                 onSuccess: () => void,
                 onError: () => void) => void;
}

interface IProfileInfoCardProps extends IConnectedProfileInfoCardProps,
    IExternalProfileInfoCardProps,
    WithTranslation {
}

interface IProfileInfoCardState {
    isLoading: boolean;
    realmsValue: { [key: string]: any }[] | [];
    isRealmEditable: boolean;
}


class ProfileInfoCard extends React.Component<IProfileInfoCardProps, IProfileInfoCardState> {
    constructor(props: IProfileInfoCardProps) {
        super(props);
        this.state = {
            isLoading: false,
            realmsValue: [],
            isRealmEditable: false
        };
    }

    render() {
        const mentor = this.props.mentorDetails,
            realms: any = [];

        if (mentor && mentor.realms && mentor.realms.length) {
            mentor.realms.map((realm: { [key: string]: any }) => {
                return realms.push({
                    name: realm.name,
                    id: realm.id
                });
            })
        }
        return (
            <CustomCard showLocalLoader={this.state.isLoading}>
                <div className="mentor-details-section-wrapper mentor-profile-section row">
                    <div className="mentor-img-wrapper col-12 col-xl-3">
                        <AccountImageHost UserRole={UserRole.MENTOR} onImageChange={() => null}/>
                    </div>
                    <div className="mentor-details-wrapper col-12 col-xl-9">
                        <SchoolDetails mentor={mentor}/>
                        <div className="main-title">
                            <div className="name-wrapper">
                                {mentor?.account.firstName} {mentor?.account.lastName}
                            </div>
                        </div>

                        <p className="paragraph">
                            {mentor?.languages && mentor?.languages.length ?
                                <>
                                    <Translation text={'profile.profileInfo.languages'}/>
                                    {mentor?.languages.map((language: { [key: string]: any }) => {
                                        return (
                                            <span key={language.id} className="language-label">
                                                <Translation text={`language.${language.id}`}/>
                                            </span>
                                        )
                                    })}
                                </> :
                                null}
                            {this.renderMentorStatus()}
                        </p>

                        <RichTextComponent updateData={this.updateDescription}
                                           label={`profile.profileInfo.description`}
                                           value={mentor?.longDescription}
                                           type={'longDescription'}/>

                        <p className="paragraph">
                            <Translation text={`profile.profileInfo.realms`}/>
                        </p>

                        {realms.length ? (
                            <div className="subjects">
                                {realms.map((item: { [key: string]: any }, index: number) => {
                                    return (
                                        <div className="subject-item edit-subject-item" key={index}>
                                            <p>{item.name}</p>
                                            <button className="edit-subject-button"
                                                    onClick={() => this.removeRealms(item.id)}>
                                                <i className="feather icon-x"/>
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : null}

                        <button className="btn btn-theme-no-outline"
                                onClick={() => this.setState({isRealmEditable: !this.state.isRealmEditable})}>
                            <Translation text={'profile.profileInfo.addNewRealm'}/>
                        </button>
                        {this.renderRealmsSelect()}

                        <RichTextComponent updateData={this.updateDescription}
                                           label={`profile.profileInfo.workDescription`}
                                           value={mentor?.programme}
                                           type={'programme'}/>
                    </div>
                </div>
            </CustomCard>
        );
    }

    private renderMentorStatus = () => {
        let mentor = this.props.mentorDetails;
        if (!mentor) {
            return;
        }

        let status: string | null = mentor.studyLevel,
            statusDescription: string | null = mentor.mentorSchoolStudyFields[0]?.statusDescription;

        return (
            <React.Fragment>
                {status ?
                    (<>
                        <span className="divider"/>
                        <Translation text={`profile.profileInfo.studyLevel.${status}`}/>
                    </>) :
                    null
                }
                {status === MentorStatus.STUDENT && statusDescription ?
                    <span> - {statusDescription} <Translation text={`profile.profileInfo.studyLength`}/></span>
                    : null}
            </React.Fragment>
        )
    };

    private renderRealmsSelect = () => {
        if (!this.state.isRealmEditable) {
            return;
        }

        const {t} = this.props;
        return (
            <div className={styles.multiselectContainer}>
                <MultiSelect
                    multiselectType={MultiSelectType.DEFAULT}
                    handleChange={(e: any) => this.setState({realmsValue: e})}
                    options={this.props.realmsList}
                    name={'select_calendars'}
                    placeholder={t('profile.profileInfo.select')}
                    value={this.state.realmsValue}
                />
                <div className={styles.editBtnContainer}>
                    <button onClick={() => this.setState({isRealmEditable: !this.state.isRealmEditable})}>
                        <i className="feather icon-x"/>
                    </button>
                    <button onClick={() => this.addRealms()}>
                        <i className="feather icon-check"/>
                    </button>
                </div>
            </div>
        )
    };

    private removeRealms = (itemId: string) => {
        this.setState({isLoading: true});
        this.props.updateData(
            {
                realms: this.props.mentorDetails?.realms
                    .filter((realm: { [key: string]: any }) => realm.id !== itemId)
                    .map((filteredRealms: { [key: string]: any }) => filteredRealms.id)
            },
            () => this.setState({
                isLoading: false,
            }),
            () => this.setState({isLoading: false})
        )
    };

    private addRealms = () => {
        const profileRealms = this.props.mentorDetails?.realms.map((realm: { [key: string]: any }) => realm.id),
            selectedRealms = this.state.realmsValue.map((realm: { [key: string]: any }) => realm.value);
        this.setState({isLoading: true});
        this.props.updateData(
            {realms: [...profileRealms, ...selectedRealms]},
            () => this.setState({
                isLoading: false,
                isRealmEditable: false,
                realmsValue: []
            }),
            () => this.setState({isLoading: false})
        )
    };

    private updateDescription = (descriptionType: string, value: string) => {
        this.setState({isLoading: true});
        this.props.updateData(
            {[descriptionType]: value},
            () => this.setState({
                isLoading: false
            }),
            () => this.setState({isLoading: false})
        )
    }
}

export default withTranslation()(ProfileInfoCard);
