import React from 'react';
import styles from './styles.module.scss';
import {
    convertScheduleInstanceToISchedules,
    CustomCard,
    getScheduleInstancesAPI,
    isNullOrUndefined,
    isSameValue,
    RestQueryParams,
    TimeLine,
    TimelineDate,
    Translation
} from 'educat-common-web';
import AddScheduleModal from '../../../Schedules/AddScheduleModal';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {catchError, tap} from 'rxjs/operators';


export enum ScheduleMode {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year'
}

interface IExternalApplicantScheduleProps {
    readonly isLoading: boolean;
    readonly authToken: string | null;
    readonly applicantId: string | null;
}

interface IApplicantScheduleProps extends IExternalApplicantScheduleProps {
}

interface IApplicantScheduleState {
    isEditModalVisible: boolean;
    selectedSchedule: { [key: string]: any } | null;
    isLoading: boolean;
    schedules: any[];
}


class ApplicantSchedule extends React.Component<IApplicantScheduleProps, IApplicantScheduleState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IApplicantScheduleProps) {
        super(props);

        this.state = {
            isEditModalVisible: false,
            selectedSchedule: null,
            isLoading: false,
            schedules: [],
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getSchedules();
    }

    componentDidUpdate(prevProps: Readonly<IApplicantScheduleProps>, prevState: Readonly<IApplicantScheduleState>, snapshot?: any): void {
        if (!isSameValue(this.props.applicantId, prevProps.applicantId)) {
            this.getSchedules();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const schedules = this.state.schedules.map(convertScheduleInstanceToISchedules),
            hasSchedules = Array.isArray(schedules) && schedules.length > 0;

        return (
            <>
                <CustomCard showLocalLoader={this.props.isLoading || this.state.isLoading}>
                    <CustomCard.Header>
                        <div className={styles.header}>
                            <h2 className="custom-card-title">
                                <Translation text="dashboard.schedule.title"/>
                            </h2>
                            <TimelineDate/>
                        </div>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        <div className={styles.scheduleWrapper}>
                            {hasSchedules && <TimeLine data={schedules}
                                                       mode={ScheduleMode.MONTH}
                                                       itemHeight={40}
                                                       itemWidth={40}
                                                       dayWidth={24}
                                                       interactiveMode={false}/>}
                        </div>
                        {/* hidden until schedule update issues are resolved */}
                        {/*<div className={`button-wrapper ${styles.btnWrapper}`}>*/}
                        {/*    <button className="btn btn-theme" onClick={() => this.toggleEditModal()}>*/}
                        {/*        <Translation text="buttons.edit"/>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </CustomCard.Body>
                </CustomCard>

                {this.state.isEditModalVisible ?
                    (<AddScheduleModal isModalVisible={this.state.isEditModalVisible}
                                       toggleModal={this.toggleEditModal}
                                       schedule={this.state.selectedSchedule}
                                       updateStateSchedule={(item: { [key: string]: any }) => this.setState({selectedSchedule: item})}
                    />) : null}
            </>
        );
    }

    private toggleEditModal = () => {
        this.setState({
            isEditModalVisible: !this.state.isEditModalVisible
        });
    };

    private getSchedules = (): void => {
        if (isNullOrUndefined(this.props.applicantId)) {
            return;
        }
        if (this.state.isLoading) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        } else {
            this.setState({isLoading: true});
        }
        const params = new RestQueryParams().add('applicant.id', this.props.applicantId);

        this.subscriptions.push(
            getScheduleInstancesAPI(this.props.authToken, params).pipe(
                tap((resp: any) => {
                    this.setState({isLoading: false, schedules: (resp?.['hydra:member'] || [])})
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isLoading: false});
                    return of(error);
                })
            ).subscribe()
        );
    };
}

export default ApplicantSchedule;
