import {RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {educatCalculatedPricesAPI} from './provider/educatCalculatedPricesAPI';


export function calculatePackagePricesAPI(
    authToken: string,
    mentorId: string,
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatCalculatedPricesAPI.get(
        `mentors/${mentorId}/calculate_package_prices`,
        new RestQueryParams(),
        headers
    );
}
