import createAPIHandler from '../base/apiFactory';


export type TutoringSubject = {
    id: string;
    name: string;
    maturityExamId: string | null;
    examId: string | null;
    otherId: string | null;
    maturityExamArray: [];
    examArray: [];
    otherArray: [];
}

export const educatTutoringScopeTypeAPI = createAPIHandler({
    url: process.env.REACT_APP_API_URL,
    defaultHeaders: {
        'Accept': 'application/ld+json',
        'Content-type': 'application/ld+json',
        'Accept-Language': 'pl-PL, pl;q=0.9, en;q=0.5, *;q=0.2'
    },
    mapper: (data: any): any => {
        let subjectArray: TutoringSubject[] = [];
        if (data && data['hydra:member']) {
            const apiData = data['hydra:member'];
            apiData.forEach((tutoringScopeType: any) => {
                if (!subjectArray.some((subject: TutoringSubject) => tutoringScopeType.subject.id === subject.id)) {
                    const subjectItem: TutoringSubject = {
                        id: tutoringScopeType.subject.id,
                        name: tutoringScopeType.subject.name,
                        maturityExamArray: [],
                        maturityExamId: null,
                        examArray: [],
                        examId: null,
                        otherArray: [],
                        otherId: null
                    }
                    subjectArray.push(subjectItem);
                }
            })
            subjectArray = subjectArray.map((subject: TutoringSubject) => {
                const dataForSubject = apiData.filter((tutoringScopeType: any) => tutoringScopeType.subject.id === subject.id);
                dataForSubject.forEach((dataForSubjectItem: any) => {
                    if (dataForSubjectItem.name === 'matura') {
                        const maturityExamArray = dataForSubjectItem.tutoringScopeItemTypes.map((tutoringScopeType: any) => {
                            return {name: tutoringScopeType.name, id: tutoringScopeType.id}
                        })
                        subject.maturityExamId = dataForSubjectItem.id;
                        subject.maturityExamArray = maturityExamArray;
                    }
                    if (dataForSubjectItem.name === 'pozostałe') {
                        const otherArray = dataForSubjectItem.tutoringScopeItemTypes.map((tutoringScopeType: any) => {
                            return {name: tutoringScopeType.name, id: tutoringScopeType.id}
                        })
                        subject.otherId = dataForSubjectItem.id;
                        subject.otherArray = otherArray;
                    }
                    if (dataForSubjectItem.name === 'egzaminy') {
                        const examArray = dataForSubjectItem.tutoringScopeItemTypes.map((tutoringScopeType: any) => {
                            return {name: tutoringScopeType.name, id: tutoringScopeType.id}
                        })
                        subject.examId = dataForSubjectItem.id;
                        subject.examArray = examArray;
                    }
                })
                return subject;
            });
        }
        return subjectArray;
    },
    isList: false
});
