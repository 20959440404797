import {FileType, Form, IFormConfig, ImageUploader, Translation} from "educat-common-web";
import React from "react";
import {Subscription} from "rxjs";
import {MentorRegistrationSteps} from "../../../../service/mentorRegistrationService";
import {mentorBasicInformationFormConfig} from "./mentorBasicInformationFormConfig";

interface IConnectedFormStepBasicInformationProps {
}

interface IExternalFormStepBasicInformationProps {
    readonly submitStep: (stepName: MentorRegistrationSteps, stepValue: any) => void;
    readonly prevStep: () => void;
    readonly stepData?: any;
    readonly languageList: { [key: string]: any }[];
}

interface IFormStepBasicInformationProps extends IConnectedFormStepBasicInformationProps, IExternalFormStepBasicInformationProps {
}

interface IFormStepBasicInformationState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    userImageId: string | null;
    userImageFile: typeof FileType | null;
    stepName: MentorRegistrationSteps;
    formConfig: typeof IFormConfig;
}

class FormStepBasicInformation extends React.Component<IFormStepBasicInformationProps, IFormStepBasicInformationState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IFormStepBasicInformationProps) {
        super(props);

        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: mentorBasicInformationFormConfig(this.props.languageList, this.props.stepData),
            stepValue: null,
            userImageId: null,
            userImageFile: null,
            stepName: MentorRegistrationSteps.BASIC_INFORMATION,
        };
    }

    componentDidMount() {
        if (this.props.stepData) {
            const userImageId = this.props.stepData?.userImageId,
                userImageFile = this.props.stepData?.userImageFile;
            const updatedFormConfig = mentorBasicInformationFormConfig(this.props.languageList, this.props.stepData);
            this.setState({
                formConfig: updatedFormConfig,
                stepValue: this.props.stepData,
                userImageId: userImageId,
                userImageFile: userImageFile
            });
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IFormStepBasicInformationProps>,
        prevState: Readonly<IFormStepBasicInformationState>,
        snapshot?: any
    ): void {
        if (this.state.stepValue && this.state.userImageId !== prevState.userImageId) {
            let updatedStepValue = Object.assign(this.state.stepValue, {
                userImageId: this.state.userImageId ? this.state.userImageId : null,
                userImageFile: this.state.userImageFile ? this.state.userImageFile : null,
            });
            this.setState({stepValue: updatedStepValue});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className="onboarding-form-wrapper">
                    <Form
                        config={this.state.formConfig}
                        controlName={MentorRegistrationSteps.BASIC_INFORMATION}
                        onValueStateChange={this.formChangeHandler}
                        onValidationStateChange={this.formValidityChange}
                        value={this.state.stepValue}/>
                    <div className="col-xl-12 user-image-input">
						<span className="image-input-label required">
							<Translation
                                text={"mentorOnboarding.registration.basic_information.formControls.photo.label"}/>
						</span>
                        <ImageUploader
                            selectedImageFile={this.state.userImageFile}
                            triggerText={"mentorOnboarding.registration.basic_information.formControls.photo.input"}
                            onImageChange={(imageId: string, file: any) => this.updateUserImage(imageId, file)} />
                    </div>
                </div>
                <footer className="onboarding-navigation">
                    <button type="button" onClick={() => this.props.prevStep()} className="btn btn-theme btn-back">
                        <Translation text="buttons.back"/>
                    </button>
                    <button
                        type="submit"
                        disabled={!this.state.isStepValid || this.state.userImageId === null}
                        onClick={() => this.props.submitStep(this.state.stepName, this.state.stepValue)}
                        className="btn btn-theme btn-rounded">
                        <Translation text="buttons.next"/>
                    </button>
                </footer>
            </>
        );
    }

    private updateUserImage(imageId: string | null, file: any) {
        this.setState({userImageId: imageId, userImageFile: file});
    }

    private formChangeHandler = (controlName: MentorRegistrationSteps, value: any, changeType: string) => {
        if (changeType !== "init") {
            let updatedStepValue = Object.assign(value, {
                userImageId: this.state.userImageId ? this.state.userImageId : null,
                userImageFile: this.state.userImageFile ? this.state.userImageFile : null,
            });
            this.setState({stepName: controlName, stepValue: updatedStepValue});
        }
    };
    private formValidityChange = (controlName: string, isValid: boolean) => {
        this.setState({isStepValid: isValid});
    };
}

export default FormStepBasicInformation;
