import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getSchoolAPI(
    id: string,
    authToken: string | null
): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `schools/${id}`,
        new RestQueryParams(),
        headers
    );
}
