import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    MultiSelectType,
    ValidationRules
} from 'educat-common-web';

export const openingHoursOptions = Array(24).fill(1).map((hourNumber: number, index: number) => ({
    label: `${index}:00`,
    value: index
}));

export const calendarSettingsConfig: typeof IFormConfig = (
    closingHours: typeof IMultiselectOption[] | []
) => ({
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'opening_hours',
            class: 'form-row',
            controls: {
                availableFrom: {
                    controlType: 'control',
                    defaultValue: '',
                    placeholder: 'calendar.calendarTimeSlots.form.startHourPlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'calendar.calendarTimeSlots.form.startHour',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: openingHoursOptions,
                    hostClass: 'col-xl-10 mb-3',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
                availableUntil: {
                    controlType: 'control',
                    defaultValue: '',
                    placeholder: 'calendar.calendarTimeSlots.form.lastHourPlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'calendar.calendarTimeSlots.form.lastHour',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: closingHours,
                    hostClass: 'col-xl-10',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper
                },
                slotLength: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'calendar.calendarTimeSlots.form.duration',
                    label: 'calendar.calendarTimeSlots.form.duration',
                    multiselectOptions: [{label: "1 godzina", value: 1}],
                    hostClass: 'col-xl-6 d-none',
                    disabled: true,
                    inputDataMapper: () => {
                        return [{label: "1 godzina", value: 1}];
                    },
                    outputDataMapper: collectionOutputDataMapper
                }
            }
        }
    ]
});
