import {
    IFormConfig
} from 'educat-common-web';


export const tutoringSubjectScopeGroup: typeof IFormConfig = (groupType: string, groupName: string, controls: any, isFieldset: boolean) => ({
    key: `${groupType}`,
        controlType: 'fieldset',
        fieldsetTitle: groupName,
        class: 'row maturity-fieldset default-fieldset',
        controls: controls
    });

