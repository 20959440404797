import {
    FormControlType,
    IFormConfig,
    InputType
} from 'educat-common-web';

export const mentorFreeHelpPackagesFormConfig: typeof IFormConfig = (freeHelpPackage: any, value: any) => ({
    controlType: 'form',
    class: 'onboarding-form onboarding-table',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [{
        key: `free_help_package`,
        controlType: 'group',
        class: 'row',
        controls: {
            selected: {
                controlType: 'control',
                defaultValue: value ? value.selected : true,
                // value: false,
                formControlType: FormControlType.CHECKBOX,
                validationRules: [],
                placeholder: '',
                label: freeHelpPackage.name,
                isLabelHidden: false,
                isCheckboxLabelRaw: false,
                checkboxLabel: ``,
                disabled: value?.selected ? true : false,
                hostClass: 'col-xl-4 checkbox-container'
            },
            services: {
                hostClass: `col-xl-5 onboarding-table-cell hide-label input-readonly textarea-mask`,
                controlType: 'control',
                defaultValue: value ? value.services : '--',
                disabled: true,
                formControlType: FormControlType.TEXTAREA,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
            },
            maxApplicants: {
                hostClass: `col-xl-3 justify-content-end custom-number`,
                controlType: 'control',
                defaultValue: value ? value.maxApplicants : 0,
                formControlType: FormControlType.QUANTITY_INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '',
                customIncrementButtons: true,
                minDate: 0,
                disabled: value?.maxApplicants ? true : false,
                type: InputType.NUMBER,
                inputDataMapper: (data: any) => {
                    if (!data) {
                        return null;
                    }
                    return Number(data);
                },
                outputDataMapper: (data: any) => {
                    return Number(data);
                },
            },
        }
    }
    ]
});
