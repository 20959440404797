import { Container } from 'inversify';
import getDecorators, { fixInjectedProperties } from './decorators';
import {AlertManagerService, CalendarCreationService, MentorRegistrationService, OnboardingHelperService} from '../service';

const container = new Container();

container.bind<AlertManagerService>('AlertManagerService').to(AlertManagerService).inSingletonScope();
container.bind<MentorRegistrationService>('MentorRegistrationService').to(MentorRegistrationService).inSingletonScope();
container.bind<OnboardingHelperService>('OnboardingHelperService').to(OnboardingHelperService).inSingletonScope();
container.bind<CalendarCreationService>('CalendarCreationService').to(CalendarCreationService).inSingletonScope();
const { lazyInject } = getDecorators(container);

export {container, lazyInject, fixInjectedProperties};

