import {
    authTokenSelector,
    deepCloneObject,
    Form,
    FormControlChangeType,
    IFormConfig,
    isNullOrUndefined,
    isSameValue,
    Loader,
    LoaderType,
    Translation
} from "educat-common-web";
import React from "react";
import {connect} from "react-redux";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, debounceTime, filter, tap} from "rxjs/operators";
import {updateCalendarAPI} from "../../../../api/updateCalendarAPI";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {RootState} from "../../../../store/reducers";
import {changeCalendarSettings, ICalendarDetails} from "../../../../store/reducers/calendarSlice";
import {
    calendarDetailsSelector,
    calendarIdSelector,
    calendarSettingsSelector
} from "../../../../store/selectors/calendarSelectors";
import CalendarCreationService, {
    ICalendarGeneratorHourItem,
    ICalendarRule,
    ICalendarSettings
} from "../../../../service/calendarCreationService";
import {getTimezoneOffset, numberToDateConverter} from "../../../../utils/dateTransformUtils";
import {calendarSettingsConfig, openingHoursOptions} from "./calendarGeneratorConfig";
import CalendarWeek from "./CalendarWeek";
import styles from "./styles.module.scss";

interface IConnectedCalendarGeneratorProps {
    readonly calendarSettings: ICalendarSettings | null;
    readonly calendarDetails: ICalendarDetails;
    readonly calendarId: string;
    readonly authToken: string;
}

interface ICalendarGeneratorProps extends IConnectedCalendarGeneratorProps {
    readonly changeCalendarSettings: typeof changeCalendarSettings;
    readonly reloadCalendar: (from?: string, until?: string) => void;
    readonly retrievedCalendar?: any;
}

interface ICalendarGeneratorState {
    calendarGeneratorSettings: ICalendarSettings;
    dayHoursTable: ICalendarGeneratorHourItem[];
    formConfig: typeof IFormConfig | null;
    value: any;
    calendarRules: ICalendarRule[];
    isLoading: boolean;
}

class CalendarGenerator extends React.Component<ICalendarGeneratorProps, ICalendarGeneratorState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    @lazyInject("CalendarCreationService") private calendarCreationService: CalendarCreationService;
    @lazyInject("AlertManagerService") private alertManagerService: IAlertManagerService;

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: null,
            calendarGeneratorSettings: {
                slotLength: null,
                availableFrom: null,
                availableUntil: null,
            },
            dayHoursTable: [],
            value: null,
            calendarRules: [],
            isLoading: false
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setFormConfig(null);

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.onFormValueChange(data))
                )
                .subscribe()
        );
        if (this.props.retrievedCalendar) {
            this.updateFormFromServer(this.props.retrievedCalendar);
        }
    }

    componentDidUpdate(prevProps: Readonly<ICalendarGeneratorProps>, prevState: Readonly<ICalendarGeneratorState>, snapshot?: any) {
        if (!isSameValue(this.props.retrievedCalendar?.id, prevProps.retrievedCalendar?.id)) {
            this.updateFormFromServer(this.props.retrievedCalendar, true);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <article className={`${styles.calendarGeneratorWrapper} row`}>
                <div className="col-12 col-xxl-5">
                    <header className={styles.calendarGeneratorHeader}>
                        <h3 className={styles.calendarWeekTitle}>
                            <Translation text={"calendar.calendarTimeSlots.tabs.week.details"}/>
                        </h3>
                        <Form
                            config={this.state.formConfig}
                            value={this.state.value}
                            onValueStateChange={this.onValueStateChange}
                            controlName={"calendarGeneratorForm"}
                        />
                    </header>
                </div>
                {this.renderCalendarWeek()}
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading}/>
            </article>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private renderCalendarWeek() {
        if (this.state.dayHoursTable.length < 1) {
            return;
        }

        return (
            <div className="col-12 col-xxl-7">
                <CalendarWeek
                    timeSlotsTable={this.state.dayHoursTable}
                    calendarRules={this.state.calendarRules}
                    timezone={this.props.retrievedCalendar.timezone}
                />
            </div>
        );
    }

    private onFormValueChange = (data: any) => {
        this.setState({isLoading: true});
        const calendarSettings = this.calendarCreationService.generateCalendarSettings(false, data);
        this.setFormConfig(calendarSettings.availableFrom);
        this.props.changeCalendarSettings(calendarSettings);
        this.setState({
            value: calendarSettings,
            calendarGeneratorSettings: calendarSettings,
        });
        if (
            isNullOrUndefined(calendarSettings.availableFrom) ||
            isNullOrUndefined(calendarSettings.availableUntil) ||
            isNullOrUndefined(calendarSettings.slotLength)
        ) {
            this.setState({isLoading: false});
            return;
        }
        if ((calendarSettings.availableFrom || calendarSettings.availableFrom === 0) &&
            calendarSettings.availableUntil && this.props.calendarDetails) {
            const timezoneOffset = getTimezoneOffset(this.props.calendarDetails.timezone);
            const availableFrom = numberToDateConverter(calendarSettings.availableFrom, timezoneOffset),
                availableUntil = numberToDateConverter(calendarSettings.availableUntil, timezoneOffset);
            this.updateCalendarDetails(availableFrom, availableUntil);
        }

        const slotsTable = this.calendarCreationService.generateTimeSlotsTable(calendarSettings);
        this.setState({dayHoursTable: slotsTable});
    };

    private updateFormFromServer = (calendar: any, forceSettingsUpdate = false) => {
        const calendarSettings = this.calendarCreationService.generateCalendarSettings(true, calendar);
        const calendarRules: ICalendarRule[] = deepCloneObject(calendar.calendarRules);
        this.setFormConfig(calendarSettings.availableFrom);
        this.setState({
            value: calendarSettings,
            calendarRules: calendarRules,
        });
        if (forceSettingsUpdate || !this.props.calendarSettings) {
            this.props.changeCalendarSettings(calendarSettings);
        }

        if (
            isNullOrUndefined(calendarSettings.availableFrom) ||
            isNullOrUndefined(calendarSettings.availableUntil) ||
            isNullOrUndefined(calendarSettings.slotLength)
        ) {
            return;
        }
        const slotsTable = this.calendarCreationService.generateTimeSlotsTable(calendarSettings);
        this.setState({dayHoursTable: slotsTable});
    };

    private updateCalendarDetails = (availableFrom: any, availableUntil: any) => {
        if (this.props.retrievedCalendar && this.props.retrievedCalendar.id) {
            let formattedCalendarRules: ICalendarRule[] = Array.from(this.props.retrievedCalendar.calendarRules);
            formattedCalendarRules.map((item: any) => {
                const timeString = item.endsAt.split('T')[1].split('+')[0];
                if (timeString === "00:00:00") {
                    item.endsAt = item.endsAt.replace("00:00:00", "23:59:59");
                }

                return item;
            });

            this.subscriptions.push(
                updateCalendarAPI(this.props.retrievedCalendar.id, this.props.authToken, {
                    name: this.props.calendarDetails.calendarName,
                    public: true,
                    price: this.props.calendarDetails.calendarPrice,
                    slotLength: this.props.retrievedCalendar?.slotLength,
                    availableFrom: availableFrom,
                    availableUntil: availableUntil,
                    timezone: this.props.calendarDetails.timezone,
                    calendarRules: formattedCalendarRules,
                }).pipe(
                    tap(() => {
                        this.props.reloadCalendar();
                        this.alertManagerService.addAlert("calendar.modals.confirmUpdateModal.success");
                        this.setState({isLoading: false});
                    }),
                    catchError((error: any) => {
                        this.alertManagerService.handleApiError(error.response);
                        this.setState({isLoading: false});
                        return of(error);
                    })
                ).subscribe()
            );
        }
    };

    private setFormConfig = (availableFrom: number | null) => {
        this.setState({
            formConfig: calendarSettingsConfig(this.setClosingHoursSelectOption(availableFrom))
        });
    }

    private setClosingHoursSelectOption = (availableFrom: number | null) => {
        if (availableFrom) {
            return Array(24).fill(1).map((hourNumber: number, index: number) => ({
                label: `${index}:00`,
                value: index,
                isDisabled: index <= availableFrom
            }));
        }
        return openingHoursOptions;
    }
}

export default connect(
    (state: RootState) => ({
        calendarSettings: calendarSettingsSelector(state),
        calendarDetails: calendarDetailsSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeCalendarSettings
    }
)(CalendarGenerator);
