import React from 'react';
import {isNullOrUndefined, Loader, LoaderType, Translation} from 'educat-common-web';
import {IApplicantListingOutput} from '../../../api/getApplicants';
import ApplicantsTableRow from './ApplicantsTableRow';

interface IApplicantsTableExternalProps {
    readonly applicants: IApplicantListingOutput[];
    readonly isLoading: boolean;
}

interface IApplicantsTableProps extends IApplicantsTableExternalProps {
}

interface IApplicantsTableState {
}

class ApplicantsTable extends React.Component<IApplicantsTableProps, IApplicantsTableState> {
    render() {
        if (this.hasNoApplicants) {
            return <p>
                <Translation text={'applicants.table.noData'}/>
            </p>;
        }

        return (
            <React.Fragment>
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading}/>
                <table className="data-table default-table">
                    <thead>
                    <tr>
                        <th className="highlight-cell">
                            <Translation text={'applicants.table.applicant'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'applicants.table.assistance'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'applicants.table.hours'}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.applicants.map(applicant => <ApplicantsTableRow key={applicant.id} applicant={applicant}/>)}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    private get hasNoApplicants(): boolean {
        if (isNullOrUndefined(this.props.applicants) || !Array.isArray(this.props.applicants)) {
            return true;
        }

        return 0 === this.props.applicants.length && !this.props.isLoading;
    }
}

export default ApplicantsTable;
