import {Translation, refreshAccountStateData} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';

interface IConnectedFormOnboardingThankYouProps {
    readonly refreshAccountStateData: typeof refreshAccountStateData;
}

interface IExternalFormOnboardingThankYouProps {
}

interface IFormOnboardingThankYouProps extends IConnectedFormOnboardingThankYouProps, IExternalFormOnboardingThankYouProps {
}

interface IFormOnboardingThankYouState {
}

class FormOnboardingThankYou extends React.Component<IFormOnboardingThankYouProps, IFormOnboardingThankYouState> {
    componentDidMount() {
        this.props.refreshAccountStateData();
    }

    render() {
        return (
            <article>
                <div className="onboarding-awaiting-info-container p-0">
                    <p className="onboarding-awaiting-info">
                        <Translation text={`mentorOnboarding.onboarding.thank_you.line1`}/>
                    </p>
                    <p className="onboarding-awaiting-info">
                        <Translation text={`mentorOnboarding.onboarding.thank_you.line2`}/>
                    </p>
                    <p className="onboarding-awaiting-info mb-0">
                        <Translation text={`mentorOnboarding.onboarding.thank_you.greeting`}/>
                    </p>
                    <p className="onboarding-awaiting-info">
                        <Translation text={`mentorOnboarding.onboarding.thank_you.signature`}/>
                    </p>
                    <a className="btn btn-theme" href={process.env.REACT_APP_EDUCAT_LANDING_HOST}>
                        <Translation text={'buttons.returnToHomepage'}/>
                    </a>
                </div>
            </article>
        );
    }
}

export default connect(
    () => ({}),
    {
        refreshAccountStateData,
    }
)(FormOnboardingThankYou);
