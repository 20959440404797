import {
    FormControlType,
    IFormConfig,
    IFormControls,
    isNullOrUndefined,
    MultiSelectType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper
} from 'educat-common-web';
import {TaskTypeList} from '../../../../service/mentorRegistrationService';


export const mentorHelpRangeFormConfig: typeof IFormConfig = (value: any, taskTypeList: TaskTypeList, realmList = []) => {
    const groups = taskTypeList;
    const controlGroups: any[] = [];
    if (groups.taskTypesWithoutGroups.length > 0) {
        const controls = createFormControlsFromTask(value, groups.taskTypesWithoutGroups, false);
        const helpRangeGroup = taskGroup('help_range', 'help_range', controls, false);
        controlGroups.push(helpRangeGroup);
    }
    if (groups.taskTypeGroups.length > 0) {
        groups.taskTypeGroups.forEach((taskTypeGroup: any, index) => {
            const controls = createFormControlsFromTask(value, taskTypeGroup.taskTypes, true);
            controlGroups.push(taskGroup(taskTypeGroup.id, taskTypeGroup.name, controls, true));
        });
    }
    controlGroups.push(multiselectControl(realmList, value?.realms));

    return {
        controlType: 'form',
        class: 'onboarding-form',
        dataAccessor: (data: any, name: string) => {
            if (isNullOrUndefined(data)) {
                return data;
            }

            return 'realms' === name ? data['realms'] : data;
        },
        inputDataMapper: (data: any) => {
            return data;
        },
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            ...controlGroups,
        ]
    };
};

export const taskGroup: typeof IFormControls = (groupKey: string, groupName: string, controls: any, isFieldset: boolean) => ({
    key: `${groupKey}`,
    controlType: isFieldset ? 'fieldset' : 'group',
    fieldsetTitle: isFieldset ? groupName : null,
    class: isFieldset ? 'row help-range default-fieldset' : 'row help-range-form-group',
    controls: controls,
});

export const multiselectControl: typeof IFormControls = (realmList: any[] | [], value: any) => ({
    key: 'realms',
    controlType: 'group',
    class: 'row',
    dataAccessor: (data: any) => data,
    controls: {
        realms: {
            controlType: 'control',
            formControlType: FormControlType.AUTOCOMPLETE,
            multiselectType: MultiSelectType.DEFAULT,
            multiselectOptions: realmList,
            validationRules: [],
            inputDataMapper: multiCollectionInputDataMapper,
            outputDataMapper: multiCollectionOutputDataMapper,
            placeholder: 'mentorOnboarding.registration.help_range.formControls.placeholder',
            label: 'mentorOnboarding.registration.help_range.formControls.helpRealms',
            hostClass: 'col-xl-12 form-row',
        },
    }
});

const createFormControlsFromTask = (value: any, taskArray: { name: string; id: string }[], isFieldset: boolean) => {
    const controls: any = {};
    taskArray.forEach((task: { name: string; id: string }, index) => {
        controls[task.id] = {
            controlType: 'control',
            defaultValue: false,
            formControlType: FormControlType.CHECKBOX,
            validationRules: [],
            placeholder: '',
            label: task.name,
            isLabelHidden: false,
            isCheckboxLabelRaw: false,
            checkboxLabel: ``,
            hostClass: isFieldset
                ? 'checkbox-fieldset-container'
                : 'checkbox-row',
        };
    });

    return controls;
};
