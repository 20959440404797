import {
    authTokenSelector,
    CustomCard,
    IModelMentorServiceDefinitionRates,
    isNotNullOrUndefined,
    mentorIdSelector,
    Translation
} from "educat-common-web";
import React from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import ServiceItem from "./ServiceItem";
import {getMentorServicesAPI} from "../../../api/getMentorServices";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {of, Subscription} from "rxjs";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {catchError, tap} from "rxjs/operators";


interface IConnectedServicesCardProps {
    authToken: string | null;
    mentorId: string | undefined;
}

interface IExternalServicesCardProps {
}

interface IServicesCardProps extends IConnectedServicesCardProps,
    IExternalServicesCardProps,
    WithTranslation {
}

interface IServicesCardState {
    isLoading: boolean;
    services: typeof IModelMentorServiceDefinitionRates[] | [];
}


class ServicesCard extends React.Component<IServicesCardProps, IServicesCardState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject("AlertManagerService") private alertManagerService: IAlertManagerService;

    constructor(props: IServicesCardProps) {
        super(props);
        this.state = {
            isLoading: false,
            services: [],
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getMentorServices();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const services = this.state.services;

        return (
            <CustomCard showLocalLoader={this.state.isLoading}>
                <CustomCard.Body>
                    <div className="row">
                        {/*Section hidden per client's request*/}
                        {/*<div className="col-12">*/}
                        {/*    <div className="view-header">*/}
                        {/*        <h2 className="view-title">*/}
                        {/*            <Translation text={"profile.services.servicesRates.title"}/>*/}
                        {/*        </h2>*/}
                        {/*    </div>*/}
                        {/*    {services.filter(item => isNotNullOrUndefined(item.serviceApplicationElement))*/}
                        {/*        .map(item => <ServiceItem service={item}*/}
                        {/*                                  serviceDetails={item.serviceApplicationElement}*/}
                        {/*                                  refreshList={this.getMentorServices}*/}
                        {/*                                  key={item['@id']}/>)}*/}
                        {/*</div>*/}

                        <div className="col-12">
                            <div className="view-header">
                                <h2 className="view-title">
                                    <Translation text={"profile.services.packagesRates.title"}/>
                                </h2>
                            </div>
                            {services.filter(item => isNotNullOrUndefined(item.serviceConsultationPackage))
                                .map(item => <ServiceItem service={item}
                                                          serviceDetails={item.serviceConsultationPackage}
                                                          refreshList={this.getMentorServices}
                                                          key={item['@id']}/>)}

                            {services.filter(item => isNotNullOrUndefined(item.serviceApplicationPackage))
                                .map(item => <ServiceItem service={item}
                                                          serviceDetails={item.serviceApplicationPackage}
                                                          refreshList={this.getMentorServices}
                                                          key={item['@id']}/>)}

                            {services.filter(item => isNotNullOrUndefined(item.serviceConsultation))
                                .map(item => <ServiceItem service={item}
                                                          serviceDetails={item.serviceConsultation}
                                                          refreshList={this.getMentorServices}
                                                          key={item['@id']}
                                                          hideSwitch/>)}

                        </div>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private getMentorServices = () => {
        if (undefined === this.props.mentorId) {
            return;
        }

        this.setState({isLoading: true});
        this.subscriptions.push(
            getMentorServicesAPI(this.props.authToken, this.props.mentorId).pipe(
                tap((resp: any) => {
                    this.setState({isLoading: false, services: resp['hydra:member']});
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        mentorId: mentorIdSelector(state)
    }),
    {}
)(withTranslation()(ServicesCard));
