import {isSameValue, Translation} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import SchedulesDetails from './ScheduleDetails';
import styles from './styles.module.scss';


interface ISchedulesTableConnectedProps {
}

interface ISchedulesTableExternalProps {
    schedules: any[];
    isLoading: boolean;
}

interface ISchedulesTableProps extends ISchedulesTableConnectedProps,
    ISchedulesTableExternalProps,
    WithTranslation {
}

interface ISchedulesTableState {
    scheduleList: { [key: string]: any }[];
    moreResultsShown: boolean;
    selectedSchedule: { [key: string]: any } | null;
    isUpdateModalVisible: boolean;
}

class SchedulesTable extends React.Component<ISchedulesTableProps, ISchedulesTableState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ISchedulesTableProps) {
        super(props);

        this.state = {
            scheduleList: this.props.schedules.slice(0, 4),
            moreResultsShown: false,
            selectedSchedule: null,
            isUpdateModalVisible: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.setState({scheduleList: this.props.schedules.slice(0, 4)})
    }

    componentDidUpdate(
        prevProps: Readonly<ISchedulesTableProps>,
        prevState: Readonly<ISchedulesTableState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.schedules, prevProps.schedules)) {
            this.setState({scheduleList: this.props.schedules.slice(0, 4)});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if ((!this.props.schedules ||
                !Array.isArray(this.props.schedules)) ||
            !this.props.schedules.length) {
            return (
                <div className={styles.noResults}>
                    <p className={styles.title}><Translation text={'schedules.noData'}/></p>
                    <p className={styles.description}><Translation text={'schedules.noDataInfo'}/></p>
                </div>
            )
        }

        return (
            <React.Fragment>
                <table className="data-table default-table">
                    <thead>
                    <tr>
                        <th className="highlight-cell">
                            <Translation text={'schedules.table.country'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'schedules.table.school'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'schedules.table.realm'}/>
                        </th>
                        <th className="highlight-cell"/>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderTableRows()}
                    </tbody>
                </table>

                {this.state.scheduleList && this.state.scheduleList.length >= 4 ?
                    (<div className={styles.btnContainer}>
                        <button className={styles.moreResults}
                                onClick={() => this.changeResultsDisplay(!this.state.moreResultsShown)}>
                            <Translation text={'schedules.table.moreResults'}/>
                            {this.state.moreResultsShown ?
                                (<i className="feather icon-chevron-up"/>) :
                                (<i className="feather icon-chevron-down"/>)
                            }
                        </button>
                    </div>) :
                    null}

                <SchedulesDetails schedule={this.state.selectedSchedule}
                                  closeModal={this.closeEditScheduleModal}
                                  isModalVisible={this.state.isUpdateModalVisible}/>
            </React.Fragment>
        )
    }

    private renderTableRows() {
        return this.state.scheduleList.map((item: any) => {
            const school = item?.schoolStudyFields?.school,
                studyField = item?.schoolStudyFields?.studyField;

            return (
                <tr key={item.id}>
                    <td className="highlight-cell">
                        <a href={item.webContentLink} rel="noreferrer" target="_blank" className={styles.itemLink}>
                            {school && school.countries.length ?
                                <Translation text={`country.${school.countries[0].id}`}/> :
                                '---'}
                        </a>
                    </td>
                    <td>{school ? school.name : '---'}</td>
                    <td>{studyField ? studyField.name : '---'}</td>
                    <td className="borderless text-center">
                        <button className={styles.actionBtn}
                                disabled={true === item.public}
                                onClick={() => this.toggleEditScheduleModal(item)}>
                            <i className="feather icon-eye"/>
                        </button>
                    </td>
                </tr>
            );
        });
    }

    private changeResultsDisplay = (isExpanded: boolean) => {
        let schedules;
        isExpanded ? schedules = this.props.schedules : schedules = this.props.schedules.slice(0, 4);

        this.setState({
            scheduleList: schedules,
            moreResultsShown: isExpanded
        })
    };

    private toggleEditScheduleModal = (schedule: { [key: string]: any }) => {
        if (schedule.public) {
            return;
        }

        return this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            selectedSchedule: schedule
        });
    };

    private closeEditScheduleModal = () => {
        return this.setState({
            isUpdateModalVisible: false,
            selectedSchedule: null
        })
    }
}

export default withTranslation()(SchedulesTable);
