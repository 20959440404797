import {
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules,
} from 'educat-common-web';

export const newPasswordFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "auth-form",
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: "new_password_form",
            controlType: "group",
            class: "row",
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.password, (value: any) => value.newPassword]}
                },
            ],
            controls: {
                password: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.newPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control required'
                },
                newPassword: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.repeatPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control required'
                }
            }
        },
        {
            controlType: 'group',
            key: 'new_password_submit',
            class: 'row new-password-form',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: 'submit',
                    inputStyles: 'btn-theme',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.changePassword',
                },
            }
        }
    ]
};
