import {
	authTokenSelector,
	isNotNullOrUndefined,
	getLanguagesAPI,
	getMentorTaskTypesAPI,
	Loader,
	Translation,
	usernameSelector,
	deepCloneObject
} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';
import {getCountriesAPI} from '../../../api/getCountries';
import {getRealmsAPI} from '../../../api/getRealms';
import {getTutoringScopeTypesAPI} from '../../../api/getTutoringScopeTypes';
import {TutoringSubject} from '../../../api/provider/educatTutoringScopeTypeAPI';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {MentorRegistrationSteps, MentorTutoringScope, stepsArray, TaskTypeList} from '../../../service/mentorRegistrationService';
import {RootState} from '../../../store/reducers';
import FormStepBasicInformation from './FormStepBasicInformation';
import FormStepClasses from './FormStepClasses';
import FormStepHelpRange from './FormStepHelpRange';
import FormStepMentorDescription from './FormStepMentorDescription';
import FormStepStudyInformation from './FormStepStudyInformation';
import FormStepThankYou from './FormStepThankYou';

interface IConnectedMentorRegistrationProps {
	authToken: string;
	username: string;
}

interface IExtendedMentorRegistrationProps {
	mentorData: any;
}

interface IMentorRegistrationProps extends IConnectedMentorRegistrationProps, IExtendedMentorRegistrationProps,
WithTranslation {}

interface IMentorRegistrationState {
	isProcessing: boolean;
	currentStep: MentorRegistrationSteps;
	mentorId: string | null;
	mentorTutoringScopes: MentorTutoringScope[];
	mentorTutoringScopesId: {
		maturityExamId: string | null;
		examId: string | null;
		otherId: string | null;
	};
	countryList: { [key: string]: any }[];
	schoolList: { [key: string]: any }[];
	realmList: { [key: string]: any }[];
	fieldOfStudyList: { [key: string]: any }[];
	tutoringScopesList: {
		multiselectOptions: { [key: string]: any }[];
		subjectsArray: TutoringSubject[];
	};
	languageList: { [key: string]: any }[];
	taskTypeList: TaskTypeList;
	rawFormValue: any;
}

class MentorRegistration extends React.Component<IMentorRegistrationProps, IMentorRegistrationState> {
	readonly subscriptions: Subscription[] = [];
	@lazyInject("AlertManagerService") private alertManager: IAlertManagerService | undefined;

	constructor(props: IMentorRegistrationProps) {
		super(props);

		this.state = {
			isProcessing: false,
			currentStep: MentorRegistrationSteps.STUDY_INFORMATION,
			mentorId: null,
			mentorTutoringScopes: [],
			mentorTutoringScopesId: {
				maturityExamId: null,
				examId: null,
				otherId: null,
			},
			countryList: [],
			schoolList: [],
			realmList: [],
			fieldOfStudyList: [],
			tutoringScopesList: { multiselectOptions: [], subjectsArray: [] },
			languageList: [],
			taskTypeList: { taskTypeGroups: [], taskTypesWithoutGroups: [] },
			rawFormValue: {},
		};
		fixInjectedProperties(this);
	}

	componentDidMount() {
		if (this.props.mentorData) {
			this.setState({mentorId: this.props.mentorData.id});
		}
		this.fetchSelectData();
	}

	componentWillUnmount() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	render() {
		const stepKey = this.state.currentStep.toLowerCase();
		return (
			<article className={`col-xl-6 ${stepKey}`}>
				<header className="onboarding-header">
					<h1 className="onboarding-title">
						<Translation text={`mentorOnboarding.registration.${stepKey}.title`} />
					</h1>
				</header>
				<Loader showLoader={this.state.isProcessing} />
				<div className="onboarding-content">
					{this.renderFormStep()}
				</div>
			</article>
		);
	}

	private renderFormStep() {
		if (this.state.isProcessing) {
			return <div className="loading-dummy-page"/>;
		}

		switch (this.state.currentStep) {
			case MentorRegistrationSteps.STUDY_INFORMATION:

				return (
					<FormStepStudyInformation
						submitStep={this.submitStep}
						countryList={this.state.countryList}
						stepData={this.isolatedStepValue(MentorRegistrationSteps.STUDY_INFORMATION)}
					/>
				);
			case MentorRegistrationSteps.HELP_RANGE:
				return (
					<FormStepHelpRange
						submitStep={this.submitStep}
						prevStep={() => this.prevStep(MentorRegistrationSteps.STUDY_INFORMATION)}
						realmList={this.state.realmList}
						taskTypeList={this.state.taskTypeList}
						stepData={this.isolatedStepValue(MentorRegistrationSteps.HELP_RANGE)}
					/>
				);
			case MentorRegistrationSteps.BASIC_INFORMATION:
				return (
					<FormStepBasicInformation
						submitStep={this.submitStep}
						prevStep={() => this.prevStep(MentorRegistrationSteps.HELP_RANGE)}
						languageList={this.state.languageList}
						stepData={this.isolatedStepValue(MentorRegistrationSteps.BASIC_INFORMATION)}
					/>
				);
			case MentorRegistrationSteps.MENTOR_DESCRIPTION:
				return (
					<FormStepMentorDescription
						submitStep={this.submitStep}
						prevStep={() => this.prevStep(MentorRegistrationSteps.BASIC_INFORMATION)}
						stepData={this.isolatedStepValue(MentorRegistrationSteps.MENTOR_DESCRIPTION)}
					/>
				);
			case MentorRegistrationSteps.CLASSES:
				return (
					// TODO PPP: mega słabe: changeCurrentStep z widnow.scrollTo
					<FormStepClasses
						submitStep={this.submitStep}
						prevStep={this.prevStep}
						tutoringScopesList={this.state.tutoringScopesList}
						stepData={this.isolatedStepValue(MentorRegistrationSteps.CLASSES)}
						rawFormData={this.state.rawFormValue}
						changeCurrentStep={() => this.setState({currentStep: MentorRegistrationSteps.THANK_YOU})}
					/>
				);
			case MentorRegistrationSteps.THANK_YOU:
				return <FormStepThankYou />;
			default:
				return;
		}
	}

	private isolatedStepValue = (stepName: MentorRegistrationSteps): any => {
		const value = this.state?.rawFormValue?.[stepName];

		return isNotNullOrUndefined(value) ?
			deepCloneObject(value) :
			null;
	};

	private retrieveListData(api: Observable<any>, listName: string) {
		const {t} = this.props;
		return api.pipe(
			filter((response) => !!response),
			tap((resp: any) => {
				if (resp["hydra:member"]) {
					const multiselectOptions: { [key: string]: any }[] = (resp["hydra:member"] || []).map(
						(option: { [key: string]: any }) => {
							let label = option.name ? option.name : option.id;
							if (listName === 'countryList' || listName === 'languageList') {
								label = t(`${listName === 'countryList' ? 'country' : 'language'}.${option.id}`);
							}
							return ({
							value: option.id,
							label: label,
						})}
					);
					let updatedState = deepCloneObject(this.state);
					updatedState[listName] = multiselectOptions;

					this.setState(updatedState);
				}
			}),
			catchError((err: any) => {
				this.alertManager?.handleApiError(err);
				return of();
			})
		);
	}

	private retrieveTutoringScopeListData(api: Observable<any>) {
		return api.pipe(
			filter((response) => !!response),
			tap((resp: TutoringSubject[]) => {
				const multiselectOptions: { [key: string]: any }[] = (resp || []).map(
					(option: { [key: string]: any }) => ({
						value: option.id,
						label: option.name ? option.name : option.id,
					})
				);
				let updatedState = deepCloneObject(this.state);
				updatedState["tutoringScopesList"] = {
					multiselectOptions: multiselectOptions,
					subjectsArray: resp,
				};

				this.setState(updatedState);
			}),
			catchError((err: any) => {
				this.alertManager?.handleApiError(err);
				return of();
			})
		);
	}

	private retrieveTaskTypeListData(api: Observable<any>) {
		return api.pipe(
			filter((response) => !!response),
			tap((resp: any) => {
				if (resp["hydra:member"]) {
					const response = resp["hydra:member"];
					let mappedTaskTypeList = deepCloneObject(response),
						taskTypeGroups: any[] = [],
						taskTypesWithoutGroup: any[] = [];
					mappedTaskTypeList = mappedTaskTypeList.filter((taskType: any) => taskType.for !== "scholar");
					mappedTaskTypeList.forEach((taskType: any) => {
						if (taskType.mentorTaskTypeGroup !== null) {
							if (
								!taskTypeGroups.some(
									(taskTypeGroup: any) => taskTypeGroup.id === taskType.mentorTaskTypeGroup.id
								)
							) {
								const taskTypeGroup = {
									name: taskType.mentorTaskTypeGroup.name,
									id: taskType.mentorTaskTypeGroup.id,
									taskTypes: [],
								};
								taskTypeGroups.push(taskTypeGroup);
							}
						}
					});
					taskTypeGroups.forEach((taskTypeGroup: any) => {
						mappedTaskTypeList.forEach((taskType: any) => {
							const taskTypeItem = {
								id: taskType.id,
								name: taskType.name,
							};
							if (taskType.mentorTaskTypeGroup?.id === taskTypeGroup.id) {
								taskTypeGroup.taskTypes.push(taskTypeItem);
							}
							if (
								!taskType.mentorTaskTypeGroup &&
								!taskTypesWithoutGroup.includes((taskType: any) => taskType.id === taskTypeItem.id)
							) {
								taskTypesWithoutGroup.push(taskTypeItem);
							}
						});
					});

					this.setState({
						taskTypeList: {
							taskTypeGroups: taskTypeGroups,
							taskTypesWithoutGroups: taskTypesWithoutGroup,
						},
					});
				}
			}),
			catchError((err: any) => {
				this.alertManager?.handleApiError(err);
				return of();
			})
		);
	}

	private fetchSelectData() {
		const selectOptionsApiArray = [
			// this.retrieveListData(getSchoolsAPI(this.props.authToken, new RestQueryParams({type: "college"})), 'schoolList'),
			this.retrieveListData(getRealmsAPI(this.props.authToken), "realmList"),
			this.retrieveListData(getCountriesAPI(this.props.authToken), "countryList"),
			this.retrieveListData(getLanguagesAPI(this.props.authToken), "languageList"),
			this.retrieveTutoringScopeListData(getTutoringScopeTypesAPI(this.props.authToken)),
			this.retrieveTaskTypeListData(getMentorTaskTypesAPI(this.props.authToken)),
		];
		this.setState({ isProcessing: true });
		this.subscriptions.push(
			forkJoin(selectOptionsApiArray)
				.pipe(
					tap(() => {
						this.setState({ isProcessing: false });
					})
				)
				.subscribe()
		);
	}

	private submitStep = (
		stepName: MentorRegistrationSteps,
		stepValue: any,
	) => {
		const nextStepIndex = stepsArray.findIndex((step) => step.stepName === stepName),
			updatedCurrentStep = stepsArray[nextStepIndex + 1].stepName,
			updatedRawFormValue = deepCloneObject(this.state.rawFormValue);
		updatedRawFormValue[stepName] = deepCloneObject(stepValue);

		this.setState({
			currentStep: updatedCurrentStep,
			rawFormValue: updatedRawFormValue,
		}, () => {
			window.scrollTo(0, 0)
		});

	};

	private prevStep = (step: MentorRegistrationSteps, stepValue?: any, prevStep?: MentorRegistrationSteps) => {
		if (step === MentorRegistrationSteps.MENTOR_DESCRIPTION && stepValue && prevStep) {
			let updatedRawFormValue = deepCloneObject(this.state.rawFormValue);
			let stepRawData = stepValue;

			if (stepRawData.classes.subjectClasses === 'no') {
				stepRawData.exams = null;
				stepRawData.maturityExam = null;
				stepRawData.other = null;
				stepRawData.classes.subject = null;
			}
			updatedRawFormValue[prevStep] = stepRawData;

			return this.setState({currentStep: step, rawFormValue: updatedRawFormValue})
		}

		this.setState({
			currentStep: step
		}, () => {
			window.scrollTo(0, 0)
		});
	};
}

export default withTranslation()(connect(
	(state: RootState) => ({
		username: usernameSelector(state),
		authToken: authTokenSelector(state),
	}),
	{}
)(MentorRegistration));
