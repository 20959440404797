import {
    authTokenSelector,
    deepCloneObject,
    Form,
    FormControlChangeType,
    IFormConfig,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isObjectEmpty,
    isSameCollection,
    isSameValue,
    Loader,
    removeExtraValueKeys,
    RestQueryParams,
    SchoolType,
    Translation
} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {MentorRegistrationSteps} from '../../../../service/mentorRegistrationService';
import {RootState} from '../../../../store/reducers';
import {mentorStudyInformationFormConfig} from './mentorStudyInformationFormConfig';
import {IMentorStudyInformationFormInstanceConfig} from './types';
import {getSchoolsAPI} from '../../../../api/getSchools';
import {getSchoolStudyFieldsAPI} from '../../../../api/getSchoolStudyFields';
import {getSchoolAPI} from "../../../../api/getSchool";


interface IConnectedFormStepStudyInformationProps {
    authToken: string;
}

interface IExternalFormStepStudyInformationProps {
    readonly submitStep: (stepName: MentorRegistrationSteps, stepValue: any) => void;
    readonly stepData?: any;
    readonly countryList: { [key: string]: any }[];
}

interface IFormStepStudyInformationProps
    extends IConnectedFormStepStudyInformationProps,
        IExternalFormStepStudyInformationProps,
        WithTranslation {
}

interface IFormStepStudyInformationState {
    isStepValid: boolean;
    stepValue: any;
    stepName: MentorRegistrationSteps;
    formConfig: typeof IFormConfig | null;
    isConfigLoading: boolean;
    isDataLoading: boolean | null;
    formInstanceData: { [key: string]: IMentorStudyInformationFormInstanceConfig }[];
    isFormInstanceReloadRequired: boolean;
}

type CollectionUpdater = (data: any, resp: any, index: number, stateMultiselectOptions: string) => void;

const standardCollectionUpdater: CollectionUpdater = (data: any, resp: any, index: number, stateMultiselectOptions: string) => {
    (data[index] as any)[`study_information_${index}`][stateMultiselectOptions] = (resp['hydra:member'] || [])
        .map((option: { [key: string]: any }) => ({
            value: option.id,
            label: option.name ? option.name : option.id,
        }));
};

const schoolSingleOptionUpdater: CollectionUpdater = (data: any, resp: any, index: number, stateMultiselectOptions: string) => {
    (data[index] as any)[`study_information_${index}`][stateMultiselectOptions] = [{
        value: resp.id,
        label: resp.name ? resp.name : resp.id,
    }];
};

const fieldOfStudyUpdater: CollectionUpdater = (data: any, resp: any, index: number, stateMultiselectOptions: string) => {
    (data[index] as any)[`study_information_${index}`][stateMultiselectOptions] = (resp['hydra:member'] || [])
        .map((option: { [key: string]: any }) => ({
            value: option.id,
            label: option.studyField.name,
        }));

    const mapping: any = {};
    (resp['hydra:member'] || []).forEach((option: { [key: string]: any }) => {
        mapping[option.id] = option.realm?.id;
    });
    data[index][`study_information_${index}`].fieldOfStudyToRealmMapping = mapping;
    data[index][`study_information_${index}`].realmValues = (resp['hydra:member'] || [])
        .map((option: { [key: string]: any }) => ({
            value: option.realm?.id,
            label: option.realm?.name,
        }));
};

class FormStepStudyInformation extends React.Component<IFormStepStudyInformationProps, IFormStepStudyInformationState> {
    subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IFormStepStudyInformationProps) {
        super(props);

        const formInstanceData = this.props?.stepData ?
            [...Object.keys(this.props.stepData).map((item: any, index: number) => {
                return {
                    [`study_information_${index}`]: {
                        schoolValues: [],
                        fieldOfStudyValues: [],
                        preloadedSchoolValue: null,
                        preloadedFieldOfStudyValue: null,
                        loading: false,
                        fieldOfStudyToRealmMapping: null,
                        realmValues: [],
                        preloadedRealmValue: null,
                    }
                }
            })] :
            [
                {
                    study_information_0: {
                        schoolValues: [],
                        fieldOfStudyValues: [],
                        preloadedSchoolValue: null,
                        preloadedFieldOfStudyValue: null,
                        loading: false,
                        fieldOfStudyToRealmMapping: null,
                        realmValues: [],
                        preloadedRealmValue: null,
                    }
                },
            ];

        this.state = {
            isStepValid: false,
            stepValue: this.props?.stepData ? deepCloneObject(this.props?.stepData) : null,
            stepName: MentorRegistrationSteps.STUDY_INFORMATION,
            formConfig: null,
            isConfigLoading: true,
            isDataLoading: null,
            formInstanceData,
            isFormInstanceReloadRequired: true
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.setState({stepValue: data.value})),
            ).subscribe()
        );

        this.preloadFormValues();
        this.configureForm();
    }

    componentDidUpdate(prevProps: Readonly<IFormStepStudyInformationProps>, prevState: Readonly<IFormStepStudyInformationState>): void {
        this.subscriptions = this.subscriptions.filter(subscription => !subscription.closed);

        this.updateFormConfigIfNecessary(prevProps, prevState);

        this.preloadFormValues();

        this.configureForm(prevState);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const canRenderForm = !this.state.isConfigLoading && false === this.state.isDataLoading;

        return (
            <>
                <Loader showLoader={!canRenderForm}/>
                <div className="onboarding-form-wrapper">
                    {canRenderForm && <Form config={this.state.formConfig}
                                            controlName={MentorRegistrationSteps.STUDY_INFORMATION}
                                            onValueStateChange={this.onValueStateChange}
                                            onValidationStateChange={this.formValidityChange}
                                            value={this.state.stepValue}/>}

                    <div className="offset-md-6 col-md-6 add-button-wrapper">
                        <span>+</span>
                        <button className="btn-add" onClick={() => this.addFormInstance()}>
                            <Translation text="mentorOnboarding.registration.study_information.addButton"/>
                        </button>
                    </div>
                </div>
                <footer className="onboarding-navigation justify-content-end">
                    <button
                        type="submit"
                        disabled={!this.state.isStepValid}
                        onClick={() => this.props.submitStep(this.state.stepName, deepCloneObject(this.state.stepValue))}
                        className="btn btn-theme btn-rounded">
                        <Translation text="buttons.next"/>
                    </button>
                </footer>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        return this.setState({isStepValid: isValid});
    };

    private addFormInstance() {
        const count = this.state.formInstanceData.length,
            blankFormInstance = {
            [`study_information_${count}`]: {
                schoolValues: [],
                fieldOfStudyValues: [],
                preloadedSchoolValue: null,
                preloadedFieldOfStudyValue: null,
                loading: false,
                fieldOfStudyToRealmMapping: null,
                realmValues: [],
                preloadedRealmValue: null,
            }
        };
        const updatedFormInstancesData = deepCloneObject(this.state.formInstanceData);
        updatedFormInstancesData.push(blankFormInstance);
        this.setState({
            formInstanceData: updatedFormInstancesData,
        });
    }

    private configureForm = (prevState?: IFormStepStudyInformationState) => {
        if (false !== this.state.isDataLoading) {
            return;
        }
        if (isNullOrUndefined(this.state.stepValue?.study_information_0) || isSameValue(this.state.stepValue, prevState?.stepValue)) {
            return;
        }

        let updated = false;
        const value = deepCloneObject(this.state.stepValue);

        this.state.formInstanceData.forEach((instance, index) => {
            const selectedCountry = this.state.stepValue?.[`study_information_${index}`]?.country;
            if (selectedCountry !== prevState?.stepValue?.[`study_information_${index}`]?.country && this.state.isFormInstanceReloadRequired) {
                let queryParams = new RestQueryParams();
                queryParams = queryParams.add('countries.id', selectedCountry);
                queryParams = queryParams.add('type', SchoolType.College);
                this.loadMultiselectsData(index, getSchoolsAPI(this.props.authToken, queryParams), 'schoolValues');
                this.loadMultiselectsData(index, of({'hydra:member': []}), 'fieldOfStudyValues', fieldOfStudyUpdater);
                value[`study_information_${index}`].school = null;
                value[`study_information_${index}`].fieldOfStudy = null;
                value[`study_information_${index}`].realm = null;
                updated = true;
            }
            const selectedSchool = this.state.stepValue?.[`study_information_${index}`]?.school;
            const hasSchoolChanged = selectedSchool !== prevState?.stepValue?.[`study_information_${index}`]?.school;
            if (hasSchoolChanged && this.state.isFormInstanceReloadRequired) {
                let collection$ = of({'hydra:member': []});
                if (isNotNullOrUndefined(selectedSchool)) {
                    let queryParams = new RestQueryParams();
                    queryParams = queryParams.add('school.id', selectedSchool);
                    collection$ = getSchoolStudyFieldsAPI(this.props.authToken, queryParams);
                }
                this.loadMultiselectsData(index, collection$, 'fieldOfStudyValues', fieldOfStudyUpdater);
                value[`study_information_${index}`].fieldOfStudy = null;
                value[`study_information_${index}`].realm = null;
                updated = true;
            }
            const selectedFieldOfStudy = this.state.stepValue?.[`study_information_${index}`]?.fieldOfStudy;
            if (isNotNullOrUndefined(selectedFieldOfStudy) && selectedFieldOfStudy !== prevState?.stepValue?.[`study_information_${index}`]?.fieldOfStudy) {
                value[`study_information_${index}`].realm = this.state.formInstanceData?.[index]?.[`study_information_${index}`].fieldOfStudyToRealmMapping?.[selectedFieldOfStudy];
                updated = true;
            }

            const current = value?.[`study_information_${index}`]?.status,
                previous = prevState?.stepValue?.[`study_information_${index}`]?.status;

            if (current !== previous && 'graduate' === current) {
                delete value[`study_information_${index}`]['yearOfStudies'];
                updated = true;
            }
        });

        this.setState({isFormInstanceReloadRequired: true});

        if (updated) {
            this.setState({stepValue: value, isFormInstanceReloadRequired: true});
        }
    };

    private updateFormConfigIfNecessary = (prevProps: Readonly<IFormStepStudyInformationProps>, prevState: Readonly<IFormStepStudyInformationState>): void => {
        let newFormConfigNecessary = false;

        if (!newFormConfigNecessary) {
            newFormConfigNecessary = this.state.formInstanceData.some((instance, index) => {
                const current = this.state?.stepValue?.[`study_information_${index}`]?.status,
                    previous = prevState?.stepValue?.[`study_information_${index}`]?.status;

                return current !== previous;
            });
        }

        if (!newFormConfigNecessary && !isSameCollection(this.state.formInstanceData, prevState?.formInstanceData)) {
            newFormConfigNecessary = true;
        }

        if (newFormConfigNecessary) {
            this.setFormConfig();
        }
    };

    private handleMultiselectInputChange = (value: string, controlName: string, index: number) => {
        if (value.length < 3) {
            return;
        }
        let queryParams = new RestQueryParams();
        queryParams = queryParams.add('name', value);

        if (controlName === 'school') {
            const selectedCountry = this.state.stepValue?.[`study_information_${index}`]?.country;
            queryParams = queryParams.add('countries.id', selectedCountry);
            queryParams = queryParams.add('type', SchoolType.College);

            this.loadMultiselectsData(index, getSchoolsAPI(this.props.authToken, queryParams), 'schoolValues');
        }
    };

    private setFormConfig = (): void => {
        const formConfig = mentorStudyInformationFormConfig(
            this.props.t,
            this.state.formInstanceData,
            this.props.countryList,
            this.state.stepValue,
            (index: number) => {
                this.setState((state) => {
                    const stepValue = Object.assign({}, state.stepValue);
                    let updatedInstanceData = Array.from(this.state.formInstanceData);
                    removeExtraValueKeys(stepValue, 'study_information_', index);
                    updatedInstanceData.forEach((instance: { [key: string]: IMentorStudyInformationFormInstanceConfig }) => {
                        removeExtraValueKeys(instance, 'study_information_', index);
                    });

                    return {
                        stepValue: stepValue,
                        formInstanceData: updatedInstanceData.filter(instance => !isObjectEmpty(instance)),
                        isFormInstanceReloadRequired: false
                    };
                });
            },
            (value: any, controlName: string, index: number) => this.handleMultiselectInputChange(value, controlName, index)
        );

        this.setState({formConfig, isConfigLoading: false});
    };

    private preloadFormValues = (): void => {
        if (null !== this.state.isDataLoading) {
            return;
        }

        if (isNullOrUndefined(this.state.stepValue)) {
            this.state.formInstanceData.forEach((instance, index) => {
                this.updateInstanceData(index, data => (data[index] as any)[`study_information_${index}`].loading = false);
                this.setState({isDataLoading: false});
            });
            return;
        }

        this.state.formInstanceData.forEach((instance, index) => {
            let schools$ = of([]);
            if (this.state.stepValue?.[`study_information_${index}`]?.country) {
                if (this.state.stepValue?.[`study_information_${index}`].school) {
                    schools$ = getSchoolAPI(this.state.stepValue?.[`study_information_${index}`].school,this.props.authToken)
                } else {
                    let queryParams = new RestQueryParams();
                    queryParams = queryParams.add("countries.id", this.state.stepValue?.[`study_information_${index}`]?.country);
                    queryParams = queryParams.add("type", SchoolType.College);
                    schools$ = getSchoolsAPI(this.props.authToken, queryParams);
                }
            }

            let schoolStudyFields$ = of([]);
            if (isNotNullOrUndefined(this.state.stepValue?.[`study_information_${index}`]?.school)) {
                let queryParams = new RestQueryParams();
                queryParams = queryParams.add("school.id", this.state.stepValue?.[`study_information_${index}`]?.school);
                schoolStudyFields$ = getSchoolStudyFieldsAPI(this.props.authToken, queryParams);
            }

            const requests = {
                schools: schools$,
                schoolStudyFields: schoolStudyFields$,
            };

            this.updateInstanceData(index, (data) => ((data[index] as any)[`study_information_${index}`].loading = true));
            this.setState({isDataLoading: true});
            this.subscriptions.push(
                forkJoin(requests)
                    .pipe(
                        tap((result: any) => {
                            this.updateInstanceData(index, (data) => {
                                (data[index] as any)[`study_information_${index}`].loading = false;
                                result.schools && isNullOrUndefined(result.schools['hydra:member']) ?
                                    schoolSingleOptionUpdater(data, result.schools, index, "schoolValues") :
                                    standardCollectionUpdater(data, result.schools, index, "schoolValues");
                                fieldOfStudyUpdater(data, result.schoolStudyFields, index, "fieldOfStudyValues");
                            });
                            this.setState({isDataLoading: false});
                        })
                    )
                    .subscribe()
            );
        });
    };

    private loadMultiselectsData = (index: number, api: Observable<any>, stateMultiselectOptions: string, updater: CollectionUpdater = standardCollectionUpdater) => {
        this.updateInstanceData(index, data => (data[index] as any)[`study_information_${index}`].loading = false);

        return this.subscriptions.push(
            api.pipe(
                tap((resp: any) => {
                    if (isNullOrUndefined(resp['hydra:member'])) {
                        return;
                    }

                    this.updateInstanceData(index, data => {
                        (data[index] as any)[`study_information_${index}`].loading = false;

                        updater(data, resp, index, stateMultiselectOptions);
                    });
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.updateInstanceData(index, data => (data[index] as any)[`study_information_${index}`].loading = false);

                    return of(error);
                })
            )
                .subscribe()
        );
    };

    private updateInstanceData = (index: number, updater: (data: { [key: string]: IMentorStudyInformationFormInstanceConfig }[]) => void): void => {
        this.setState(state => {
            if (isNullOrUndefined(state.formInstanceData[index])) {
                return null;
            }
            const copy = deepCloneObject(this.state.formInstanceData);
            updater(copy);

            return {
                formInstanceData: copy,
            }
        });
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(withTranslation()(FormStepStudyInformation));
