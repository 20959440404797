import {FormControlType, IFormConfig, InputType, ValidationRules} from 'educat-common-web';

export const mentorDescriptionFormConfig: typeof IFormConfig = (value?: any) => ({
    controlType: 'form',
    class: 'onboarding-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'mentor_description',
            controlType: 'group',
            class: 'row',
            controls: {
                longDescription: {
                    controlType: 'control',
                    defaultValue: value ? value.longDescription : '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MAX_LENGTH, params: {length: 2000}}
                    ],
                    placeholder: 'mentorOnboarding.registration.mentor_description.formControls.placeholder',
                    isLabelHidden: false,
                    rows: 5,
                    maxLength: 2000,
                    label: 'mentorOnboarding.registration.mentor_description.formControls.longDescription',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-row required'
                },
                programme: {
                    controlType: 'control',
                    defaultValue: value ? value.programme : '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MAX_LENGTH, params: {length: 2000}}
                    ],
                    placeholder: 'mentorOnboarding.registration.mentor_description.formControls.placeholder',
                    isLabelHidden: false,
                    rows: 5,
                    maxLength: 2000,
                    label: 'mentorOnboarding.registration.mentor_description.formControls.programme',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-row required'
                }
            }
        }
    ]
});
