const navigation = {
  items: [
    {
      id: 'Dashboard',
      title: 'Insurance Cover',
      type: 'group',
      icon: '',
      children: [
          {
              id: 'kokpit',
              title: 'sidebar.dashboard',
              type: 'item',
              url: '/panel/dashboard',
              icon: 'menu-icon dashboard',
          },
          {
              id: 'applicants',
              title: 'sidebar.applicants',
              type: 'item',
              url: '/panel/applicants',
              icon: 'menu-icon user',
          },
          {
              id: 'schedules',
              title: 'sidebar.schedules',
              type: 'item',
              url: '/panel/schedules',
              icon: 'menu-icon file-text',
          },
          {
              id: 'consultations',
              title: 'sidebar.consultations',
              type: 'item',
              url: '/panel/consultations',
              icon: 'menu-icon schedules',
          },
          {
              id: 'contentFiles',
              title: 'sidebar.contentFiles',
              type: 'item',
              url: '/panel/content-files',
              icon: 'menu-icon contents',
          },
          {
              id: 'insurances',
              title: 'sidebar.insurances',
              type: 'item',
              url: '/panel/insurances',
              icon: 'menu-icon briefcase',
          },
          // {
          //     id: 'videoChat',
          //     title: 'sidebar.videoChat',
          //     type: 'item',
          //     url: '/panel/video-chat',
          //     icon: 'menu-icon video',
          // },
      ]
    },
  ]
};
export default navigation;
