import {accountSelector, authTokenSelector, Loader, LoaderType, Translation} from 'educat-common-web';
import React from 'react';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import ChangePasswordCard from './ChangePasswordCard';
import ProfileInfoCard from './ProfileInfoCard';
import VideoPresentation from "./VideoPresentation";
import {catchError, filter, map, switchMap, tap} from "rxjs/operators";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {getMentorAPI} from "../../api/getMentor";
import {updateMentorDetailsAPI} from "../../api/updateMentorDetails";
import {getRealmsAPI} from "../../api/getRealms";
import {getFreeTermsAPI} from '../../api/getFreeTerms';
import {getCalendarsAPI} from '../../api/getCalendar';
import CalendarEdit from './CalendarEdit';
import {changeAvailableConsultationSlots} from 'educat-common-web';
import {changeCalendarId} from '../../store/reducers/calendarSlice';
import ServicesCard from "./ServicesCard";


interface IConnectedProfileProps {
    readonly authToken: string;
    readonly account: {[key: string]: any};
    readonly changeAvailableConsultationSlots: typeof changeAvailableConsultationSlots;
    readonly changeCalendarId: typeof changeCalendarId;
}

interface IExternalProfileProps { }

interface IProfileProps extends IConnectedProfileProps,
    IExternalProfileProps {
}

interface IProfileState {
    isLoading: boolean;
    mentorDetails: {[key: string]: any} | null;
    realmsList: {[key: string]: any}[] | [];
    calendar: {[key: string]: any} | null;
    freeTerms: {[key: string]: any}[] | [];
}


class Profile extends React.Component<IProfileProps, IProfileState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            isLoading: true,
            mentorDetails: null,
            realmsList: [],
            freeTerms: [],
            calendar: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getMentorDetails();
        this.retrieveCalendar();
        this.retrieveRealms();
    }
    componentDidUpdate(prevProps: Readonly<IProfileProps>, prevState: Readonly<IProfileState>, snapshot?: any): void {
        if (this.state.calendar && this.state.calendar.id !== prevState.calendar?.id) {
                this.props.changeCalendarId(this.state.calendar.id);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <section className={`mentor-profile-section row`}>
                <h1 className="sr-only">
                    <Translation text={'profile.srTitle'} />
                </h1>
                <div className="col-md-12 col-xl-8">
                    <ProfileInfoCard mentorDetails={this.state.mentorDetails}
                                     realmsList={this.state.realmsList}
                                     updateData={this.updateMentorData}/>
                    <ServicesCard />
                    <CalendarEdit calendar={this.state.calendar}
                                  getAvailableConsultationSlots={this.retrieveCalendar}
                                  freeTerms={this.state.freeTerms}/>
                </div>
                <div className="col-md-12 col-xl-4">
                    <VideoPresentation videoLink={this.state.mentorDetails?.videoLink}
                                       updateData={this.updateMentorData} />
                    <ChangePasswordCard />
                </div>

                <Loader showLoader={this.state.isLoading} type={LoaderType.Local}/>
            </section>
        );
    }

    private getMentorDetails = () => {
        if (!this.props.account || !this.props.account.mentorId) {
            return;
        }

        this.subscriptions.push(
            getMentorAPI(this.props.authToken, this.props.account.mentorId).pipe(
                map((response: any) => {
                    this.setState({
                        mentorDetails: response,
                        isLoading: false
                    })
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        );
    };

    private updateMentorData = (
        value: {[key: string]: any},
        onSuccess: () => void,
        onError: () => void) => {
        if (!this.state.mentorDetails || !this.state.mentorDetails.id) {
            return;
        }
        let payload: {[key: string]: any} = {
            realms: this.state.mentorDetails.realms.map((realm: {[key: string]: any}) => realm.id)
        };

        if (value.hasOwnProperty('longDescription')) {
            payload.longDescription = value.longDescription;
        }

        if (value.hasOwnProperty('videoLink')) {
            payload.videoLink = value.videoLink;
        }

        if (value.hasOwnProperty('programme')) {
            payload.programme = value.programme;
        }

        if (value.hasOwnProperty('realms')) {
            payload.realms = value.realms;
        }

        this.subscriptions.push(
            updateMentorDetailsAPI(this.props.authToken, this.state.mentorDetails.id, payload).pipe(
                tap((resp: any) => {
                    this.setState({mentorDetails: resp});
                    onSuccess();
                }),
                catchError((error: any) => {
                    onError();
                    this.alertManager?.handleApiError(error.response);
                    return of();
                })
            ).subscribe()
        )
    };

    private retrieveRealms = () => {
        this.subscriptions.push(
            getRealmsAPI(this.props.authToken).pipe(
                filter((response) => !!response),
                tap((resp: any) => {
                    if (resp["hydra:member"]) {
                        const multiselectOptions: { [key: string]: any }[] = (resp["hydra:member"] || []).map(
                            (option: { [key: string]: any }) => {
                                let label = option.name ? option.name : option.id;
                                return ({
                                    value: option.id,
                                    label: label,
                                })}
                        );

                        this.setState({realmsList: multiselectOptions});
                    }
                }),
                catchError((err: any) => {
                    this.alertManager?.handleApiError(err);
                    return of();
                })
            ).subscribe()
        );
    };


    private retrieveCalendar = (from?: string, until?: string) => {
        this.subscriptions.push(
            getCalendarsAPI(this.props.authToken).pipe(
                filter((response) => !!response),
                map((resp: any) => {
                    if (resp["hydra:member"] && resp["hydra:member"].length > 0) {
                        this.setState({calendar: resp["hydra:member"][0]});
                        return resp["hydra:member"][0].id;
                    }
                    return of();
                }),
                filter((response) => !!response),
                switchMap((calendarId: string) => getFreeTermsAPI(this.props.authToken, calendarId, from, until)),
                tap((response: any) => this.setState({freeTerms: response})),
                catchError((err: any) => {
                    this.alertManager?.handleApiError(err);
                    return of();
                })
            ).subscribe(()=>{
                this.scrollToCalendarIfNecessary();
            })
        );
    }

    private scrollToCalendarIfNecessary() {
        const href = window.location.href;
        if (href.substr(href.lastIndexOf('?')+1) === 'calendar'){
            document.getElementById('calendar')?.scrollIntoView();
            window.history.pushState('data', 'title', href.substr(0, href.lastIndexOf('?')));
        }
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state)
    }),
    {
        changeAvailableConsultationSlots,
        changeCalendarId
    }
)(Profile);
