import { Observable } from "rxjs";
import { educatAPI, RestQueryParams } from "educat-common-web";


export function getNewApplicantsAPI(
    authToken: string | null,
    mentorId: string
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.get(
        `mentors/${mentorId}/new_applicants`,
        new RestQueryParams(),
        headers
    );
}
