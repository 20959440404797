import {
	Form,
	FormControlChangeType,
	IFormConfig,
	isSameValue,
	Loader,
	Translation
} from 'educat-common-web';
import React from "react";
import {MentorRegistrationSteps, TaskTypeList} from "../../../../service/mentorRegistrationService";
import {mentorHelpRangeFormConfig} from "./taskTypeBaseControl";
import {filter, tap} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from "rxjs";

interface IConnectedFormStepHelpRangeProps {}

interface IExternalFormStepHelpRangeProps {
	readonly submitStep: (stepName: MentorRegistrationSteps, stepValue: any) => void;
	readonly prevStep: () => void;
	readonly stepData?: any;
	readonly realmList: {[key: string]: any}[];
	readonly taskTypeList: TaskTypeList;
}

interface IFormStepHelpRangeProps extends IConnectedFormStepHelpRangeProps, IExternalFormStepHelpRangeProps {}

interface IFormStepHelpRangeState {
	isStepValid: boolean;
	isProcessing: boolean;
	stepValue: any;
	stepName: MentorRegistrationSteps;
	formConfig: typeof IFormConfig;
}

class FormStepHelpRange extends React.Component<IFormStepHelpRangeProps, IFormStepHelpRangeState> {
	readonly subscriptions: Subscription[] = [];
	readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
	constructor(props: IFormStepHelpRangeProps) {
		super(props);

		const value = this.props.stepData;
		this.state = {
			isStepValid: false,
			isProcessing: false,
			formConfig: mentorHelpRangeFormConfig(value, this.props.taskTypeList, this.props.realmList),
			stepValue: value,
			stepName: MentorRegistrationSteps.HELP_RANGE,
		};
	}

	componentDidMount() {
		if (this.props.taskTypeList) {
			this.setState({formConfig: mentorHelpRangeFormConfig(this.state.stepValue, this.props.taskTypeList, this.props.realmList)});
		}

		this.subscriptions.push(
			this.onValueStateChange$
				.pipe(
					filter((data: any) => data),
					tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
				)
				.subscribe()
		);
	}

	componentDidUpdate(
		prevProps: Readonly<IFormStepHelpRangeProps>,
		prevState: Readonly<IFormStepHelpRangeState>,
		snapshot?: any
	): void {
		if (!isSameValue(this.props.taskTypeList, prevProps.taskTypeList)) {
			this.setState({formConfig: mentorHelpRangeFormConfig(this.state.stepValue, this.props.taskTypeList, this.props.realmList)});
		}
	}

	render() {
		return (
			<>
				<Loader showLoader={this.state.isProcessing} />
				<div className="onboarding-form-title-wrapper">
					<div className="form-subtitle">
						<Translation text="mentorOnboarding.registration.help_range.additionalInfo" />
					</div>
				</div>
				<div className="onboarding-form-wrapper">
					<Form
						config={this.state.formConfig}
						controlName={MentorRegistrationSteps.HELP_RANGE}
						onValueStateChange={this.onValueStateChange}
						onValidationStateChange={this.formValidityChange}
						value={this.state.stepValue}/>
				</div>
				<footer className="onboarding-navigation">
					<button
						type="button"
						onClick={() => this.props.prevStep()}
						className="btn btn-theme btn-back">
						<Translation text="buttons.back" />
					</button>
					<button
						type="submit"
						disabled={!this.state.isStepValid}
						onClick={() => this.props.submitStep(this.state.stepName, this.state.stepValue)}
						className="btn btn-theme btn-rounded">
						<Translation text="buttons.next" />
					</button>
				</footer>
			</>
		);
	}

	private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
		this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
	};

	private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
		if (changeType !== FormControlChangeType.Init && changeType !== FormControlChangeType.User) {
			return;
		}
		if (isSameValue(this.state.stepValue, value)) {
			return;
		}
		const realms = this.computeRealms(value);

		const newValue = Object.assign({}, this.state.stepValue, value);
		newValue.realms = realms;

		this.setState({stepValue: newValue});
	};

	private computeRealms = (value: any) => {
		if (undefined === value?.realms) {
			// new realms hae not been defined yet, restore from state or reset collection
			return this.state.stepValue?.realms || [];
		}
		if (null === value?.realms || value?.realms?.length === 0) {
			// new realms are empty, reset collection
			return [];
		}
		if (undefined !== value?.realms?.[0]) {
			// new realms are not empty and contain valid values
			return value.realms;
		}
		if (this.state.stepValue?.realms?.length > 0 && undefined !== this.state.stepValue.realms[0]) {
			return this.state.stepValue.realms;
		}

		return [];
	};

	private formValidityChange = (controlName: string, isValid: boolean) => {
		this.setState({isStepValid: isValid});
	};
}

export default FormStepHelpRange;
