import React from 'react';
import styles from "./styles.module.scss";
import {CustomCard, Translation, SchoolCard} from "educat-common-web";
import {UserRole} from 'educat-common-web';


interface IConnectedApplicantStudyFieldsProps {}

interface IExternalApplicantStudyFieldsProps {
    isLoading: boolean;
    applicantSchoolStudyFields: {[key: string]: any}[] | null;
}

interface IApplicantStudyFieldsProps extends IConnectedApplicantStudyFieldsProps,
    IExternalApplicantStudyFieldsProps {
}

interface IApplicantStudyFieldsState {}


class ApplicantStudyFields extends React.Component<IApplicantStudyFieldsProps, IApplicantStudyFieldsState> {

    constructor(props: IApplicantStudyFieldsProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Header>
                    <h2 className="custom-card-title">
                        <Translation text="dashboard.fieldOfStudy.title" />
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className={styles.studyFieldsWrapper}>
                        {this.props.applicantSchoolStudyFields ?
                            this.props.applicantSchoolStudyFields.map((studyField: {[key: string]: any}) => {
                                return (
                                    <SchoolCard applicantSchoolStudyField={studyField}
                                                key={studyField.id}
                                                toggleReviewState={() => null}
                                                userRole={UserRole.MENTOR}/>
                                )
                            }) : null}
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default ApplicantStudyFields;
