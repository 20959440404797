import {
    authTokenSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    isNullOrUndefined,
    isSameValue,
    Translation
} from "educat-common-web";
import React from "react";
import {connect} from "react-redux";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, tap} from "rxjs/operators";
import {updateCalendarAPI} from "../../../../api/updateCalendarAPI";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {RootState} from "../../../../store/reducers";
import {changeCalendarDetails, ICalendarDetails} from "../../../../store/reducers/calendarSlice";
import {calendarDetailsSelector, calendarIdSelector} from "../../../../store/selectors/calendarSelectors";
import {calendarDetailsConfig} from "./formConfig";


interface IConnectedCalendarTimezoneFormProps {
    readonly calendarDetails: ICalendarDetails | null;
    readonly calendarId: string | null;
    readonly authToken: string;
    readonly changeCalendarDetails: typeof changeCalendarDetails;
}

export interface ICalendarTimezoneFormProps extends IConnectedCalendarTimezoneFormProps {
    readonly calendar: any;
    readonly reloadCalendar: any;
}

interface ICalendarTimezoneFormState {
    isFormValid: boolean;
    formConfig: typeof IFormConfig | null;
    value: any;
}

class CalendarTimezoneForm extends React.Component<ICalendarTimezoneFormProps, ICalendarTimezoneFormState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    @lazyInject("AlertManagerService") private alertManagerService: IAlertManagerService;

    constructor(props: any) {
        super(props);

        this.state = {
            isFormValid: false,
            formConfig: null,
            value: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.updateFormFromAPI(this.props.calendar);
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
        this.setFormConfig();
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (!isSameValue(this.props.calendar, prevProps.calendar) && this.props.calendar) {
            this.updateFormFromAPI(this.props.calendar);
            this.setFormConfig();
        }

        if (!isSameValue(this.state.value, prevState.value)) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="col-xl-6">
                <h3 className="secondary-title">
                    <Translation text={"calendar.calendarDetails.title"}/>
                </h3>
                <div>
                    <Form
                        config={this.state.formConfig}
                        onValidationStateChange={this.onFormValidityChange}
                        onValueStateChange={this.onValueStateChange}
                        value={this.state.value}
                        controlName={"calendarTimezoneForm"}
                    />
                    {/*<p className={styles.timezoneDetails}>*/}
                    {/*    <Translation text={"calendar.calendarDetails.form.timezone.details"}/>*/}
                    {/*</p>*/}
                </div>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        if (isNullOrUndefined(value.timezone)) {
            return;
        }
        this.updateCalendarDetails(value.timezone);
    };

    private onFormValidityChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private updateFormFromAPI = (calendar: any) => {
        if (calendar) {
            this.props.changeCalendarDetails({
                calendarName: calendar.name,
                calendarPrice: calendar.price,
                timezone: calendar.timezone,
                calendarFreeTerms: calendar.calendarFreeTerms
            });
            this.setState({
                value: {
                    timezone: calendar.timezone,
                },
            });
        }
    };

    private updateCalendarDetails = (timezone: string) => {
        if (this.props.calendarDetails && this.props.calendarId) {
            this.subscriptions.push(
                updateCalendarAPI(this.props.calendarId, this.props.authToken, {
                    name: this.props.calendarDetails.calendarName,
                    public: true,
                    price: this.props.calendarDetails.calendarPrice,
                    slotLength: this.props.calendar.slotLength,
                    availableFrom: this.props.calendar.availableFrom,
                    availableUntil: this.props.calendar.availableUntil,
                    timezone: timezone,
                    calendarRules: this.props.calendar.calendarRules,
                })
                    .pipe(
                        tap(() => {
                            if (this.props.calendar.availableFrom && this.props.calendar.availableUntil){
                                window.location.reload();
                            }
                            if (this.props.calendarDetails) {
                                this.props.changeCalendarDetails({
                                    calendarName: this.props.calendarDetails.calendarName,
                                    calendarPrice: this.props.calendarDetails.calendarPrice,
                                    timezone: timezone,
                                    calendarFreeTerms: this.props.calendarDetails.calendarFreeTerms
                                });
                            }

                            this.alertManagerService.addAlert("calendar.modals.confirmUpdateModal.success");
                        }),
                        catchError((error: any) => {
                            this.alertManagerService.handleApiError(error.response);
                            return of(error);
                        })
                    )
                    .subscribe()
            );
        }
    };

    private setFormConfig = () => {
        const isTimezoneDisabled = isNullOrUndefined(this.props.calendar);
        this.setState({formConfig: calendarDetailsConfig(this.state.value, isTimezoneDisabled)});
    }
}

export default connect(
    (state: RootState) => ({
        calendarDetails: calendarDetailsSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeCalendarDetails,
    }
)(CalendarTimezoneForm);
