import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';
import moment from "moment";


export function getFreeTermsAPI(authToken: string, calendarId: string, from?: string, until?: string): Observable<any> {

    const endOfDay = new Date().setHours(0, 0, 0, 0),
        dateFrom = moment(new Date(endOfDay).setMonth(new Date().getMonth() - 2)).toISOString(true),
        dateUntil = moment(new Date(endOfDay).setMonth(new Date().getMonth() + 2)).toISOString(true);

    return educatAPI.post(
        'calendar/free_terms',
        {
            from: from ? from : dateFrom,
            until: until ? until : dateUntil,
            calendarId: calendarId
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
