import {
    IFormConfig,
} from 'educat-common-web';


export const mentorApplicationExamPricesFormConfig: typeof IFormConfig = (groups: any[]) => ({
    controlType: 'form',
    class: 'onboarding-form onboarding-table',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [...groups]
});


export const taskGroup: typeof IFormConfig = (groupName: string, controls: any, isFieldset: boolean) => ({
    key: `${groupName}`,
    controlType: isFieldset ? 'fieldset' : 'group',
    fieldsetTitle: isFieldset ? groupName : null,
    class: isFieldset ? 'row help-range' : 'row',
    controls: controls
});
