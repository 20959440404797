import {
    accountSelector,
    Breadcrumbs,
    initLogout,
    NavBar,
    NotFound,
    Sidebar,
    Toast,
    Translation,
    UserRole,
    ImodelMediaObject,
    accountUsernameSelector,
    accountAvatarSelector,
    impersonatorRefreshTokenSelector,
    ContentFiles,
    Insurances,
    getCurrentYear
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import Applicants from '../Applicants';
import ApplicantView from '../Applicants/ApplicantView';
import Consultations from '../Consultations';
import Dashboard from '../Dashboard';
import Profile from '../Profile';
import Schedules from '../Schedules';
import VideoChatHost from '../VideoChatHost';
import navigation from './menu-items';
import {withTranslation, WithTranslation} from "react-i18next";


interface IConnectedPanelHostProps {
    readonly username: string;
    readonly avatar: typeof ImodelMediaObject | null;
    readonly impersonatorToken: string | null;
    readonly initLogout: typeof initLogout;
}

interface IExternalPanelHostProps {
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps, IExternalPanelHostProps, RouteComponentProps, WithTranslation {
}

interface IPanelHostState {
    isSidebarCollapsed: boolean;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private readonly navMenu: any[];
    private readonly subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            isSidebarCollapsed: false,
        };

        const {t} = this.props;
        this.navMenu = [
            {
                url: '/panel/profile',
                icon: 'icon-user',
                title: t('navMenu.profile'),
            },
            {
                url: '#',
                icon: 'icon-power',
                title: t('navMenu.logout'),
                onClick: this.onLogout,
            },
        ];
        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        const collapsed = this.state.isSidebarCollapsed ? 'collapsed' : '';

        return (
            <div className="dashboard-view">
                <div className="main-container-wrapper">
                    <Sidebar navigation={navigation} collapseMenu={this.state.isSidebarCollapsed}/>

                    <div className={`main-container ${collapsed}`}>
                        <NavBar
                            onToggleNavigation={this.toggleNavigation}
                            onLogout={this.onLogout}
                            isDropdownVisible={false}
                            isSearchVisible={false}
                            generalNotificationsDisplayed={false}
                            profileNotificationsDisplayed={false}
                            navMenu={this.navMenu}
                            username={this.props.username}
                            userRole={UserRole.MENTOR}
                            userImageUrl={this.props.avatar?.tinyThumb}
                            notificationCount={3}
                            navigation={navigation.items}
                            collapseMenu={this.state.isSidebarCollapsed}
                            impersonatorToken={this.props.impersonatorToken}
                        />
                        <div className="content-wrapper">
                            <main className="content">
                                <Breadcrumbs/>
                                <Switch>
                                    <Route path="/panel/dashboard" component={Dashboard} exact key="dashboard"/>
                                    <Route path="/panel/applicants" component={Applicants} exact key="applicants"/>
                                    <Route path="/panel/applicants/:id" component={ApplicantView} exact key="applicant"/>
                                    <Route path="/panel/schedules" component={Schedules} exact key="schedules"/>
                                    <Route path="/panel/content-files" component={ContentFiles} exact key="content-files"/>
                                    <Route path="/panel/profile" component={Profile} exact key="profile"/>
                                    <Route path="/panel/consultations" component={Consultations} exact key="consultations"/>
                                    <Route path="/panel/consultations/:id" component={VideoChatHost} exact key="videoChat"/>
                                    <Route path="/panel/content-files" component={() => <ContentFiles userRole={UserRole.MENTOR} />} exact key="content-files"/>
                                    <Route path="/panel/insurances" component={Insurances} exact key="insurances" />
                                    <Route key="not-found" component={NotFound}/>
                                </Switch>
                            </main>

                            <footer className="footer">
                                <Translation text="footer.copyright" config={{year: getCurrentYear()}}/>
                            </footer>
                        </div>
                    </div>
                </div>

                <Toast/>
            </div>
        );
    }


    toggleNavigation = (): void => {
        this.setState({isSidebarCollapsed: !this.state.isSidebarCollapsed});
    };

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
    };


}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        username: accountUsernameSelector(state),
        avatar: accountAvatarSelector(state),
        impersonatorToken: impersonatorRefreshTokenSelector(state)
    }),
    {
        initLogout
    }
)(withRouter(withTranslation()(PanelHost)));
