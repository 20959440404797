import {
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules,
} from 'educat-common-web';

export const resetPasswordFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "auth-form",
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: "reset_password_form",
            controlType: "group",
            class: "reset-password-form",
            controls: {
                email: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}}
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.email',
                    type: InputType.EMAIL,
                    hostClass: ""
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: 'submit',
                    hostClass: '',
                    defaultContainerStyles: '',
                    defaultStyles: 'btn btn-theme reset-button',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.send',
                    isLabelHidden: true,
                },
            }
        }
    ]
};
