import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {connect} from "react-redux";
import {
    authTokenSelector,
    changeBreadcrumbs,
    CustomCard,
    IMultiselectOption,
    RestQueryParams,
    Translation
} from 'educat-common-web';
import {RootState} from "../../store/reducers";
import SchedulesTable from "./SchedulesTable";
import {getSchedulesAPI} from "../../api/getSchedules";
import {catchError, map} from "rxjs/operators";
import styles from "./styles.module.scss";
import AddScheduleModal from "./AddScheduleModal";
import ScheduleStudyFieldForm, {ScheduleStudyFieldFormType} from "./AddScheduleModal/ScheduleStudyFieldForm";


interface IConnectedSchedulesProps {
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly authToken: string;
}

interface IExternalSchedulesProps {
}

interface ISchedulesProps extends IConnectedSchedulesProps,
    IExternalSchedulesProps,
    RouteComponentProps {
}

interface ISchedulesState {
    schedules: any[] | [];
    isProcessing: boolean;
    isAddScheduleModalShown: boolean;
    countryValues: typeof IMultiselectOption[] | null;
}


class Schedules extends React.Component<ISchedulesProps, ISchedulesState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ISchedulesProps) {
        super(props);

        this.state = {
            schedules: [],
            isProcessing: true,
            isAddScheduleModalShown: false,
            countryValues: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.schedules', path: '/panel/schedules'}
        ]);

        this.getSchedules();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <section className={`${styles.schedulesSection} row`}>
                    <div className='col-xl-8'>
                        <CustomCard showLocalLoader={false}>
                            <CustomCard.Header>
                                <div className={styles.header}>
                                    <h2 className="custom-card-title">
                                        <Translation text="schedules.title"/>
                                    </h2>
                                </div>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <div className="schedule-list-filters">
                                    <ScheduleStudyFieldForm updateScheduleDetails={this.getSchedules}
                                                            type={ScheduleStudyFieldFormType.SEARCH_SCHEDULE}/>
                                </div>
                            </CustomCard.Body>
                        </CustomCard>

                        {this.renderSchedulesTableCard()}
                    </div>

                    <div className='col-xl-4'>
                        <CustomCard showLocalLoader={false}>
                            <CustomCard.Header>
                                <div className={styles.header}>
                                    <h2 className="custom-card-title">
                                        <Translation text="schedules.addSchedule.title"/>
                                    </h2>
                                </div>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <div className={styles.newScheduleDescription}>
                                    <Translation text="schedules.addSchedule.description"/>
                                </div>
                                <div>
                                    <button className="btn btn-theme" onClick={() => this.toggleAddSchedule()}>
                                        <Translation text="schedules.addSchedule.add"/>
                                    </button>
                                </div>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </section>

                {this.state.isAddScheduleModalShown ?
                    (<AddScheduleModal isModalVisible={this.state.isAddScheduleModalShown}
                                       toggleModal={this.toggleAddSchedule}
                                       refreshList={this.getSchedules}/>) : null}
            </React.Fragment>
        );
    }

    private renderSchedulesTableCard() {
        if (this.state.isProcessing) return;

        if (!this.state.schedules || !Array.isArray(this.state.schedules) || !this.state.schedules.length) {
            return (
                <CustomCard showLocalLoader={this.state.isProcessing}>
                    <CustomCard.Header>
                        <Translation text="schedules.noSchedule.title"/>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        <Translation text="schedules.noSchedule.description"/>
                    </CustomCard.Body>
                </CustomCard>
            );
        }


        return (
            <CustomCard showLocalLoader={this.state.isProcessing}>
                <CustomCard.Body>
                    <SchedulesTable schedules={this.state.schedules} isLoading={this.state.isProcessing}/>
                </CustomCard.Body>
            </CustomCard>
        )
    }

    private toggleAddSchedule = () => {
        this.setState({isAddScheduleModalShown: !this.state.isAddScheduleModalShown});
    };

    private getSchedules = (value?: { [key: string]: any }) => {
        if (value && !value.studyField) {
            return;
        }

        this.setState({isProcessing: true});

        let queryParams = new RestQueryParams();
        if (value && value.studyField) {
            queryParams = queryParams.add('schoolStudyFields.studyField.id', value.studyField);
            queryParams = queryParams.add('schoolStudyFields.school.countries.id', value.country);
            queryParams = queryParams.add('schoolStudyFields.school.id', value.school);
        }

        return this.subscriptions.push(
            getSchedulesAPI(this.props.authToken, queryParams).pipe(
                map((resp: any) => {
                    this.setState({
                        schedules: resp['hydra:member'],
                        isProcessing: false
                    })
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isProcessing: false});
                    return of();
                })
            ).subscribe()
        )
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(Schedules));
