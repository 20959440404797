import {RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {educatCalculatedPricesAPI} from './provider/educatCalculatedPricesAPI';


export function changeMentorServiceConfigAPI(
    authToken: string,
    mentorId: string,
    numberLimit: number,
): Observable<any> {
    let headers = undefined;
    const payload = {serviceConfig: {freeServiceInstanceUsedLimit: numberLimit}};
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatCalculatedPricesAPI.put(
        `mentors/${mentorId}/service_config`,
        payload,
        new RestQueryParams(),
        headers
    );
}
