import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export interface IUpdateMentorData {
    longDescription?: string | null;
    videoLink?: string | null;
    programme?: string | null;
    realms?: string[] | [] | null;
}

export function updateMentorDetailsAPI(
    authToken: string | null,
    mentorId: string,
    updatedData: IUpdateMentorData
): Observable<any> {
    let headers = undefined,
        payload: {[key: string]: any} = {};
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    if (updatedData.hasOwnProperty('longDescription')) {
        payload.longDescription = updatedData.longDescription;
    }

    if (updatedData.hasOwnProperty('videoLink')) {
        payload.videoLink = updatedData.videoLink;
    }

    if (updatedData.hasOwnProperty('programme')) {
        payload.programme = updatedData.programme;
    }

    if (updatedData.hasOwnProperty('realms')) {
        payload.realms = updatedData.realms;
    }

    return educatAPI.put(
        `mentors/${mentorId}`,
        payload,
        new RestQueryParams(),
        headers
    );
}
