import {injectable} from 'inversify';
import {addAlert} from 'educat-common-web';
import {fixInjectedProperties} from '../ioc';
import store from '../store';

export enum AlertType {
    INFO = "info",
    WARNING = "warning",
    SUCCESS = "success",
    ERROR = "error",
}

export interface IAlertManagerService {
    handleApiError(error?: any, type?: AlertType): void;
    registrationSuccess(): void;
    loginSuccess(): void;
    loginFailure(): void;
    logoutSuccess(): void;
    addAlert(message: string, type?: AlertType | null, displayFor?: number | null, isTranslated?: boolean | null): void;
}

const AlertMessage = {
    baseError: 'alerts.baseError',
};

@injectable()
class AlertManagerService implements IAlertManagerService {

    constructor() {
        fixInjectedProperties(this);
    }

    public handleApiError(error: any = null, type: AlertType = AlertType.WARNING) {
        let message = error ? error : AlertMessage.baseError;


        if (error) {
            if (error['hydra:description']) {
                message = error['hydra:description']
            } else if (error.message) {
                if (error.message === 'JWT Token not found') {
                    message = 'auth.alert.loginRequired'
                } else {
                    message = error.message
                }
            }
        }

        this.addAlert(message, type);
    };

   public registrationSuccess() {
        this.addAlert(
            'auth.alert.registrationSuccess',
            AlertType.INFO);
    };

    public loginSuccess() {
        this.addAlert('auth.alert.loginSuccess', AlertType.INFO);
    };

    public loginFailure() {
        return this.addAlert( 'auth.alert.loginFailed', AlertType.WARNING);
    };

    public logoutSuccess() {
        return this.addAlert('auth.alert.logout', AlertType.INFO);
    }


    public addAlert(
        message: string,
        type: AlertType | null = AlertType.INFO,
        displayFor: number | undefined = 5000,
        isTranslated: boolean | null = true
    ) {
        const alert = {
            message: message,
            type: type,
            displayFor: displayFor,
            isTranslated: isTranslated
        };
        store.dispatch(addAlert(alert));
    }
}

export default AlertManagerService;
