import React from 'react';
import styles from './styles.module.scss';
import {CustomCard, DateComponent} from 'educat-common-web';
import AccountImage from '../../../Common/AccountImage';


interface IConnectedApplicantInfoCardProps {
}

interface IExternalApplicantInfoCardProps {
    isLoading: boolean;
    account: { [key: string]: any } | null;
    notificationCount?: number;
}

interface IApplicantInfoCardProps extends IConnectedApplicantInfoCardProps,
    IExternalApplicantInfoCardProps {
}

interface IApplicantInfoCardState {
}


class ApplicantInfoCard extends React.Component<IApplicantInfoCardProps, IApplicantInfoCardState> {

    constructor(props: IApplicantInfoCardProps) {
        super(props);
        this.state = {};
    }

    render() {
        const account = this.props.account,
            name = `${account?.firstName ? account.firstName : ''} ${account?.lastName ? account.lastName : ''}`;

        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Body>
                    <div className={styles.profileInfoWrapper}>
                        <div className={styles.profileInfoImgContainer}>
                            <AccountImage account={account} type={'mediumThumb'}/>
                        </div>
                        <div className={styles.profileInfoDetails}>
                            <p className={styles.profileTitle}>{name}</p>
                            <p className={styles.profileMail}>{name}</p>
                            <p className={styles.profileInfoText}>{account?.phone}</p>
                            {account?.birthdate && <p>
                                <DateComponent date={account?.birthdate}/>
                            </p>}
                        </div>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default ApplicantInfoCard;
