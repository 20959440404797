import {IModelMentor, IModelMentorSchoolStudyField, isNotNullOrUndefined,} from 'educat-common-web';
import React from 'react';

interface IConnectedSchoolDetailsProps {
}

interface IExternalSchoolDetailsProps {
    mentor: typeof IModelMentor;
}

interface ISchoolDetailsProps extends IConnectedSchoolDetailsProps,
    IExternalSchoolDetailsProps {
}

interface ISchoolDetailsState {
}


class SchoolDetails extends React.Component<ISchoolDetailsProps, ISchoolDetailsState> {
    render() {
        const mentor = this.props.mentor,
            mentorSchoolStudyFields = mentor?.mentorSchoolStudyFields
                ?.filter((mentorSchoolStudyField: typeof IModelMentorSchoolStudyField) => isNotNullOrUndefined(mentorSchoolStudyField));

        if (!Array.isArray(mentorSchoolStudyFields) || 0 === mentorSchoolStudyFields.length) {
            return null;
        }

        return (
            <div className="school-details-container">
                {mentorSchoolStudyFields?.map((mentorSchoolStudyField: typeof IModelMentorSchoolStudyField) => {
                    const schoolName = mentorSchoolStudyField.schoolStudyFields.school?.name,
                        studyFieldName = mentorSchoolStudyField.schoolStudyFields.studyField?.name;

                    if (!schoolName && !studyFieldName) {
                        return null;
                    }

                    return (
                        <p className="school-details" key={mentorSchoolStudyField.id}>
                            {schoolName}
                            {studyFieldName ? ` | ${studyFieldName}` : null}
                        </p>
                    );
                })}
            </div>
        );
    }
}

export default SchoolDetails;
