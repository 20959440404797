import React from 'react';
import {authTokenSelector, BasicModal, cancelConsultationAPI, Translation} from 'educat-common-web';
import styles from "./styles.module.scss";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {of, Subscription} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";


interface IConnectedCancelConsultationModalProps {
    authToken: string | null;
}

interface IExternalCancelConsultationModalProps {
    isModalVisible: boolean;
    closeModal: () => void;
    consultationId: string | null;
    changeConsultationsList: () => void;
}

interface ICancelConsultationModalProps extends IConnectedCancelConsultationModalProps,
    IExternalCancelConsultationModalProps {

}

interface ICancelConsultationModalState {
    calendarStartDate: Date,
    selectedDate: Date | null;
}


class CancelConsultationModal extends React.Component<ICancelConsultationModalProps, ICancelConsultationModalState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ICancelConsultationModalProps) {
        super(props);

        this.state = {
            calendarStartDate: new Date(),
            selectedDate: null,
        };

        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.isModalVisible}
                        closeModal={this.props.closeModal}>
                <BasicModal.Header>
                    <div className={styles.modalHeader}>
                        <div className={styles.btnContainer}>
                            <button onClick={() => this.props.closeModal()}>
                                <i className="feather icon-x" />
                            </button>
                        </div>
                        <h2 className={styles.modalTitle}>
                           <Translation text={"consultations.cancelMeetingTitle"}/>
                        </h2>
                    </div>
                </BasicModal.Header>
                <BasicModal.Body>
                    <div className={styles.actionButtons}>
                        <button className="btn btn-danger-outline"
                                onClick={() => this.props.closeModal()}>
                            <Translation text={'buttons.no'} />
                        </button>
                        <button className="btn btn-theme"
                                onClick={() => this.cancelConsultation()}>
                            <Translation text={'buttons.yes'} />
                        </button>
                    </div>
                </BasicModal.Body>
            </BasicModal>
        );
    }

    private cancelConsultation = () => {
        if (!this.props.consultationId) {
            return;
        }

        return this.subscriptions.push(
            cancelConsultationAPI(this.props.authToken, this.props.consultationId).pipe(
                map(() => {
                    this.alertManager.addAlert("consultations.meetingCanceled", null, null, true);
                    this.props.closeModal();
                    this.props.changeConsultationsList();
                }),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(CancelConsultationModal);
