import React from 'react';
import {getApplicantName, isNotEmpty, Translation} from 'educat-common-web';
import styles from './styles.module.scss';
import {NavLink} from 'react-router-dom';
import {IApplicantListingOutput} from '../../../../api/getApplicants';


interface IApplicantsTableRowExternalProps {
    readonly applicant: IApplicantListingOutput;
}

interface IApplicantsTableRowProps extends IApplicantsTableRowExternalProps {
}

interface IApplicantsTableRowState {
}

class ApplicantsTableRow extends React.Component<IApplicantsTableRowProps, IApplicantsTableRowState> {
    render() {
        const applicant = this.props.applicant,
            applicantName = getApplicantName(applicant),
            studyField = applicant?.applicantSchoolStudyFields?.schoolStudyFields?.studyField?.name;

        return (
            <tr key={applicant.id}>
                <td className="highlight-cell">
                    <NavLink className={styles.itemLink}
                             to={{
                                 pathname: `/panel/applicants/${applicant.id}`,
                                 state: {id: applicant.id, name: applicantName},
                             }}>
                        {applicantName}
                        {isNotEmpty(studyField) ? ` - ${studyField}` : ''}
                    </NavLink>
                </td>
                <td>
                    <Translation text={this.getHelpRangeKey()}/>
                </td>
                <td className="borderless">
                    {`${applicant.usedAmount || '--'}/${applicant.usedLimit || '--'} h`}
                </td>
            </tr>
        );
    }

    private getHelpRangeKey() {
        const prefix = 'applicants.table.range.';

        switch (this.props.applicant.paidRange) {
            case true:
                return prefix + 'paid';

            case false:
                return prefix + 'free';

            case null:
            default:
                return prefix + 'unknown';
        }
    }
}

export default ApplicantsTableRow;
