import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    MultiselectMenuPlacement,
    MultiSelectType,
    ValidationRules
} from 'educat-common-web';

export const assignScheduleFormConfig: typeof IFormConfig = (
    school?: string,
    studyField?: string,
    applicantList?: {[key: string]: any}[]
) => ({
    controlType: 'group',
    class: 'row',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'applicant',
            class: 'col-12',
            controls: {
                applicant: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Wszystkie',
                    label: 'schedules.addSchedule.form.placeholders.applicant',
                    multiselectOptions: applicantList ? applicantList : [],
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                    menuPlacement: MultiselectMenuPlacement.BOTTOM,
                    isComponentCustom: true,
                    isCustomLogoOption: true,
                }
            }
        },
        // {
        //     controlType: 'group',
        //     key: 'addToEducat',
        //     class: 'col-6',
        //     controls: {
        //         addToEducat: {
        //             controlType: 'control',
        //             defaultValue: false,
        //             formControlType: FormControlType.CHECKBOX,
        //             validationRules: [],
        //             placeholder: '',
        //             label: '',
        //             isLabelHidden: true,
        //             checkboxLabel: `Dodaj zaktualizowany plan dla ${school} na kierunek ${studyField} do bazy EduCat`,
        //         }
        //     }
        // }
    ]
});
