import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';


export function getServiceConsultationPackagesAPI(authToken: string | null, params?: typeof RestQueryParams): Observable<any> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get('service_consultation_packages', queryParams, headers);
}
