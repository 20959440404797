import React from 'react';
import styles from './styles.module.scss';
import {DateComponent, Translation} from 'educat-common-web';
import AccountImage from '../../Common/AccountImage';


interface IConnectedNewApplicantCardProps {
}

interface IExternalNewApplicantCardProps {
    readonly applicant: any;
}

interface INewApplicantCardProps extends IConnectedNewApplicantCardProps,
    IExternalNewApplicantCardProps {
}

interface INewApplicantCardState {
}

class NewApplicantCard extends React.Component<INewApplicantCardProps, INewApplicantCardState> {

    render() {
        const applicant = this.props.applicant;

        return (
            <div className={styles.container}>
                <div className={styles.info}>
                    <span className={styles.icon}>!</span>
                    <p className={styles.text}>
                        <Translation text={'newApplicants.title'}/>
                    </p>
                </div>

                <div className={styles.details}>
                    <div className={styles.applicantDetails}>
                        <div className={styles.applicantImgContainer}>
                            <AccountImage account={applicant} type={'mediumThumb'} adaptive={true}/>
                        </div>
                        <p className={styles.applicantName}>{applicant?.applicantFirstName} {applicant?.applicantLastName}</p>
                    </div>
                    <div className={styles.serviceDetails}>
                        <p className={styles.label}><Translation text={'newApplicants.serviceName'}/></p>
                        <p className={styles.service}>{applicant?.serviceName}</p>

                        {applicant && applicant.serviceDate ?
                            (<>
                                <p className={styles.label}><Translation text={'newApplicants.serviceDate'}/></p>
                                <p className={styles.service}>
                                    <DateComponent date={applicant.serviceDate} format="D.MM.YYYY [godz.] HH:00"/>
                                </p>
                            </>)
                            : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default NewApplicantCard;
