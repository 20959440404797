import {FormControlType, IFormConfig, InputType} from 'educat-common-web';


export type scheduleEventsConfig = {
    eventsNumber: number,
    value: any,
    onAdd?: () => void,
    onRemove?: (index: number) => void,
};

export const addScheduleEventFormConfig = (scheduleEventsControlsFormConfig: scheduleEventsConfig): typeof IFormConfig => {
    const scheduleEventsControls: any[] = [];
    for (let i = 0; i < scheduleEventsControlsFormConfig.eventsNumber; i++) {
        scheduleEventsControls.push(
            addScheduleEventsControls(i, scheduleEventsControlsFormConfig.value, scheduleEventsControlsFormConfig.onRemove)
        );
    }

    return {
        controlType: 'group',
        class: 'add-schedule-event-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            ...scheduleEventsControls,
            {
                controlType: 'group',
                key: 'add_subject',
                class: 'row justify-content-center',
                controls: {
                    addSubject: {
                        hostClass: 'col-xl-12 form-row',
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'button',
                        inputStyles: 'btn-add-subject',
                        defaultContainerStyles: '',
                        containerStyles: 'd-flex align-self-end',
                        defaultValue: null,
                        buttonDisabled: false,
                        btnText: 'schedules.addScheduleModal.addEvent',
                        customClickHandler: () => {
                            if (scheduleEventsControlsFormConfig.onAdd) {
                                return scheduleEventsControlsFormConfig.onAdd();
                            }
                        }
                    },
                },
            },
        ]
    }
};

export const addScheduleEventsControls = (
    index: number,
    value: any,
    onRemove?: (index: number) => void,
) => {
    const controls: any = {
        [`id${index}`]: {
            controlType: 'control',
            defaultValue: '',
            formControlType: FormControlType.INPUT,
            validationRules: [],
            placeholder: '',
            label: '',
            type: InputType.TEXT,
            hostClass: 'd-none',
        },
        [`event${index}`]: {
            controlType: 'control',
            defaultValue: '',
            formControlType: FormControlType.INPUT,
            validationRules: [],
            placeholder: 'schedules.addScheduleModal.event',
            label: '',
            type: InputType.TEXT,
            hostClass: 'col-5 add-schedule-event-control event event-name',
        },
        [`startDate${index}`]: {
            controlType: 'control',
            defaultValue: null,
            formControlType: FormControlType.DATE,
            validationRules: [],
            placeholder: 'schedules.addScheduleModal.eventStart',
            label: '',
            type: InputType.DATE,
            hostClass: 'col-3 add-schedule-event-control event',
            dateFormat: 'dd/MM/yyyy',
            maxDate: value && value[`endDate${index}`] ? new Date(value[`endDate${index}`]) : null
        },
        [`endDate${index}`]: {
            controlType: 'control',
            defaultValue: null,
            formControlType: FormControlType.DATE,
            validationRules: [],
            placeholder: 'schedules.addScheduleModal.eventEnd',
            label: '',
            type: InputType.DATE,
            hostClass: 'col-3 add-schedule-event-control date',
            dateFormat: 'dd/MM/yyyy',
            minDate: value && value[`startDate${index}`] ? new Date(value[`startDate${index}`]) : null
        },
        [`remove${index}`]: {
            hostClass: 'col-1 add-schedule-event-control action',
            controlType: 'control',
            formControlType: FormControlType.BUTTON,
            buttonType: 'button',
            inputStyles: 'btn-add-subject event',
            defaultContainerStyles: '',
            defaultValue: null,
            buttonDisabled: false,
            btnText: '',
            btnPostIconStyles: 'feather icon-trash-2',
            customClickHandler: () => {
                if (onRemove) {
                    onRemove(index);
                }
            }
        },
    };
    return {
        controlType: 'group',
        key: `schedule_event_${index}`,
        class: 'row',
        controls: controls
    };
};
