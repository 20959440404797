import {Observable} from "rxjs";
import {educatAPI, RestQueryParams} from "educat-common-web";

export function deleteCalendarSlotLockersAPI(
    authToken: string,
    calendarId: string,
    dates: string[]
): Observable<any> {
    const payload: any = {
        calendarId: calendarId,
        dates: dates
    };

    return educatAPI.post(
        `calendar_event/delete_slot_lockers`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
