import {
    FormControlType,
    IFormConfig,
    IFormControls,
} from 'educat-common-web';
import {FreeTermsSlot} from "./index";

export const mentorKeepCollidingDatesFormConfig: typeof IFormConfig = (timeslots: FreeTermsSlot[]) => {
    const controlGroups: any[] = [];
    if (timeslots.length > 0) {
        const controls = createFormControlsFromTask(timeslots);
        const helpRangeGroup = taskGroup('colliding_dates', 'colliding_dates', controls);
        controlGroups.push(helpRangeGroup);
    }

    return {
        controlType: 'form',
        class: 'onboarding-form',
        touched: true,
        controls: [
            ...controlGroups,
        ]
    };
};

export const taskGroup: typeof IFormControls = (groupKey: string, groupName: string, controls: any) => ({
    key: `${groupKey}`,
    controlType: 'group',
    class: 'row colliding-dates-form-group',
    // class: 'row',
    controls: controls,
});

const createFormControlsFromTask = (slots: FreeTermsSlot[]) => {
    const controls: any = {};
    slots.forEach((slot, index) => {
        controls[slot.starts_at] = {
            controlType: 'control',
            defaultValue: false,
            value: false,
            formControlType: FormControlType.CHECKBOX,
            validationRules: [],
            placeholder: '',
            label: new Date(slot.starts_at).toLocaleDateString(),
            isLabelHidden: false,
            isCheckboxLabelRaw: false,
            checkboxLabel: ``,
            hostClass: 'checkbox-row col-xl-3'
        };
    });

    return controls;
};
