import React from "react";
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from "rxjs";
import styles from "./styles.module.scss";
import {WithTranslation, withTranslation} from "react-i18next";
import {addContentFilesFormConfig, createFormControls, taskGroup} from "./addContentFilesFormConfig";
import {connect} from "react-redux";
import {catchError, filter, map, tap} from "rxjs/operators";
import {
    accountIdSelector,
    assignContentFileToApplicantAPI,
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    getContentFilesAPI,
    isNullOrUndefined,
    isSameValue,
    RestQueryParams,
    Translation,
    truncateString,
} from "educat-common-web";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";

interface IConnectedAddContentFilesModalProps {
    readonly authToken: string | null;
    readonly accountId: string;
}

interface IExternalAddContentFilesModalProps {
    readonly isModalVisible: boolean;
    readonly toggleModal: () => void;
    readonly applicantId?: string | null;
}

interface IAddContentFilesModalProps extends
    IConnectedAddContentFilesModalProps,
    IExternalAddContentFilesModalProps,
    WithTranslation {}

interface IAddContentFilesModalState {
    readonly formConfig: any | null;
    readonly value: any;
    readonly isModalVisible: boolean;
    readonly contentFilesList: { [key: string]: any }[] | [];
    readonly isLoading: boolean;
}

class AddContentFilesModal extends React.Component<IAddContentFilesModalProps, IAddContentFilesModalState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IAddContentFilesModalProps) {
        super(props);

        this.state = {
            formConfig: null,
            value: null,
            isModalVisible: false,
            contentFilesList: [],
            isLoading: true
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getContentFiles();
        if (this.state.contentFilesList.length) {
            this.setFormConfig();
        }

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IAddContentFilesModalProps>, prevState: Readonly<IAddContentFilesModalState>): void {
        if (!isSameValue(this.state.contentFilesList, prevState.contentFilesList) && this.state.contentFilesList.length) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.isModalVisible} closeModal={this.props.toggleModal}>
                <div className={styles.modalWrapper}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Header>
                            <div className={styles.closeModalContainer}>
                                <button className={styles.btnClose} onClick={() => this.props.toggleModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>

                            <h2 className={styles.modalHeader}>
                                <Translation text="contentFiles.applicantMaterials.table.addContentFile"/>
                            </h2>
                            <div className={styles.modalSubtitle}>
                                <Translation text={'contentFiles.applicantMaterials.table.addContentFileDescription'}/>
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <div className={styles.formContainer}>
                                <Form config={this.state.formConfig}
                                      value={this.state.value}
                                      controlName={'assignMaterialsFormConfig'}
                                      onValueStateChange={this.onValueStateChange}/>
                            </div>

                            <div className={styles.btnContainer}>
                                <button className="btn btn-theme"
                                        disabled={!this.state.value}
                                        onClick={() => this.addContentFiles()}>
                                    <Translation
                                        text={'contentFiles.applicantMaterials.table.actionButtons.confirmAssign'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
            </BasicModal>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.setState({value: value});
    };

    private setFormConfig = () => {
        const formConfig = addContentFilesFormConfig(this.createFormGroupsFromContentFiles());

        this.setState({formConfig: formConfig, isLoading: false});
    };

    private getContentFiles = () => {
        const params = new RestQueryParams()
            .add('accounts.id', this.props.accountId);

        return this.subscriptions.push(
            getContentFilesAPI(this.props.authToken, params).pipe(
                map((resp: any) => {
                    if (resp['hydra:member']) {
                        const multiselectOptions: { [key: string]: any }[] | [] = (resp['hydra:member'] || [])
                            .map((option: { [key: string]: any }) => {
                                return {
                                    value: option.contentFile.id,
                                    label: truncateString(option.contentFile.name, 40)
                                }
                            });
                        this.setState({contentFilesList: multiselectOptions});
                    }
                }),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };


    private addContentFiles = () => {
        this.setState({isLoading: true});
        const selectedFiles: Observable<any>[] = Object.keys(this.state.value).filter((key: string) => this.state.value[key])
            .map((fileId: string) => this.assignMaterialsToApplicant(fileId));
        this.subscriptions.push(
            forkJoin(selectedFiles).pipe(
                tap(() => {
                    this.setState({isLoading: false});
                    this.props.toggleModal();
                    this.alertManager.addAlert('contentFiles.applicantMaterials.table.fileAddedSuccess');
                })
            ).subscribe()
        );
    };

    private assignMaterialsToApplicant = (fileId: string) => {
        if (isNullOrUndefined(this.props.applicantId) || !this.state.contentFilesList.length) {
            return;
        }

        const fileName = this.state.contentFilesList.find(item => item.value === fileId)?.label;
        return assignContentFileToApplicantAPI(
            this.props.authToken,
            fileId,
            this.props.applicantId,
            fileName
        ).pipe(
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager.handleApiError(error);
                return of(error);
            })
        )
    };

    private createFormGroupsFromContentFiles(): any[] {
        if (this.state.contentFilesList.length === 0) {
            return [];
        }

        return this.state.contentFilesList.map((applicant: { [key: string]: any }) => {
            const controls = createFormControls(applicant);

            return taskGroup(applicant.value, controls);
        });
    }
}

export default connect(
    (state: any) => ({
        authToken: authTokenSelector(state),
        accountId: accountIdSelector(state)
    }),
    {}
)(withTranslation()(AddContentFilesModal));
