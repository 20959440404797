import {Observable} from 'rxjs';
import {RestQueryParams} from 'educat-common-web';
import {educatTutoringScopeTypeAPI} from './provider/educatTutoringScopeTypeAPI';


export function getTutoringScopeTypesAPI(authToken: string): Observable<any> {
    return educatTutoringScopeTypeAPI.get(
        'tutoring_scope_types?itemsPerPage=1000',
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
