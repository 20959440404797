import {deepCloneObject, Form, FormControlType, IFormConfig, Translation} from 'educat-common-web';
import React from 'react';
import {Subscription} from 'rxjs';
import {MentorOnboardingSteps} from '../Common/converters/onboardingHelperService';
import OnboardingFormHeader from '../Common/OnboardingFormHeader';
import {mentorHourPackagesFormConfig, taskGroup} from './mentorHourPackagesFormConfig';

interface IHourPackageItem {
    name: string;
    description: string;
    id: string;
    price: number;
    currency: string;
    discount: number;
    discountedPrice: { [key: string]: any };
}

interface IConnectedFormStepHourPackagesProps {
}

interface IExternalFormStepHourPackagesProps {
    readonly submitStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly prevStep: () => void;
    readonly stepData?: any;
    readonly serviceConsultationPackageList: any[];
}

interface IFormStepHourPackagesProps extends IConnectedFormStepHourPackagesProps, IExternalFormStepHourPackagesProps {
}

interface IFormStepHourPackagesState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorOnboardingSteps;
    formConfig: typeof IFormConfig;
}

class FormStepHourPackages extends React.Component<IFormStepHourPackagesProps, IFormStepHourPackagesState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IFormStepHourPackagesProps) {
        super(props);

        this.state = {
            isStepValid: true,
            isProcessing: false,
            formConfig: null,
            stepValue: null,
            stepName: MentorOnboardingSteps.HOUR_PACKAGES,
        };
    }

    componentDidMount() {
        if (this.props.stepData) {
            const groups = this.createFormGroupsFromHourPackages();
            let updatedGroups = deepCloneObject(groups);
            if (this.props.stepData) {
                const hourPackageStepData = this.props.stepData;
                updatedGroups.map((control: any) => {
                    if (control.hasOwnProperty('controls')) {
                        Object.keys(control.controls).map((key: string) => {
                            if (key.includes('net_price') || key.includes('net_price_discount_') || key.includes('client_discount_')) {
                                return control.controls[key];
                            }
                            const stepDataItem = hourPackageStepData[key];
                            if (stepDataItem !== null && stepDataItem !== undefined) {
                                control.controls[key].defaultValue = stepDataItem;
                                control.controls[key].value = stepDataItem;
                                control.controls[key].checked = stepDataItem;
                            } else {
                                control.controls[key].defaultValue = false;
                                control.controls[key].value = false;
                                control.controls[key].checked = false;
                            }
                            control.controls[key].disabled = true;
                            return control.controls[key];
                        });
                    }

                    return control;
                });
            }

            const updatedFormConfig = mentorHourPackagesFormConfig(updatedGroups);
            this.setState({formConfig: updatedFormConfig, stepValue: this.props.stepData});
        } else {
            this.setState({formConfig: mentorHourPackagesFormConfig(this.createFormGroupsFromHourPackages())});
        }

    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className="onboarding-form-wrapper">
                    <OnboardingFormHeader stepName={this.state.stepName}/>
                    {this.state.formConfig &&
                        <Form config={this.state.formConfig}
                              controlName={MentorOnboardingSteps.HOUR_PACKAGES}
                              onValueStateChange={this.formChangeHandler}
                              onValidationStateChange={this.formValidityChange}
                              value={this.state.stepValue}/>}

                    <div className="additional-information">
                        <Translation text="mentorOnboarding.onboarding.priceRecalculatingInfo"/>
                    </div>
                </div>
                <footer className="onboarding-navigation">
                    <button type="button" onClick={() => this.props.prevStep()} className="btn btn-theme btn-back">
                        <Translation text="buttons.back"/>
                    </button>
                    <button
                        type="submit"
                        disabled={!this.state.isStepValid}
                        onClick={() => this.props.submitStep(this.state.stepName, this.state.stepValue)}
                        className="btn btn-theme btn-rounded"
                    >
                        <Translation text="buttons.next"/>
                    </button>
                </footer>
            </>
        );
    }

    private formChangeHandler = (controlName: MentorOnboardingSteps, value: any) => {
        if (!this.state.stepValue) {
            return this.setState({stepValue: value});
        }
        let updatedStepValue = deepCloneObject(this.state.stepValue);
        for (const [key, inputValue] of Object.entries(value)) {
            updatedStepValue[key] = inputValue;
        }
        this.setState({stepValue: updatedStepValue});
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };

    private createFormGroupsFromHourPackages(): any[] {
        if (this.props.serviceConsultationPackageList.length === 0) {
            return [];
        }

        return this.props.serviceConsultationPackageList.map((hourPackage: IHourPackageItem) => {
            const controls = this.createFormControlsFromHourPackage(hourPackage);

            return taskGroup(hourPackage.name, controls, false);
        });
    }

    private createFormControlsFromHourPackage(hourPackage: IHourPackageItem) {
        let controls = {};
        controls = Object.assign(controls, {
            [hourPackage.id]: {
                controlType: 'control',
                defaultValue: true,
                value: true,
                formControlType: FormControlType.CHECKBOX,
                validationRules: [],
                placeholder: '',
                label: hourPackage.name,
                isLabelHidden: false,
                isCheckboxLabelRaw: false,
                checkboxLabel: ``,
                hostClass: 'col-xl-3 onboarding-table-cell checkbox-container',
            },
            [`net_price${hourPackage.id}`]: {
                controlType: 'control',
                defaultValue: hourPackage.price ? `${Math.round(hourPackage.price / 100)} ${hourPackage.currency}` : null,
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
                hostClass: `col-xl-3 onboarding-table-cell hide-label input-readonly`,
            },
            [`client_discount_${hourPackage.id}`]: {
                controlType: 'control',
                defaultValue: hourPackage.discount ? `${Math.round(hourPackage.discount / 100)} %` : null,
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
                hostClass: `col-xl-3 onboarding-table-cell hide-label input-readonly`,
            },
            [`net_price_discount_${hourPackage.id}`]: {
                controlType: 'control',
                defaultValue: hourPackage.discountedPrice ? `${Math.round(hourPackage.discountedPrice.amount / 100)} ${hourPackage.currency}` : null,
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
                hostClass: `col-xl-3 onboarding-table-cell hide-label input-readonly`,
            },
        });

        return controls;
    }
}

export default FormStepHourPackages;
