import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getApplicantAPI(
    authToken: string | null,
    applicantId: string
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.get(
        `applicants/${applicantId}`,
        new RestQueryParams(),
        headers
    );
}
