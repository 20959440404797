import {
    accountSelector,
    AuthFooter,
    AuthHeader,
    authTokenSelector,
    IAccount,
    isAuthenticatedSelector,
    Loader,
    initLogout,
    setMentorOnboardingConfirmed,
    Toast,
    Translation,
    usernameSelector,
    isSameValue
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {getMentorAPI} from '../../api/getMentor';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import ConfirmationInfo from './ConfirmationInfo';
import MentorOnboarding from './MentorOnboarding';
import MentorRegistration from './MentorRegistration';

type IConnectedOnboardingHostProps = {
    account: typeof IAccount;
    username: string;
    initLogout: typeof initLogout;
    setMentorOnboardingConfirmed: typeof setMentorOnboardingConfirmed;
    isAuthenticated: boolean;
    authToken: string;
};

interface IExternalOnboardingHostProps {
    readonly userRole: any;
    readonly authType: any;
    readonly formConfig: any;
    // readonly envData: any;
}

interface IOnboardingHostProps
    extends IConnectedOnboardingHostProps,
        IExternalOnboardingHostProps,
        RouteComponentProps {
}

interface IOnboardingHostState {
    isProcessing: boolean;
    mentorCreationStage: MentorCreationStage | null;
    isRegistrationConfirmed: boolean;
    isExtendedRegistrationFilled: boolean;
    isOnboardingEnabled: boolean;
    isMentorOnboardingFilled: boolean;
    isOnboardingConfirmed: boolean;
    mentorData: any;
}

export enum MentorCreationStage {
    REGISTRATION_BASIC = 'registration_basic',
    REGISTRATION_BASIC_NOT_CONFIRMED = 'registration_basic_not_confirmed',
    REGISTRATION_EXTENDED = 'registration_extended',
    REGISTRATION_EXTENDED_NOT_CONFIRMED = 'registration_extended_not_confirmed',
    ONBOARDING = 'onboarding',
    ONBOARDING_NOT_CONFIRMED = 'onboarding_not_confirmed',
    ONBOARDING_CONFIRMED = 'onboarding_confirmed',
}

class OnboardingHost extends React.Component<IOnboardingHostProps, IOnboardingHostState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IOnboardingHostProps) {
        super(props);
        this.state = {
            isProcessing: true,
            mentorCreationStage: null,
            isRegistrationConfirmed: false,
            isExtendedRegistrationFilled: false,
            isOnboardingEnabled: false,
            isMentorOnboardingFilled: false,
            isOnboardingConfirmed: false,
            mentorData: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }
        this.getUserData();
    }

    componentDidUpdate(
        prevProps: Readonly<IOnboardingHostProps>,
        prevState: Readonly<IOnboardingHostState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.account?.mentorId, prevProps.account?.mentorId) ||
            !isSameValue(this.props.isAuthenticated, prevProps.isAuthenticated)) {
            this.getUserData();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="onboarding-page">
                <AuthHeader/>
                <button className="btn btn-logout" type="button" onClick={this.onLogout}>
					<span className="sr-only">
						<Translation text="buttons.logout"/>
					</span>
                    <Translation text="buttons.logout"/>
                </button>

                <main className="onboarding-section">
                    {this.renderCurrentRegistrationStage()}
                </main>
                <AuthFooter/>

                <Toast/>
                <Loader showLoader={this.state.isProcessing}/>
            </div>
        );
    }

    setCurrentMentorRegistrationStage() {
        if (!this.state.mentorData) {
            return null;
        }
        const mentorData = this.state.mentorData,
            accountData = this.props.account,
            isRegistrationConfirmed = accountData?.mentorRegistrationConfirmed,
            isOnboardingEnabled = mentorData?.onboardingEnabled,
            isOnboardingConfirmed = mentorData?.confirmed,
            isExtendedRegistrationFilled = mentorData?.programme !== undefined && mentorData?.programme !== null,
            isMentorOnboardingFilled =
                mentorData?.mentorOnboarding.nationality && mentorData?.mentorOnboarding.bankData;

        if (!isRegistrationConfirmed) {
            this.setState({
                mentorCreationStage: MentorCreationStage.REGISTRATION_BASIC_NOT_CONFIRMED,
            });
        }
        if (isRegistrationConfirmed && !isOnboardingEnabled) {
            this.setState({
                mentorCreationStage: isExtendedRegistrationFilled
                    ? MentorCreationStage.REGISTRATION_EXTENDED_NOT_CONFIRMED :
                    MentorCreationStage.REGISTRATION_EXTENDED,
            });
        }
        if (isOnboardingEnabled && !isOnboardingConfirmed) {
            this.setState({
                mentorCreationStage: isMentorOnboardingFilled
                    ? MentorCreationStage.ONBOARDING_NOT_CONFIRMED :
                    MentorCreationStage.ONBOARDING,
            })
        }
        if (isOnboardingConfirmed) {
            this.props.setMentorOnboardingConfirmed(true);
            this.setState({
                mentorCreationStage: MentorCreationStage.ONBOARDING_CONFIRMED,
            });
        }
    }

    private renderCurrentRegistrationStage() {
        switch (this.state.mentorCreationStage) {
            case MentorCreationStage.REGISTRATION_BASIC_NOT_CONFIRMED:
                return <ConfirmationInfo registrationStage={MentorCreationStage.REGISTRATION_BASIC_NOT_CONFIRMED}/>;
            case MentorCreationStage.REGISTRATION_EXTENDED:
                return <MentorRegistration mentorData={this.state.mentorData}/>;
            case MentorCreationStage.REGISTRATION_EXTENDED_NOT_CONFIRMED:
                return <ConfirmationInfo registrationStage={MentorCreationStage.REGISTRATION_EXTENDED_NOT_CONFIRMED}/>;
            case MentorCreationStage.ONBOARDING:
                return <MentorOnboarding mentorId={this.state.mentorData.id} mentorData={this.state.mentorData}
                                         reloadUserData={() => this.getUserData()}/>;
            case MentorCreationStage.ONBOARDING_NOT_CONFIRMED:
                return <ConfirmationInfo registrationStage={MentorCreationStage.ONBOARDING_NOT_CONFIRMED}/>;
            case MentorCreationStage.ONBOARDING_CONFIRMED:
                return <Redirect push to={'/panel/dashboard'}/>;
            default:
                return;
        }
    }

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
    };

    private getUserData() {
        if (!this.props.authToken || !this.props.account.mentorId) {
            return;
        }
        this.subscriptions.push(
            getMentorAPI(this.props.authToken, this.props.account.mentorId)
                .pipe(
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.registration.mentor.alerts.fetchMentorError');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    tap((response: any) => {
                        if (response) {
                            this.setRegistrationFlags(response);
                            this.setMentorData(response);
                        }
                    })
                )
                .subscribe()
        );
    }

    private setRegistrationFlags(mentor: any) {
        const isRegistrationConfirmed = this.props.account.mentorRegistrationConfirmed,
            onboardingEnabled = mentor.onboardingEnabled,
            // const isRegistrationConfirmed = true,
            //     onboardingEnabled = false,
            isExtendedRegistrationFilled = !!mentor.programme,
            isMentorOnboardingFilled = Object.values(mentor.mentorOnboarding).some((item) => item !== null);
        this.setState({
            isRegistrationConfirmed: isRegistrationConfirmed,
            isOnboardingEnabled: onboardingEnabled,
            isExtendedRegistrationFilled: isExtendedRegistrationFilled,
            isMentorOnboardingFilled: isMentorOnboardingFilled,
            isOnboardingConfirmed: false,
            isProcessing: false,
        });
    }

    private setMentorData(mentor: any) {
        this.setState({mentorData: mentor}, () => this.setCurrentMentorRegistrationStage());
    }
}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        username: usernameSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        setMentorOnboardingConfirmed,
        initLogout,
    }
)(withRouter(OnboardingHost));
