import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export interface IScheduleItem {
    name: string;
    icon: string | null;
    from: string;
    to: string;
    itemType: string | null,
    // subjectId: string | null; //"3fa85f64-5717-4562-b3fc-2c963f66afa6"
}

export interface ITaskDefinitionItem {
    subject: string;
    content: string;
    needsReview: boolean;
    startsAt: string;
    endsAt: string;
}

export interface IAddSchedulePayload {
    schoolStudyFieldsId: string,
    scheduleList: {
        schedules: IScheduleItem[] | [];
    }
    taskDefinitions: ITaskDefinitionItem[] | [],
    mentorId: string | null;
}

export function addScheduleAPI(authToken: string | null, payload: IAddSchedulePayload): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.post(
        'schedules',
        payload,
        new RestQueryParams(),
        headers
    );
}
